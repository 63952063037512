import React from 'react';
import './style.scss';
import Sidebar from '../../Layouts/Sidebar';
import useGetUser from '../../shared/hooks/useGetUser';
// Please remove this comment

const Dashboard = () => {
    useGetUser();

    return (
        <div className="dashboard-container">
            <Sidebar />
        </div>
    );
};

export default Dashboard;
