import moment from 'moment';
import * as XLSX from 'xlsx';

export const loadFileData = async (file, expectedHeaders, handleTransformToCamelCase, fixedColumns = true) => {
    const filename = file.name;

    const binaryStr = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target.result);
        reader.readAsBinaryString(file);
    });

    const workbook = XLSX.read(binaryStr, { type: 'binary' });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });

    const areHeadersValid = expectedHeaders.every((header, index) => {
        return data[0][index] === header;
    });

    if (data.length > 0 && data[0].length === expectedHeaders.length && areHeadersValid) {
        const columns = data[0].map((element, index) => ({
            title: element,
            dataIndex: handleTransformToCamelCase(element),
            key: handleTransformToCamelCase(element),
            fixed: fixedColumns ? (index < 2 ? 'left' : undefined) : undefined,
        }));

        const dataSource = data.slice(1).map((record, i) => {
            const object = { key: i + 1 };
            record.forEach((element, index) => {
                if (index === 1 && columns[index].key === 'from') {
                    // Handle the 'From' field
                    if (typeof element === 'number') {
                        // Convert Excel serial date to JavaScript date correctly
                        const excelBaseDate = new Date(Date.UTC(1899, 11, 30)); // Adjusted base date to 1899-12-30
                        object[columns[index].key] = moment(excelBaseDate.getTime() + element * 86400 * 1000).format(
                            'DD-MM-YYYY'
                        );
                    } else {
                        // Assuming it's in a valid date format
                        const fromDate = moment(element, 'YYYY-MM-DD HH:mm:ss', true);
                        object[columns[index].key] = fromDate.isValid()
                            ? fromDate.format('MM-DD-YYYY')
                            : 'Invalid Date';
                    }
                } else {
                    object[columns[index].key] = element; // For all other fields
                }
            });
            return object;
        });
        // const dataSource = data.slice(1).map((record, i) => {
        //   const object = { key: i + 1 };
        //   record.forEach((element, i) => {
        //     object[columns[i].key] = element;
        //   });
        //   return object;
        // });

        return { filename, columns, dataSource };
    } else {
        throw new Error('Excel template columns do not match.');
    }
};
