import React, { useEffect, useState } from 'react';
import './styles.css';
import { Modal, Pagination, Table } from 'antd';
import CustomizedButton from '../../Button';
import { CloseOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import CommonMessage, { message } from '../../Message';
import { webService } from '../../../Services/web.service';
import { isValidPartNumber, isValidPrice, isValidQuantity } from '../../../Constants/constants';

const ImportParts = ({ isVisible, handleClose, file, handleGetImportedRecords }) => {
    const [filename, setFilename] = useState(null);
    const [columns, setColumns] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [records, setRecords] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [partRecords, setPartRecords] = useState([]);
    const [isRecordLoading, setIsRecordLoading] = useState(false);
    const [excelData, setExcelData] = useState([]);

    const handleTransformToCamelCase = (inputString) => {
        switch (inputString) {
            case 'Quoted Price':
                return 'quotedSellPrice';
            case 'Quantity':
                return 'quantity';
            case 'Part Number':
                return 'partNumber';
            default:
                return inputString.toLowerCase().replace(/\s+(.)/g, function (match, group) {
                    return group.toUpperCase();
                });
        }
    };

    const loadFile = async (file) => {
        const filename = file.name;
        setFilename(filename);

        const binaryStr = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => resolve(e.target.result);
            reader.readAsBinaryString(file);
        });

        const workbook = XLSX.read(binaryStr, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        const expectedHeaders = ['Part Number', 'Quantity', 'Quoted Price'];

        if (
            data.length > 0 &&
            data[0].length === expectedHeaders.length &&
            expectedHeaders.every((header, index) => data[0][index] === header)
        ) {
            // Set Headers
            const tableColumns = data[0].map((element) => ({
                title: element,
                dataIndex: handleTransformToCamelCase(element),
                key: handleTransformToCamelCase(element),
            }));

            tableColumns.splice(1, 0, {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
            });

            tableColumns.push(
                {
                    title: 'Base Price',
                    dataIndex: 'minimumSellPrice',
                    key: 'minimumSellPrice',
                },
                {
                    title: 'Stock Order Price',
                    dataIndex: 'stockOrderPrice',
                    key: 'stockOrderPrice',
                }
            );

            setColumns(tableColumns);

            const records = [];

            setExcelData(data.slice(1));

            data.slice(1).forEach((record, i) => {
                if (isValidPrice(record[2]) && isValidQuantity(record[1]) && isValidPartNumber(record[0])) {
                    records.push({
                        key: i + 1,
                        partNumber: record[0],
                        quantity: record[1],
                        quotedSellPrice: record[2],
                    });
                }
            });

            setPartRecords(records);
        } else {
            message.error('Excel worksheet columns does not match.');
        }
    };

    const getPartRecords = async () => {
        try {
            setIsRecordLoading(true);

            webService
                .post(`parts/getEffectParts`, {
                    partNumbers: partRecords.map((ele) => ele.partNumber),
                })
                .then((response) => {
                    const data = [];

                    partRecords.forEach((record) => {
                        const part = response.data.find((ele) => ele.partNumber == record.partNumber);

                        if (part) {
                            const object = {
                                partNumber: record.partNumber,
                                quantity: record.quantity,
                                quotedSellPrice: record.quotedSellPrice,
                                minimumSellPrice: 0,
                                stockOrderPrice: part.stockPrice,
                                description: part.description,
                            };

                            if (part.partsBaskets.length > 0) {
                                object.minimumSellPrice = part.partsBaskets[0].minimumSellPrice;
                                object.stockOrderPrice = part.partsBaskets[0].stockPrice;
                            }

                            data.push(object);
                        }
                    });

                    setDataSource(data);

                    if (data.length == 0) {
                        message.error('Import failed: No valid items found.');
                    }

                    if (data.length > 0 && data.length < excelData.length) {
                        message.warning('Import partially failed: Some items have invalid data; valid items imported.');
                    }
                })
                .finally(() => {
                    setIsRecordLoading(false);
                });
        } catch (error) {
            console.error('Error Status', error);
        } finally {
            setIsRecordLoading(false);
        }
    };

    const handleImport = () => {
        let partNumberCount = 0;
        let quantityCount = 0;
        let quotedPriceCount = 0;

        // Filter valid rows from the excel and find respective filter data lengths
        const isNumber = (value) => typeof value === 'number' && !isNaN(value);

        const filteredData = dataSource.filter((row) => {
            const { partNumber, quantity, quotedSellPrice } = row;
            const isPartNumberValid = partNumber !== null && partNumber !== undefined && partNumber !== '';
            const isQuantityValid = isNumber(quantity);
            const isQuotedPriceValid = isNumber(quotedSellPrice);
            if (isPartNumberValid) partNumberCount++;
            if (isQuantityValid) quantityCount++;
            if (isQuotedPriceValid) quotedPriceCount++;

            return isQuantityValid && isQuotedPriceValid && isPartNumberValid;
        });

        if (filteredData.length > 0) {
            // Update filtered data with corresponding prices
            var newData = filteredData.map((row) => {
                const { partNumber, quantity, quotedSellPrice, description, stockOrderPrice, basePrice, ...rest } = row;
                // const details = partNumberToPrices[partNumber];
                const TotalQuotedPrice = quantity * quotedSellPrice;
                return {
                    partNumber,
                    description,
                    quantity,
                    quotedSellPrice,
                    TotalQuotedPrice,
                    minimumSellPrice: basePrice,
                    stockOrderPrice,
                    ...rest,
                };
            });

            const hasRequiredKeys = (obj) => {
                return (
                    obj.hasOwnProperty('partNumber') &&
                    obj.hasOwnProperty('quantity') &&
                    obj.hasOwnProperty('quotedSellPrice')
                );
            };

            let allObjectsHaveRequiredKeys = dataSource.every(hasRequiredKeys);

            //calculate excel columns lengths
            let partNumberLength = 0;
            let quantityLength = 0;
            let quotedSellPriceLength = 0;
            dataSource.forEach((item) => {
                if (item.partNumber) partNumberLength++;
                if (item.quantity) quantityLength++;
                if (item.quotedSellPrice) quotedSellPriceLength++;
            });

            // import data with the validations and respective messages
            if (!allObjectsHaveRequiredKeys) {
                handleGetImportedRecords(newData);
                CommonMessage(
                    'warning',
                    'Import partially failed: Only HMCA Rebate items were imported; others not imported',
                    2,
                    () => {
                        console.log('Message closed');
                    },
                    'custom-success-message'
                );
                closeModal();
            } else if (quantityCount === quantityLength && quotedPriceCount === quotedSellPriceLength) {
                handleGetImportedRecords(newData);
                message.success('Import successful: All items imported.');
                closeModal();
            } else if (partNumberCount !== partNumberLength) {
                handleGetImportedRecords(newData);
                CommonMessage(
                    'warning',
                    'Import partially failed: Only HMCA Rebate items were imported; others not imported.',
                    2,
                    () => {
                        console.log('Message closed');
                    },
                    'custom-success-message'
                );
                closeModal();
            } else if (quantityCount !== quantityLength || quotedPriceCount !== quotedSellPriceLength) {
                handleGetImportedRecords(newData);
                message.warning('Import partially failed: Some items have invalid data; valid items imported.');
                closeModal();
            }
        } else {
            handleGetImportedRecords(newData);
            message.error('Import failed: No valid items found.');
            closeModal();
        }
    };

    const closeModal = () => {
        setDataSource([]);
        handleClose();
    };

    useEffect(() => {
        if (file) {
            loadFile(file);
        }
    }, [file]);

    const onChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    useEffect(() => {
        const startIndex = currentPage - 1;
        const endIndex = startIndex + pageSize;
        setRecords(dataSource.slice(startIndex, endIndex));
    }, [dataSource, currentPage, pageSize]);

    useEffect(() => {
        if (partRecords.length > 0) {
            getPartRecords();
        }
    }, [partRecords]);

    return (
        <Modal visible={isVisible} className="import-modal precise-modal-size" footer={null} closable={false}>
            <div className="header">
                <h3>{filename}</h3>
                <span
                    style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        columnGap: '10px',
                    }}
                >
                    <CustomizedButton
                        type="primary"
                        className="import-btn"
                        onClick={handleImport}
                        isDisabled={dataSource.length === 0}
                    >
                        Import Parts
                    </CustomizedButton>
                    <CloseOutlined className="close-icon" onClick={closeModal} />
                </span>
            </div>
            <div className="content">
                <div className="modal-container">
                    <Table dataSource={records} columns={columns} pagination={false} loading={isRecordLoading} />
                </div>
            </div>
            <div className="parts-pagination">
                <Pagination
                    pageSizeOptions={['15', '25', '50', '100']}
                    showSizeChanger={true}
                    onChange={onChange}
                    total={dataSource.length}
                    pageSize={pageSize}
                    current={currentPage}
                    className="custom-pagination"
                />
            </div>
        </Modal>
    );
};
export default ImportParts;
