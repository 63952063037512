import React, { useEffect, useRef, useState } from 'react';
import { Select } from 'antd';
import { reportdealerShipsApi } from '../../Services/userEndpoints';
import { CaretDownOutlined, SearchOutlined } from '@ant-design/icons';
import { webService } from '../../Services/web.service';

const { Option } = Select;

const DealerSelect = ({
    records,
    dealerList,
    setDealerList,
    setFilteredDealerList,
    searchState,
    handleDropdownVisibleChange,
    onSearch,
    selectedValue = undefined,
    form = undefined,
    name = undefined,
    handleSelectChange,
}) => {
    const [value, setValue] = useState(undefined);
    const isListLoading = useRef(false);

    const [dealerListAPIQueryParams, setDealerListAPIQueryParams] = useState({
        skip: 1,
        limit: 500,
    });

    const handlePartSelectScroll = (event) => {
        const { target } = event;

        const threshold = target.scrollTop + target.offsetHeight >= target.scrollHeight / 2;

        if (threshold) {
            setDealerListAPIQueryParams({
                ...dealerListAPIQueryParams,
                skip: ++dealerListAPIQueryParams.skip,
            });
        }
    };

    const searchRecords = (e) => {
        const value = e.target.value.toLowerCase();
        setFilteredDealerList(
            dealerList.filter(
                (record) => record.code.toLowerCase().includes(value) || record.name.toLowerCase().includes(value)
            )
        );
    };

    const handleOnBlurCustomerSelect = () => {
        if (form && name) {
            form.validateFields([name]);
        }
        if (!value) {
            setDealerList([]);
            setDealerListAPIQueryParams({
                ...dealerListAPIQueryParams,
                skip: 1,
                searchName: null,
            });
        }
    };

    const getDealerList = async () => {
        try {
            webService
                .get(`${reportdealerShipsApi}`, {
                    ...dealerListAPIQueryParams,
                })
                .then((response) => {
                    const responseData = response?.data?.rows;
                    setDealerList(responseData);
                    setFilteredDealerList(responseData);
                })
                .catch(() => {});
        } catch (error) {
            console.error('Error Status', error);
        } finally {
            isListLoading.current = false;
        }
    };

    const handleChange = (e) => {
        setValue(e);
        handleSelectChange(e);
        if (form) {
            form.setFieldsValue({ [name]: e });
            form.validateFields(['name', 'dealershipId']);
        }
    };

    useEffect(() => {
        getDealerList();
    }, [dealerListAPIQueryParams]);

    useEffect(() => {
        setValue(selectedValue);
    }, [selectedValue]);

    return (
        <>
            <Select
                showSearch
                className="custom-select custom-placeholder-text"
                onChange={handleChange}
                // onBlur={handleOnBlurCustomerSelect}
                filterOption={false}
                optionFilterProp="children"
                onKeyUp={(e) => searchRecords(e)}
                placeholder="Select Dealer"
                value={value}
                onPopupScroll={handlePartSelectScroll}
                onSearch={(value) => onSearch('dealer', value)}
                onDropdownVisibleChange={(open) => handleDropdownVisibleChange('dealer', open)}
                suffixIcon={
                    searchState.dealer.onSearch ? (
                        <SearchOutlined className="dropdown-icon" />
                    ) : (
                        <CaretDownOutlined className="dropdown-icon" />
                    )
                }
            >
                {records?.map((record) => {
                    return (
                        <Option key={record?.id} value={`${record?.code}`}>
                            <div className="options">
                                <p>{record?.code}</p>
                                <p className="elipse-width options-description">{record?.name}</p>
                            </div>
                        </Option>
                    );
                })}
            </Select>
        </>
    );
};

export default DealerSelect;
