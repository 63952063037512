import React from 'react';
import './style.scss'; // Import the CSS for styling

const ThreeDotsLoader = () => {
    return (
        <div className="dots-loader">
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
};

export default ThreeDotsLoader;
