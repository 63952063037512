import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: {},
    reducers: {
        set: (state, action) => {
            const { user, dealership } = action.payload;
            return { user, dealer: dealership };
        },
    },
});

export const { set } = userSlice.actions;

export default userSlice.reducer;
