const usePartsRebateSummaryTableColumns = (rebateDetail) => {
    const columns = [
        {
            title: 'Month',
            key: 'month',
            isDefault: true,
            isSelected: true,
        },
        ...(!rebateDetail
            ? [
                  {
                      title: 'Dealer Code',
                      key: 'dealerCode',
                      isDefault: true,
                      isSelected: true,
                  },
                  {
                      title: 'Dealership Name',
                      key: 'dealershipName',
                      isDefault: true,
                      isSelected: true,
                  },
              ]
            : []),

        {
            title: 'Working Days',
            key: 'workingDays',
            isDefault: true,
            isSelected: true,
        },
        {
            title: 'Working Days %',
            key: 'workingDaysPercentage',
            isDefault: true,
            isSelected: true,
        },
        {
            title: 'Total Sales (Quoted Price)',
            key: 'totalSales',
            isDefault: true,
            isSelected: true,
        },
        {
            title: 'Total Rebate Sales',
            key: 'totalRebateSales',
            isDefault: false,
            isSelected: true,
        },
        {
            title: 'Quantity Sold',
            key: 'quantitySold',
            isDefault: false,
            isSelected: true,
        },
        {
            title: 'Dealer Cost',
            key: 'dealerCost',
            isDefault: false,
            isSelected: true,
        },
        {
            title: 'Gross Profit($)',
            key: 'grossProfit',
            isDefault: false,
            isSelected: true,
        },
        {
            title: 'Gross Profit(%)',
            key: 'grossProfitPercentage',
            isDefault: false,
            isSelected: true,
        },
        {
            title: 'Monthly Allowance',
            key: 'monthlyAllowance',
            isDefault: false,
            isSelected: true,
        },
        {
            title: 'Rebate Usage ($)',
            key: 'rebateUsage',
            isDefault: false,
            isSelected: true,
        },
        {
            title: 'Unused Allowance',
            key: 'unusedAllowance',
            isDefault: false,
            isSelected: true,
        },
        {
            title: 'Rebate Paid Amount',
            key: 'rebatePaidAmount',
            isDefault: false,
            isSelected: true,
        },
        {
            title: 'GP Recovery',
            key: 'gpRecovery',
            isDefault: false,
            isSelected: true,
        },
        {
            title: 'Handling Fee',
            key: 'handlingFee',
            isDefault: false,
            isSelected: true,
        },
    ];

    let defaultColumns = [];
    let additionalColumns = [];

    columns.forEach((column) => {
        if (column.isDefault) {
            defaultColumns.push(column);
        } else {
            additionalColumns.push(column);
        }
    });

    let selectedTableColumns = [];

    columns.forEach((column) => {
        if (column.isSelected && !column.isDefault) {
            selectedTableColumns.push(column.key);
        }
    });

    return [defaultColumns, additionalColumns, selectedTableColumns];
};

export default usePartsRebateSummaryTableColumns;
