import { useContext, useEffect, useState } from 'react';
import { commonSorter, formatDate, render } from '../../../Constants/constants';
import '../../../Assets/Styles/global-styles.css';
import CustomizedButton from '../../../Components/Button';
import { EyeOutlined, DownloadOutlined, CaretDownOutlined, EditOutlined } from '@ant-design/icons';
import { Form, Select, Tag, Tooltip } from 'antd';
import { customerStatusList, customerUnApprovalStatusList } from '../../../Constants/enum';
import { QuoteContext } from '../../../Pages/Quote-Calculator/quoteContext';

const useGetPartsRebateCustomerTableColumns = (
    selectedTableColumns = [],
    rebateDetail,
    handleStatusChange,
    handleReasonChange,
    handleClickExport,
    handleViewIconClick,
    handleEditIconClick
) => {
    const { customerType } = useContext(QuoteContext);
    // Function to get the status class based on the approval status
    const getQuoteStatusClassName = (statusId) => {
        const statusName = getStatusName(statusId);
        return statusName === 'Approved' ? 'invoiced-color' : statusName === 'Pending' ? 'pending-clr' : 'delete-clr';
    };

    const getReasonTextById = (reasonId) => {
        const reasonObj = customerUnApprovalStatusList.find((item) => item.id === reasonId);
        return reasonObj ? reasonObj.reason : 'Unknown';
    };

    const getStatusName = (statusId) => {
        const statusObj = customerStatusList.find((approval) => approval.id == statusId);
        return statusObj ? statusObj.status : 'Unknown';
    };

    const additionalColumns = [
        {
            title: 'Business ABN',
            dataIndex: 'businessABN',
            key: 'businessABN',
            align: 'left',
            width: '124px',
            className: 'table-sorter font',
            render: render,
            sorter: commonSorter('businessABN'),
        },
        {
            title: 'Customer Type',
            dataIndex: 'customerType',
            key: 'customerType',
            align: 'left',
            width: '103px',
            className: 'table-sorter font',
            render: render,
        },
        {
            title: 'Approval Status',
            dataIndex: 'approvalStatus',
            key: 'approvalStatus',
            align: 'left',
            width: '131px',
            className: 'table-sorter font',
            render: (text, record) => (
                <>
                    <Tag
                        bordered
                        className={`tabel-dropdown ${getQuoteStatusClassName(record.approvalStatus)}`}
                        style={{ width: '100px' }}
                    >
                        <div className="display-center-all">
                            {getStatusName(record.approvalStatus)}
                            <CaretDownOutlined style={{ marginLeft: '5px', marginTop: '2px' }} />
                        </div>
                    </Tag>
                    <Form.Item className="custom-form-item">
                        <Select
                            className="custom-select-status dropdown-center"
                            onChange={(e) => handleStatusChange(e, record)}
                            value={record.approvalStatus}
                        >
                            {/* Conditionally render approval status options based on customer type */}
                            {record.customerType === 'Trade'
                                ? // For Trade customer type, show all options
                                  customerStatusList.map((item) => (
                                      <Select.Option key={item.id} value={item.id}>
                                          {item.status}
                                      </Select.Option>
                                  ))
                                : // For Retail customer type, exclude "Approved"
                                  customerStatusList
                                      .filter((item) => item.status !== 'Approved')
                                      .map((item) => (
                                          <Select.Option key={item.id} value={item.id}>
                                              {item.status}
                                          </Select.Option>
                                      ))}
                        </Select>
                    </Form.Item>
                </>
            ),
        },
        {
            title: <div style={{ textAlign: 'left' }}>Reason</div>,
            dataIndex: 'reason',
            key: 'reason',
            align: 'center',
            width: '165px',
            className: 'table-sorter font',
            render: (text, record) => {
                const reasonText = getReasonTextById(record.reason);

                return (
                    <Select
                        value={reasonText}
                        className="approval-status-dropdown"
                        onChange={(value) => handleReasonChange(value, record)}
                        style={{ width: 120 }}
                    >
                        {customerUnApprovalStatusList.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                <span>{item.reason}</span>
                            </Select.Option>
                        ))}
                    </Select>
                );
            },
        },
    ];

    const defaultColumns = [
        ...(!rebateDetail
            ? [
                  {
                      title: 'Dealer Code',
                      dataIndex: 'dealershipCode',
                      key: 'dealershipCode',
                      align: 'left',
                      fixed: 'left',
                      width: '93px',
                      sorter: commonSorter('dealershipCode'),
                      className: 'table-sorter font',
                      render: render,
                  },
                  {
                      title: 'Dealership Name',
                      dataIndex: 'dealershipName',
                      key: 'dealershipName',
                      align: 'left',
                      fixed: 'left',
                      width: '169px',
                      sorter: commonSorter('dealershipName'),
                      className: 'table-sorter font',
                      render: render,
                  },
                  {
                      title: 'Customer Name',
                      dataIndex: 'customerName',
                      key: 'customerName',
                      align: 'left',
                      fixed: 'left',
                      width: '145px',
                      sorter: commonSorter('customerName'),
                      className: 'table-sorter font',
                      render: render,
                  },
                  {
                      title: 'DMS ID',
                      dataIndex: 'DMSId',
                      key: 'DMSId',
                      align: 'left',
                      fixed: 'left',
                      width: '110px',
                      sorter: commonSorter('DMSId'),
                      className: 'table-sorter font',
                      render: render,
                  },
                  {
                      title: 'Business Name',
                      dataIndex: 'businessName',
                      key: 'businessName',
                      align: 'left',
                      fixed: 'left',
                      width: '197px',
                      sorter: commonSorter('businessName'),
                      className: 'table-sorter font',
                      render: render,
                  },
              ]
            : [
                  {
                      title: 'Customer Name',
                      dataIndex: 'customerName',
                      key: 'customerName',
                      align: 'left',
                      width: '225px',
                      className: 'table-sorter font',
                  },
                  {
                      title: 'DMS ID',
                      dataIndex: 'DMSId',
                      key: 'DMSId',
                      align: 'left',
                      width: '182px',
                      className: 'table-sorter font',
                  },
                  {
                      title: 'Business Name',
                      dataIndex: 'businessName',
                      key: 'businessName',
                      align: 'left',
                      width: '284px',
                      className: 'table-sorter font',
                      render: (text) => (text ? text : '-'),
                  },
                  {
                      title: 'Customer ABN',
                      dataIndex: 'customerABN',
                      key: 'customerABN',
                      align: 'center',
                      width: '168px',
                      className: 'table-sorter font',
                  },
                  {
                      title: 'Invoice Count',
                      dataIndex: 'invoiceCount',
                      key: 'invoiceCount',
                      align: 'center',
                      width: '149px',
                      className: 'table-sorter font',
                  },
                  {
                      title: 'Total Rebate',
                      dataIndex: 'totalRebateSales',
                      key: 'totalRebateSales',
                      align: 'center',
                      width: '149px',
                      className: 'table-sorter font',
                      render: (text) => (text ? text : '-'),
                  },
                  {
                      title: 'Actions',
                      key: 'actions',
                      align: 'center',
                      width: '121px',
                      className: 'table-sorter font',
                      render: (text, record) => (
                          <span style={{ whiteSpace: 'nowrap' }}>
                              <CustomizedButton
                                  type="danger"
                                  icon={<EyeOutlined className="quote-edit-btn quote-edit-mar" />}
                                  onClick={() => handleViewIconClick(record)}
                              ></CustomizedButton>
                              <CustomizedButton
                                  type="danger"
                                  icon={<DownloadOutlined className="quote-edit-btn quote-delete-mar" />}
                                  onClick={() => handleClickExport(record)}
                              ></CustomizedButton>
                          </span>
                      ),
                  },
              ]),
    ];

    const actionsColumn = {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        align: 'center',
        fixed: 'right',
        width: '110px',
        className: 'table-sorter font',
        render: (_, record, index) => {
            return (
                <span className="quote-action-btn" style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <CustomizedButton
                        type="danger"
                        icon={<EyeOutlined className="quote-edit-btn quote-edit-mar" />}
                        onClick={() => handleViewIconClick(record)}
                    ></CustomizedButton>
                    <CustomizedButton type="danger">
                        <EditOutlined
                            className="quote-edit-btn quote-edit-mar"
                            onClick={() => handleEditIconClick(record)}
                        />
                    </CustomizedButton>
                    <CustomizedButton
                        type="danger"
                        icon={<DownloadOutlined className="quote-edit-btn quote-delete-mar" />}
                        onClick={() => handleClickExport(record)}
                    ></CustomizedButton>
                </span>
            );
        },
    };

    const [columns, setColumns] = useState([
        ...defaultColumns,
        ...(!rebateDetail ? [...additionalColumns, actionsColumn] : []),
    ]);

    const setSelectedTableColumns = (updatedColumns = []) => {
        const values = [
            ...defaultColumns,
            ...(!rebateDetail
                ? [...additionalColumns.filter((addColumn) => updatedColumns.includes(addColumn.key)), actionsColumn]
                : []),
        ];

        setColumns(values);
    };

    return { columns, setSelectedTableColumns };
};
export default useGetPartsRebateCustomerTableColumns;
