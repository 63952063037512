import React, { useContext, useEffect, useState } from 'react';
import { Input, Pagination } from 'antd';
import './index.css';
import '../../Assets/Styles/global-styles.css';
import '../../Layouts/Sidebar/index.css';
import '../../Assets/Styles/fonts.css';
import '../../Assets/Styles/global-styles.css';
import { commonSorter } from '../../Constants/constants';
import CommonTable from '../../Components/Table';
import { historicSalesDataApi } from '../../Services/userEndpoints';
import { QuoteContext } from './quoteContext';
import { paginationConfig } from '../../Components/Pagination';
import { webService } from '../../Services/web.service';

const HistoricalSalesData = ({ selectedCustomerID }) => {
    const { Search } = Input;
    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const { setHistoricData } = useContext(QuoteContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(paginationConfig?.defaultPageSize);
    const [totalItems, setTotalItems] = useState(0);

    const handleSearch = (value) => {
        setSearchText(value);
        if (!value) {
            getHistoricDataList();
            // setData(data);
            return;
        }
        const filteredData = data.filter((item) => {
            return Object.keys(item).some((key) => String(item[key]).toLowerCase().includes(value.toLowerCase()));
        });

        setData(filteredData);
    };

    const getHistoricDataList = async () => {
        try {
            const payload = {
                customerId: selectedCustomerID,
                pageNumber: currentPage,
                pageSize: pageSize,
            };

            webService
                .post(historicSalesDataApi, payload)
                .then((response) => {
                    const responseData = response.data.rows || [];
                    const totalRecords = response.data.total || 0;
                    setData(responseData);
                    setHistoricData(responseData);
                    setTotalItems(totalRecords);
                })
                .catch((error) => {
                    console.log('Error :', error);
                });
        } catch (error) {
            console.log('Error :', error);
        }
    };

    useEffect(() => {
        if (selectedCustomerID) {
            getHistoricDataList();
        }
    }, [selectedCustomerID]);

    const onChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const onShowSizeChange = (current, size) => {
        setCurrentPage(current);
        setPageSize(size);
    };

    const columns = [
        {
            title: 'Part Number',
            width: 135,
            dataIndex: 'partNumber',
            key: 'partNumber',
            sorter: commonSorter('partNumber'),
            align: 'left',
            className: 'table-sorter font',
        },
        {
            title: 'Description',
            width: 150,
            dataIndex: 'description',
            key: 'description',
            sorter: commonSorter('description'),
            className: 'table-sorter font',
        },
        {
            title: 'Quantity',
            width: 90,
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'right',
            sorter: commonSorter('quantity'),
            className: 'table-sorter font',
        },
        {
            title: 'Conversion Rate',
            width: 90,
            dataIndex: 'conversionrate',
            key: 'conversionrate',
            align: 'right',
            sorter: commonSorter('conversionrate'),
            className: 'table-sorter font',
        },
        {
            title: 'Last Sales to DMS ID',
            width: 90,
            dataIndex: 'lastsalestocustomer',
            key: 'lastsalestocustomer',
            align: 'right',
            sorter: commonSorter('lastsalestocustomer'),
            className: 'table-sorter font',
        },
        {
            title: 'Average Sales Price',
            width: 90,
            dataIndex: 'averagesellprice',
            key: 'averagesellprice',
            align: 'right',
            sorter: commonSorter('averagesellprice'),
            className: 'table-sorter font',
        },
        {
            title: 'Lowest Price Sold',
            width: 90,
            dataIndex: 'lowestpricesold',
            key: 'lowestpricesold',
            align: 'right',
            sorter: commonSorter('lowestpricesold'),
            className: 'table-sorter font',
        },
    ];
    return (
        <div>
            <div className="historic-container">
                <div className="total-search-container">
                    <div className="total-text">
                        Total:
                        <span className="item">{`${data.length} item(s)`}</span>
                    </div>
                    <div className="action-btn-container">
                        <Search
                            placeholder="Search"
                            onChange={(e) => handleSearch(e.target.value)}
                            value={searchText}
                            enterButton
                            className="serach-field"
                        />
                    </div>
                </div>
                <div className="historic-table">
                    <CommonTable dataSource={data} columns={columns} pagination={false} />
                </div>
                <div className="quote-pagination">
                    <Pagination
                        onShowSizeChange={onShowSizeChange}
                        onChange={onChange}
                        total={totalItems}
                        className="custom-pagination"
                        current={currentPage}
                        {...paginationConfig}
                    />
                </div>
            </div>
        </div>
    );
};
export default HistoricalSalesData;
