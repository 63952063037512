import { useEffect, useState } from 'react';
import { webService } from '../../Services/web.service';
import { useDispatch } from 'react-redux';
import { set } from '../../store/userSlice';

const useGetUser = () => {
    const [user, setUser] = useState({});
    const [dealer, setDealer] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        webService.get('user').then((response) => {
            setUser(response?.data?.user);
            setDealer(response?.data?.dealership);

            dispatch(
                set({
                    user: response?.data?.user,
                    dealership: response?.data?.dealership,
                })
            );
        });
    }, []);

    return { user, dealer };
};

export default useGetUser;
