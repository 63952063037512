import { QuoteProvider } from './Pages/Quote-Calculator/quoteContext';
import Router from './Routes/Router';

const App = () => {
    return (
        <>
            <QuoteProvider>
                <Router />
            </QuoteProvider>
        </>
    );
};

export default App;
