import React, { useEffect, useState } from 'react';
import './styles.css';
import { Modal, Pagination, Table } from 'antd';
import CustomizedButton from '../../Button';
import { CloseOutlined } from '@ant-design/icons';
import CommonMessage from '../../Message';
import { loadFileData } from '../../../Constants/fileLoader';

const ImportReportsPayment = ({ isVisible, handleClose, file, handleGetImportedRecords }) => {
    const [filename, setFilename] = useState(null);
    const [columns, setColumns] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);

    const handleTransformToCamelCase = (inputString) => {
        switch (inputString) {
            case 'Dealer Code':
                return 'dealerCode';
            case 'Allowance Year':
                return 'allowanceYear';
            case 'Allowance Month':
                return 'allowanceMonth';
            case 'Amount':
                return 'amount';
            case 'Receipt Id':
                return 'receiptId';

            default:
                return inputString.toLowerCase().replace(/\s+(.)/g, function (match, group) {
                    return group.toUpperCase();
                });
        }
    };

    const loadFile = async (file) => {
        const expectedHeaders = ['Dealer Code', 'Allowance Year', 'Allowance Month', 'Amount', 'Receipt Id'];

        try {
            const { filename, columns, dataSource } = await loadFileData(
                file,
                expectedHeaders,
                handleTransformToCamelCase
            );

            setColumns(columns);
            setDataSource(dataSource);
            setFilename(filename);
        } catch (error) {
            CommonMessage('error', error.message);
        }
    };

    const handleImport = () => {
        handleGetImportedRecords(dataSource);
        CommonMessage('success', 'Dealer Payments File Imported.');
        closeModal();
    };

    const closeModal = () => {
        setDataSource([]);
        handleClose();
    };

    useEffect(() => {
        if (file) {
            loadFile(file);
        }
    }, [file]);
    const onChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };
    const startIndex = currentPage;
    const endIndex = startIndex + pageSize;
    const tableData = dataSource.slice(startIndex, endIndex);
    // const tableData = Array.isArray(dataSource) ? dataSource.slice(startIndex, endIndex) : [];

    return (
        <Modal
            open={isVisible}
            className="import-modal"
            closable={false}
            footer={[
                <span className="footer">
                    <CustomizedButton className="reset-btn" onClick={closeModal}>
                        Cancel
                    </CustomizedButton>
                    <CustomizedButton type="primary" className="import-btn" onClick={handleImport}>
                        Import
                    </CustomizedButton>
                </span>,
            ]}
        >
            <div className="header">
                <h3>{filename}</h3>
                <span className="close-icon">
                    <CloseOutlined className="close-icon" onClick={closeModal} />
                </span>
            </div>
            <div className="content ">
                <div className="modal-container">
                    <Table dataSource={dataSource} columns={columns} pagination={false} />
                </div>
            </div>
            <div className="parts-pagination">
                <Pagination
                    defaultPageSize={15}
                    pageSizeOptions={['15', '25', '50', '100']}
                    showSizeChanger={true}
                    onChange={onChange}
                    total={dataSource.length}
                    defaultCurrent={1}
                    className="custom-pagination"
                />
            </div>
        </Modal>
    );
};
export default ImportReportsPayment;
