import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from 'chart.js';

// Register ChartJS components
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

const ChartCard = ({ title, description, data }) => {
    const totalValue = data.reduce((acc, item) => acc + item.value, 0);
    const centerText = (data[0].value / totalValue) * 100;
    const sideLeftText = (data[0].value / totalValue) * 100;
    const sideRightText = (data[1].value / totalValue) * 100;

    const chartData = {
        labels: [data[0].label, data[1].label],
        datasets: [
            {
                data: data.map((item) => item.value),
                backgroundColor: ['#002C5F', '#E5E5E5'],
                borderColor: ['#002C5F', '#E5E5E5'],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return context.raw + '%';
                    },
                },
            },
            legend: {
                display: false,
            },
            textInside: {
                text: 'GeeksforGeeks',
                color: 'green',
                fontSize: 28,
            },
        },

        cutout: '70%',
        rotation: -90,
        circumference: 180,
    };

    return (
        <div style={{ width: '100%', height: '4vw', position: 'relative' }}>
            <p
                style={{
                    textAlign: 'center',
                    position: 'relative',
                    bottom: '13px',
                    margin: 0,
                    color: '#67707f',
                    fontWeight: 600,
                }}
            >
                {description}
            </p>
            <div style={{ width: '100%', height: '75%', position: 'relative', bottom: '12px' }}>
                {/* Overlay Div for Center Text */}
                <div
                    style={{
                        position: 'absolute',
                        top: '80%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        textAlign: 'center',
                        fontSize: '11px',
                        fontWeight: 'bold',
                        color: '#002C5F',
                    }}
                >
                    {centerText.toFixed(1)}%
                </div>
                <Doughnut data={chartData} options={options} />
                <div
                    style={{
                        position: 'absolute',
                        top: '75%',
                        left: '30%',
                        transform: 'translate(-50%, 0)',
                        textAlign: 'center',
                    }}
                >
                    <p style={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{sideLeftText.toFixed(1)}%</p>
                </div>
                {/* Right text */}
                <div
                    style={{
                        position: 'absolute',
                        top: '75%',
                        right: '30%',
                        transform: 'translate(50%, 0)',
                        textAlign: 'center',
                    }}
                >
                    <p style={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{sideRightText.toFixed(1)}%</p>
                </div>
            </div>
        </div>
    );
};

export default ChartCard;
