import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import AccessoriesRevenue1 from '../../Assets/Icons/Accessories Revenue1.png';
import APNV from '../../Assets/Icons/APNV.png';
import APNVVehicles from '../../Assets/Icons/APNV Vehicles.png';
import StockOrderRatio from '../../Assets/Icons/Stock Order Ratio.png';
import EstimatedIMRPayment from '../../Assets/Icons/Estimated IMR Payment.png';
import StretchedTarget from '../../Assets/Icons/Stretched Target.png';
import WorkDays from '../../Assets/Icons/Work Days.png';
import EOMBonus from '../../Assets/Icons/EOM Bonus.png';
import ChartCard from './SpeedoMeterChart';

import { sideNavCollapsedState } from '../../Recoil/recoilState';
import { useRecoilState } from 'recoil';

const reportCardStyle = {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#fff',
};

const imageTextContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '10px',
};

const imageContainerStyle = {
    marginRight: '10px',
    margin: '0',
};

const imageStyle = {
    width: '40px',
    height: '44px',
};

const textContainerStyle = {};

const titleStyle = {
    color: '#67707f',
    fontWeight: 'bold',
    fontSize: '18px',
    margin: '0px',
};

const descriptionStyle = {
    color: '#67707f',
    fontWeight: '500',
    fontSize: '14px',
    margin: '0px',
};

const Cards1 = () => {
    const [collapsed, setCollapsed] = useRecoilState(sideNavCollapsedState);

    const Filtercard = [
        { id: 1, title: '0', description: 'APNV', icon: APNV },
        { id: 2, title: '$0', description: 'Accessories Purchase', icon: AccessoriesRevenue1 },
        { id: 3, title: '0', description: 'Vehicles sold', icon: APNVVehicles },
        { id: 4, title: '$0', description: 'Dealer Wholesale Purchase', icon: EOMBonus },
        { id: 5, title: '0', description: 'EDM Bonus', icon: EOMBonus },
        { id: 6, title: '0', description: 'IMR', icon: EOMBonus },
        { id: 7, title: '0', description: 'Sales Target', icon: APNVVehicles },
        { id: 8, title: '0', description: 'WorkDays', icon: AccessoriesRevenue1 },
    ];

    const chartCards = [
        { id: 9, title: 'Performance Chart', description: 'Stock Order Ratio', data: [70, 30] },
        { id: 10, title: 'Revenue Chart', description: 'Progress to Target', data: [70, 30] },
    ];

    const Filtercard2 = [
        { id: 1, title: '0', description: 'APNV', icon: APNV },
        { id: 2, title: '$0', description: 'Accessories Purchase', icon: AccessoriesRevenue1 },
        { id: 3, title: '0', description: 'Vehicles sold', icon: APNVVehicles },
        { id: 4, title: '$0', description: 'Dealer Wholesale Purchase', icon: EOMBonus },
        { id: 5, title: '0', description: 'EDM Bonus', icon: EOMBonus },
        // { id: 6, title: "0", description: "IMR", icon: EOMBonus },
        { id: 7, title: '0', description: 'Sales Target', icon: APNVVehicles },
        { id: 8, title: '0', description: 'WorkDays', icon: AccessoriesRevenue1 },
    ];

    return (
        <>
            <div>
                <h4 style={{ color: '#002C5F', margin: '15px 15px' }}>MTD</h4>
                <Row
                    gutter={16}
                    justify="start"
                    style={{
                        columnGap: '36px',
                        rowGap: '20px',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start', // Align items to the top
                        backgroundColor: '#EEEEEE',
                        margin: '0 10px',
                        padding: '10px 0', // Add padding to avoid content being cut off
                    }}
                >
                    {Filtercard.map((card) => (
                        <Col
                            key={card.id}
                            xs={24}
                            sm={4}
                            md={4}
                            className={collapsed ? 'collapsed-card' : 'expanded-card'}
                        >
                            <Link>
                                <div className="report-card-custom" style={reportCardStyle}>
                                    {/* Container for image and text */}
                                    <div style={imageTextContainerStyle}>
                                        {/* Image takes the left side */}
                                        {card.icon && (
                                            <div style={imageContainerStyle}>
                                                <img src={card.icon} alt="icon" style={imageStyle} />
                                            </div>
                                        )}

                                        {/* Title and Description take the right side */}
                                        <div style={textContainerStyle}>
                                            <h2 style={titleStyle}>{card.title}</h2>
                                            <h2 style={descriptionStyle}>{card.description}</h2>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                    ))}

                    {chartCards.map((chart) => (
                        <Col
                            key={chart.id}
                            xs={24}
                            sm={4}
                            md={4}
                            className={collapsed ? 'collapsed-card' : 'expanded-card'}
                        >
                            <div className="report-card-custom" style={reportCardStyle}>
                                <ChartCard
                                    title={chart.title}
                                    description={chart.description}
                                    data={[
                                        { label: 'Category 1', value: chart.data[0] },
                                        { label: 'Category 2', value: chart.data[1] },
                                    ]}
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>

            <div>
                <h4 style={{ color: '#002C5F', margin: '15px 15px' }}>YTD</h4>
                <Row
                    gutter={16}
                    justify="start"
                    style={{
                        columnGap: '36px',
                        rowGap: '20px',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        backgroundColor: '#EEEEEE',
                        margin: '0 10px',
                        padding: '10px 0',
                    }}
                >
                    {Filtercard2.map((card) => (
                        <Col
                            key={card.id}
                            xs={24}
                            sm={4}
                            md={4}
                            className={collapsed ? 'collapsed-card' : 'expanded-card'}
                        >
                            <Link>
                                <div className="report-card-custom" style={reportCardStyle}>
                                    {/* Container for image and text */}
                                    <div style={imageTextContainerStyle}>
                                        {/* Image takes the left side */}
                                        {card.icon && (
                                            <div style={imageContainerStyle}>
                                                <img src={card.icon} alt="icon" style={imageStyle} />
                                            </div>
                                        )}

                                        {/* Title and Description take the right side */}
                                        <div style={textContainerStyle}>
                                            <h2 style={titleStyle}>{card.title}</h2>
                                            <h2 style={descriptionStyle}>{card.description}</h2>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                    ))}

                    {chartCards.map((chart) => (
                        <Col
                            key={chart.id}
                            xs={24}
                            sm={4}
                            md={4}
                            className={collapsed ? 'collapsed-card' : 'expanded-card'}
                        >
                            <div className="report-card-custom" style={reportCardStyle}>
                                <ChartCard
                                    title={chart.title}
                                    description={chart.description}
                                    data={[
                                        { label: 'Category 1', value: chart.data[0] },
                                        { label: 'Category 2', value: chart.data[1] },
                                    ]}
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </>
    );
};

export default Cards1;
