import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import CustomizedButton from '../Button';
import '../../Assets/Styles/global-styles.css';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import ThreeDotsLoader from '../ThreeDotsLoader/ThreeDotsLoader';

const CommonModal = ({
    title,
    visible,
    onCancel,
    onConfirm,
    children,
    showExportButton,
    showDiscardButton,
    showBackButton,
    showSaveButton,
    showUpdateButton,
    showUnable,
    showDeleteButton,
    isDownloading = false,
}) => {
    const isPrimaryType =
        showExportButton || showDiscardButton || showUpdateButton || showSaveButton || showDeleteButton;

    const [footerActions, setFooterActions] = useState([]);

    useEffect(() => {
        const values = [
            <CustomizedButton
                key="cancel"
                type={showBackButton && 'primary'}
                onClick={onCancel}
                className={
                    showBackButton
                        ? 'apply-btn export-btn discard-btn'
                        : showUnable
                          ? 'unable-cancel-hide'
                          : 'modal-cancel-btn'
                }
            >
                {showBackButton ? 'No' : ' Cancel'}
            </CustomizedButton>,
            <CustomizedButton
                key="update"
                type={(isPrimaryType || showUnable) && 'primary'}
                onClick={onConfirm}
                className={`${
                    showUnable
                        ? 'unable-confirm apply-btn export-btn discard-btn'
                        : isPrimaryType
                          ? 'apply-btn export-btn discard-btn'
                          : 'modal-cancel-btn'
                }`}
            >
                {showExportButton
                    ? 'Export'
                    : showDiscardButton
                      ? 'Discard'
                      : showBackButton
                        ? 'Yes'
                        : showUpdateButton
                          ? 'Update'
                          : showSaveButton
                            ? 'Save'
                            : showUnable
                              ? 'Ok'
                              : 'Delete'}
            </CustomizedButton>,
        ];

        if (isDownloading) {
            values[1] = (
                <CustomizedButton
                    key="update"
                    type={(isPrimaryType || showUnable) && 'primary'}
                    className={`${
                        showUnable
                            ? 'unable-confirm apply-btn export-btn discard-btn'
                            : isPrimaryType
                              ? 'apply-btn export-btn discard-btn'
                              : 'modal-cancel-btn'
                    }`}
                >
                    {'Exporting'} <ThreeDotsLoader />
                </CustomizedButton>
            );
        }

        setFooterActions(values);
    }, [
        showExportButton,
        showDiscardButton,
        showBackButton,
        showSaveButton,
        showUpdateButton,
        showUnable,
        showDeleteButton,
        isDownloading,
    ]);

    return (
        <Modal
            title={title}
            className={` ${
                showUnable ? 'unable-delete-modal custom-modal centered-modal' : 'custom-modal centered-modal'
            } `}
            open={visible}
            onCancel={onCancel}
            closable={false}
            okButtonProps={{ style: { display: 'none' } }}
            footer={footerActions}
        >
            {children}
        </Modal>
    );
};
export default CommonModal;
