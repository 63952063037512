import React from 'react';
import HomePage from '../../Pages/HomePage';
import AdminHome from '../../Pages/AdminHomePage/adminHome';
import { useIsAdmin } from '../../shared/hooks/role';

const Home = () => {
    const isAdmin = useIsAdmin();

    return <>{isAdmin ? <AdminHome /> : <HomePage />}</>;
};

export default Home;
