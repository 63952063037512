import React, { useEffect, useState, useContext, useRef } from 'react';
import '../../Assets/Styles/global-styles.css';
import CommonTable from '../../Components/Table';
import { CaretDownOutlined, SearchOutlined } from '@ant-design/icons';
import CustomizedButton from '../../Components/Button';
import { Typography, Select, Form, Col, Row, Pagination, Input, message } from 'antd';
import '../../Assets/Styles/fonts.css';
import CommonModal from '../../Components/Modal';
import { RangeDateRangePicker } from '../../Components/Datepicker';
import { paginationConfig } from '../../Components/Pagination';
import {
    GetCustomerApprovalStatus,
    GetCustomerTypes,
    GetCustomerUnapprovalReason,
    RegionComponent,
} from '../../Constants/commonRegion';
import { useFormData } from '../../Constants/commonFormdata';
import { cleanObject } from '../../Constants/constants';
import moment from 'moment/moment';
import useGetPartsRebateCustomer from '../../shared/hooks/data/useGetPartsRebateCustomer';
import ManageColumnDrawer from '../Reporting/ManageColumnDrawer/ManageColumnDrawer';
import RebateManagementView from './rebateManagementView';
import { webService } from '../../Services/web.service';
import { customerInformation, dealershipsCustomers, rebateCustomerDownload } from '../../Services/userEndpoints';
import useGetPartsRebateCustomerTableColumns from '../../shared/hooks/columns/useGetPartsRebateCustomerTableColumns';
import usePartsRebateCustomerTableColumns from '../../shared/hooks/usePartsRebateCustomerTableColumns';
// import {
//   customerStatusList,
//   customerUnApprovalStatusList,
// } from "../../Constants/enum";
import { saveAs } from 'file-saver';
import { sortArray } from '../../Constants/utils';
import RebateManagementEditt from './rebatemanagementEdit';
import { QuoteContext } from '../Quote-Calculator/quoteContext';

const { Title } = Typography;
const { Option } = Select;

const RebateManagementCustomer = ({ rebateDetail }) => {
    const [defaultColumns, additionalColumns, selectedTableColumns] = usePartsRebateCustomerTableColumns(rebateDetail);

    const [listFilter, setListFilter] = useState({
        loadRecords: false,
        pageSize: paginationConfig?.defaultPageSize,
        pageNumber: 1,
        additionalColumns: !rebateDetail ? selectedTableColumns : ['invoiceCount', 'totalRebate'],
        ...(!rebateDetail ? { regionId: 0 } : {}),
    });

    const { partsRebateDetails, setListFilters } = useGetPartsRebateCustomer(listFilter, rebateDetail);

    const [isAnyFilterSelected, setIsAnyFilterSelected] = useState(false);
    const [exportModalVisible, setExportModalVisible] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([...defaultColumns]);
    const [showColumns, setShowColumns] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [rebateRetailList, setRebateRetailList] = useState([]);
    const [modalCustomerId, setModalCustomerId] = useState(null);
    const [editingRecord, setEditingRecord] = useState(null);
    const [isFiltered, setIsFiltered] = useState(false);
    const [dmsId, setDmsId] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isViewModalVisible, setIsViewModalVisible] = useState(false);
    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
    const [statusValue, setStatusValue] = useState();
    const [isReasonModalOpen, setIsReasonModalOpen] = useState(false);
    const [editingStatusRecord, setEditingStatusRecord] = useState(null);
    const [downloadRecord, setDownloadRecord] = useState({});
    const [reason, setReason] = useState();
    const [customerFilters, setCustomerFilters] = useState([]);
    const [searchString, setSearchString] = useState();
    const actionCustomer = useRef({});

    const debounceTimeout = useRef(null);

    const {
        regionTypes,
        dealershipDetails,
        customerType,
        approvalStatus,
        setCostomerType,
        unApprovalStatus,
        setTotalInvoices,
        setRebateCustomer,
    } = useContext(QuoteContext);

    const {
        formData,
        isAnyDropdownSelected,
        searchState,
        handleonChange,
        handleSearch,
        handleDropdownVisibleChange,
        selectedDates,
    } = useFormData();

    const handleStatusChange = (e, record) => {
        setStatusValue(e);
        setDmsId(record.DMSId);
        actionCustomer.current.id = record.DMSId;
        actionCustomer.current.status = e;
        setIsStatusModalOpen(true);
    };

    const handleViewIconClick = (record) => {
        setIsViewModalVisible(true);
        setModalCustomerId(record.DMSId);
    };

    const handleEditIconClick = (record) => {
        setIsModalVisible(true);
        setDmsId(record.DMSId);
        actionCustomer.current.id = record.DMSId;
        setEditingRecord(record);
    };

    const handleReasonChange = (e, record) => {
        setReason(e);
        setDmsId(record.DMSId);
        actionCustomer.current.id = record.DMSId;
        actionCustomer.current.reason = e;
        setIsReasonModalOpen(true);
    };

    const handleClickExport = (record) => {
        setExportModalVisible(true);
        setDownloadRecord(record);
    };

    const { columns, setSelectedTableColumns } = useGetPartsRebateCustomerTableColumns(
        selectedTableColumns,
        rebateDetail,
        handleStatusChange,
        handleReasonChange,
        handleClickExport,
        handleViewIconClick,
        handleEditIconClick
    );

    const onCancelModal = () => {
        setExportModalVisible(false);
    };

    const handleApplyFilter = () => {
        const values = {
            ...listFilter,
            loadRecords: true,
        };
        setListFilter(values);
        setListFilters(values);
    };

    // Export Rebate Detail Api
    const handleConfirmExport = async () => {
        try {
            webService.get(`${rebateCustomerDownload}/${downloadRecord.DMSId}`).then((response) => {
                const { fileName, buffer } = response.data;
                const arrayBuffer = new Uint8Array(buffer.data).buffer;
                const blob = new Blob([arrayBuffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                saveAs(blob, fileName);
                message.success(`All invoices exported.`);
                setExportModalVisible(false);
            });
        } catch (error) {
            console.error('Error Download Excel:', error);
        }
    };

    // Update Columns
    const handleUpdateColumns = (selectedColumns = []) => {
        const values = {
            ...listFilter,
            additionalColumns: selectedColumns,
        };
        setListFilter(values);
        setListFilters(values);
        setSelectedTableColumns(selectedColumns);
        if (isAnyFilterSelected) {
            const values = {
                ...listFilter,
                additionalColumns: selectedColumns,
                loadRecords: true,
            };
            setListFilter(values);
            setListFilters(values);
        }
    };

    const handleOpenDrawer = () => {
        setOpenDrawer(true);
    };

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    };

    useEffect(() => {
        const anyShowHideChecked = additionalColumns.some((col) => selectedColumns.includes(col));
        setShowColumns(anyShowHideChecked);
    }, [selectedColumns]);

    const onPaginationChange = (page, size) => {
        const values = {
            ...listFilter,
            pageSize: size,
            pageNumber: page,
        };
        setListFilter(values);
        setListFilters(values);
    };
    const getTableList = () => {
        const values = {
            ...listFilter,
            loadRecords: true,
        };
        setListFilter(values);
        setListFilters(values);
    };
    const updateValues = async (updatedRecord) => {
        try {
            webService
                .put(`${customerInformation}/${dmsId}`, updatedRecord)
                .then((response) => {
                    getTableList();
                    message.success(response?.message);
                })
                .catch(() => {});
        } catch (error) {
            console.log('Error :', error);
        }
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    const handleClearFilter = () => {
        const values = {
            ...listFilter,
            ...(!rebateDetail ? { regionId: 0 } : {}),
            customerId: undefined,
            customerName: undefined,
            dealershipId: undefined,
            customerStatus: undefined,
            customerType: undefined,
            invoicesOrCredits: undefined,
            partNumber: undefined,
            partDescription: undefined,
            dateRange: undefined,
            dateStart: undefined,
            dateEnd: undefined,
            loadRecords: false,
        };

        setListFilter(values);
        setListFilters(values);
    };

    const reportType = regionTypes.find((report) => report?.name === formData.region);

    const dropdownRender = (menu) => (
        <div>
            <div className=" dropdown-container">
                <p className="dropdown-partnumber-heading">Code</p>
                <p className="dropdown-description-heading">Dealership Name</p>
            </div>
            {menu}
        </div>
    );

    const isAnyFilterSelectedFun = () => {
        setIsAnyFilterSelected(
            listFilter.regionId > 0 ||
                listFilter.dealershipId ||
                listFilter.invoicesOrCredits ||
                listFilter.customerStatus ||
                listFilter.customerType ||
                listFilter.dateRange ||
                listFilter.customerId ||
                listFilter.customerName
        );
    };

    const handleFilterChange = (name, value) => {
        let values = {};

        if (name === 'regionId') {
            if (value !== 0)
                values = {
                    ...listFilter,
                    regionId: value,
                    dealershipId: undefined,
                };
            else {
                values = {
                    ...listFilter,
                    regionId: value,
                };
            }

            setListFilter(values);
        } else if (name === 'customerId' || name === 'customerName') {
            values = {
                ...listFilter,
                customerId: value,
            };
        } else if (name === 'partNumber') {
            const partNumber = value.split('-')[1];
            const partDescription = value.split('-')[2];

            values = {
                ...listFilter,
                partNumber: partNumber,
                partDescription: partDescription,
            };
        } else {
            values = {
                ...listFilter,
                [name]: value,
            };
        }
        setListFilter(values);
    };

    const handleViewModalCancel = () => {
        setIsViewModalVisible(false);
    };

    const handleDateChange = (value) => {
        const values = {
            ...listFilter,
            dateRange: value,
            dateStart: moment(new Date(value[0])).format('YYYY-MM-DD'),
            dateEnd: moment(new Date(value[1])).format('YYYY-MM-DD'),
        };

        setListFilter(values);
    };

    useEffect(() => {
        setIsFiltered(
            (() => {
                return Object.keys(cleanObject(listFilter)).length > 0;
            })()
        );
        isAnyFilterSelectedFun();
    }, [listFilter]);

    useEffect(() => {
        const originalArray = partsRebateDetails?.data?.rows.map((row) => row.customerType);
        const distinctArray = [...new Set(originalArray?.filter((item) => item === 'Trade' || item === 'Retail'))];
        const data = partsRebateDetails?.data || {};
        const rebateRetail = data.rows || [];
        const totalInvoice = data.totalInvoices || '-';
        const totalAllRecords = data.total || 0;
        const totalRebate = data.totalRebateSales || 0;

        const grandTotal = {
            totalQuantity: data.totalQuantity || 0,
            totalQuotedPrice: data.totalQuotedPrice || 0,
            totalStockOrderPrice: data.totalStockOrderPrice || 0,
            totalGrossProfit: data.totalGrossProfit || 0,
            totalGrossProfitPercentage: data.totalGrossProfitPercentage || 0,
            totalRebate: data.totalRebate || 0,
        };
        setTotalInvoices(totalInvoice);
        setRebateRetailList(rebateRetail);
        setRebateCustomer(totalRebate);
        setTotalRecords(totalAllRecords);
        setCostomerType(distinctArray);
    }, [partsRebateDetails]);

    const onCancelStatusModal = () => {
        setIsStatusModalOpen(false);
        setEditingStatusRecord(null);
        setStatusValue(null);
    };

    const onCancelReasonModal = () => {
        setIsReasonModalOpen(false);
        setEditingStatusRecord(null);
    };

    const handleConfirmUpdate = async () => {
        const payload = {
            approvalStatus: actionCustomer.current.status,
        };
        await updateStatus(payload);
        setIsStatusModalOpen(false);
    };

    const handleConfirmReasonUpdate = async () => {
        const payload = {
            reason: actionCustomer.current.reason,
        };

        await updateReason(payload);
        setIsReasonModalOpen(false);
        setEditingStatusRecord(null);
    };

    const updateStatus = async (updatedRecord) => {
        try {
            webService
                .patch(`${customerInformation}/status/update/${actionCustomer.current.id}`, updatedRecord)
                .then((response) => {
                    getTableList();
                    message.success(response?.message);
                });
        } catch (error) {
            console.log('Error :', error);
        }
    };

    const updateReason = async (updatedRecord) => {
        try {
            webService
                .patch(`${customerInformation}/reason/update/${actionCustomer.current.id}`, updatedRecord)
                .then((response) => {
                    getTableList();
                    message.success(response?.message);
                });
        } catch (error) {
            console.log('Error :', error);
        }
    };

    useEffect(() => {
        getCustomerFilters();
    }, []);

    //customer dropdown filter
    const getCustomerFilters = async () => {
        webService
            .get(`${dealershipsCustomers}`)
            .then((response) => {
                const customerFilter = response?.data?.rows || [];
                setCustomerFilters(customerFilter);
            })
            .catch(() => {
                setCustomerFilters([]);
            });
    };

    const handleSearchString = (e) => {
        const value = e.target.value;
        setSearchString(value); // Update local search string state

        // Clear previous timeout to debounce the API call
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        // Set a new timeout to delay the API call
        debounceTimeout.current = setTimeout(() => {
            if (isAnyFilterSelected) {
                const values = {
                    ...listFilter,
                    loadRecords: true,
                    search: value,
                };
                setListFilter(values);
                setListFilters(values);
            }
        }, 500);
    };

    return (
        <div>
            {rebateDetail && (
                <div className="quote-container">
                    <span>
                        <Title level={4} className="quoteheader">
                            Parts Rebate Customers
                        </Title>
                        <Title level={5} className="quote-info">
                            View the details of rebate customers
                        </Title>
                    </span>
                </div>
            )}
            <div className="main-container">
                <div className="box">
                    <div className="row-col-container">
                        {!rebateDetail ? (
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={8}>
                                    <Title className="customer-id summary-dropdown" level={5}>
                                        Report Type
                                    </Title>
                                    <Form.Item>
                                        <Select
                                            showSearch
                                            placeholder="Select Region"
                                            className="custom-select custom-placeholder-text"
                                            // defaultValue={}
                                            value={listFilter.regionId}
                                            onChange={(value) => handleFilterChange('regionId', value)}
                                            onSearch={(value) => handleSearch('region', value)}
                                            onDropdownVisibleChange={(open) =>
                                                handleDropdownVisibleChange('region', open)
                                            }
                                            suffixIcon={
                                                searchState.region.onSearch ? (
                                                    <SearchOutlined className="dropdown-icon" />
                                                ) : (
                                                    <CaretDownOutlined className="dropdown-icon" />
                                                )
                                            }
                                        >
                                            {regionTypes?.map((item) => (
                                                <Select.Option key={item.id} value={item?.id}>
                                                    {item?.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Title className="customer-id summary-dropdown" level={5}>
                                        Dealer Code / Name
                                    </Title>
                                    <Form.Item>
                                        {listFilter.regionId == '0' ? (
                                            <Select
                                                showSearch
                                                className="custom-select custom-placeholder-text"
                                                value={listFilter.dealershipId}
                                                onChange={(value) => {
                                                    handleFilterChange('dealershipId', value);
                                                }}
                                                onSearch={(value) => handleSearch('dealerCode', value)}
                                                onDropdownVisibleChange={(open) =>
                                                    handleDropdownVisibleChange('dealerCode', open)
                                                }
                                                suffixIcon={
                                                    searchState.dealerCode.onSearch ? (
                                                        <SearchOutlined className="dropdown-icon" />
                                                    ) : (
                                                        <CaretDownOutlined className="dropdown-icon" />
                                                    )
                                                }
                                                placeholder="Select Dealer"
                                                filterOption={(input, option) => {
                                                    const code = option.props.code;
                                                    const name = option.props.name;
                                                    return (
                                                        code?.toLowerCase().includes(input?.toLowerCase()) ||
                                                        name?.toLowerCase().includes(input?.toLowerCase())
                                                    );
                                                }}
                                                optionFilterProp="children"
                                                dropdownRender={dropdownRender}
                                            >
                                                {dealershipDetails?.map((item) => (
                                                    <Select.Option
                                                        key={item?.id}
                                                        value={`${item?.id}`}
                                                        code={item?.code}
                                                        name={item?.name}
                                                    >
                                                        <div className="options">
                                                            <p>{item?.code}</p>
                                                            <p className="elipse-width options-description">
                                                                {item?.name}
                                                            </p>
                                                        </div>
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        ) : (
                                            <Input className="input-style" disabled placeholder="Select Dealer" />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Title className="customer-id summary-dropdown" level={5}>
                                        Customer Type
                                    </Title>
                                    <Form.Item>
                                        <Select
                                            className="custom-select custom-placeholder-text"
                                            value={listFilter.customerType}
                                            onChange={(value) => handleFilterChange('customerType', value)}
                                            onDropdownVisibleChange={(open) =>
                                                handleDropdownVisibleChange('customerTypes', open)
                                            }
                                            placeholder="Select Customer Type"
                                        >
                                            {customerType?.map((item) => (
                                                <Select.Option key={item.id} value={item?.id}>
                                                    {item?.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Title className="customer-id summary-dropdown" level={5}>
                                        Customer Status
                                    </Title>
                                    <Form.Item>
                                        <Select
                                            className="custom-select custom-placeholder-text"
                                            value={listFilter.customerStatus}
                                            onChange={(value) => handleFilterChange('customerStatus', value)}
                                            onDropdownVisibleChange={(open) =>
                                                handleDropdownVisibleChange('dealerCode', open)
                                            }
                                            placeholder="Select Customer Status"
                                        >
                                            {approvalStatus.map((item) => (
                                                <Select.Option key={item.id} value={item.id}>
                                                    {item.status}{' '}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Title className="customer-id" level={5}>
                                        Invoice Period
                                    </Title>
                                    <Form.Item className="summary-report">
                                        <RangeDateRangePicker
                                            selectedDates={listFilter.dateRange}
                                            setSelectedDates={handleDateChange}
                                            format="DD/MM/YYYY"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        ) : (
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={8}>
                                    <Title className="customer-id" level={5}>
                                        Customer Name
                                    </Title>
                                    <Form.Item>
                                        <Select
                                            showSearch
                                            className="custom-select custom-placeholder-text"
                                            value={listFilter.customerId}
                                            onChange={(value) => handleFilterChange('customerName', value)}
                                            onSearch={(value) => handleSearch('customerName', value)}
                                            onDropdownVisibleChange={(open) =>
                                                handleDropdownVisibleChange('customerName', open)
                                            }
                                            suffixIcon={
                                                searchState.customerName.onSearch ? (
                                                    <SearchOutlined className="dropdown-icon" />
                                                ) : (
                                                    <CaretDownOutlined className="dropdown-icon" />
                                                )
                                            }
                                            placeholder="Enter Customer Name"
                                        >
                                            {customerFilters?.map((item) => (
                                                <Option key={item.id} value={item?.id}>
                                                    {item?.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Title className="customer-id" level={5}>
                                        DMS ID
                                    </Title>
                                    <Form.Item>
                                        <Select
                                            showSearch
                                            className="custom-select custom-placeholder-text"
                                            value={listFilter.customerId}
                                            onChange={(value) => handleFilterChange('customerId', value)}
                                            onSearch={(value) => handleSearch('dmsId', value)}
                                            onDropdownVisibleChange={(open) =>
                                                handleDropdownVisibleChange('dmsId', open)
                                            }
                                            suffixIcon={
                                                searchState.dmsId.onSearch ? (
                                                    <SearchOutlined className="dropdown-icon" />
                                                ) : (
                                                    <CaretDownOutlined className="dropdown-icon" />
                                                )
                                            }
                                            placeholder="Enter DMS ID"
                                        >
                                            {sortArray(customerFilters, 'id')?.map((item) => (
                                                <Option key={item.id} value={item?.id}>
                                                    {item?.id}{' '}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <Title className="customer-id" level={5}>
                                        Date Range
                                    </Title>
                                    <Form.Item>
                                        <RangeDateRangePicker
                                            //   setSelectedDates={setSelectedDates}
                                            //   handleChange={handleChange}
                                            selectedDates={listFilter.dateRange}
                                            setSelectedDates={handleDateChange}
                                            format="DD/MM/YYYY"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}

                        <div>
                            <div className="quotes-all-list-container">
                                <CustomizedButton className="reset-btn" onClick={handleClearFilter}>
                                    Reset
                                </CustomizedButton>
                                <CustomizedButton
                                    type="primary"
                                    className={isAnyFilterSelected ? 'apply-btn' : 'apply-btn-disabled'}
                                    onClick={handleApplyFilter}
                                >
                                    Apply
                                </CustomizedButton>
                            </div>
                        </div>
                    </div>
                    <div className="hr-container">
                        <hr className="hr-line" />
                    </div>
                    {/* <Col
            className="gutter-row rebate-management-form"
            span={6}
            style={{ marginLeft: "25px", marginTop: "15px" }}
          >
            <Title className="search  lable-width" level={6}>
              Search Record
            </Title>
            <Form.Item
              name="quotedSellPrice"
              rules={
                [
                  // requiredRule,
                  // getMaxCharsRule("quotedSellPrice", 15),
                  // zeroValueRule,
                ]
              }
            >
              <Input
                placeholder="Enter DMS ID, ABN, Customer Name"
                // addonBefore={<span className="dollor-style">$</span>}
                className=""
                autoComplete="off"
                // onBlur={() => {
                //   form.validateFields(["quotedSellPrice"]);
                // }}
                onInput={(e) => handleSearchString(e)}
                // onKeyPress={(e) =>
                //   onHandleNumericValidation(
                //     e,
                //     form,
                //     "quotedSellPrice",
                //     true
                //   )
                // }
                // onChange={(value) =>
                //   handleonChange("quotedSellPrice", value)
                // }
                style={{
                  width: "100%",
                  borderRadius: "0px",
                }}
              />
            </Form.Item>
          </Col> */}
                    <div>
                        <Title className="quote-items all-quote-list" level={4}></Title>
                        <div className="total-container reports-container">
                            <div className="total-text">
                                Total:
                                <span className="item">
                                    {totalRecords > 0 && totalRecords < 10 ? `0${totalRecords}` : totalRecords} item
                                    {totalRecords > 1 ? 's' : ''}
                                </span>
                            </div>
                            {!rebateDetail && (
                                <div className="apply-btn-container">
                                    <CustomizedButton
                                        className="import-file-btn export-button summary-exportbtn "
                                        type="Outlined"
                                        onClick={handleOpenDrawer}
                                    >
                                        Manage Columns
                                    </CustomizedButton>
                                </div>
                            )}
                        </div>
                        <div className="table-container">
                            <CommonTable
                                // scrollable={true}
                                dataSource={rebateRetailList}
                                columns={columns.map((column) => ({
                                    ...column,
                                    // onHeaderCell: () => ({
                                    //   onClick: () => {},
                                    // }),
                                    // sortOrder: columnSortDirections[column.dataIndex],
                                }))}
                            />
                            {/* {30 > 15 && ( */}
                            <div className="quote-pagination">
                                <Pagination
                                    onChange={onPaginationChange}
                                    total={totalRecords}
                                    className="custom-pagination"
                                    current={listFilter.pageNumber}
                                    {...paginationConfig}
                                />
                            </div>
                            {/* )} */}
                        </div>
                        <CommonModal
                            id=":rh:"
                            visible={isStatusModalOpen}
                            onCancel={onCancelStatusModal}
                            onConfirm={handleConfirmUpdate}
                            title="Update Status"
                            showUpdateButton={true}
                        >
                            <p className="export-message">Do want to update status.</p>
                        </CommonModal>
                        <CommonModal
                            id=":rh:"
                            visible={isReasonModalOpen}
                            onCancel={onCancelReasonModal}
                            onConfirm={handleConfirmReasonUpdate}
                            title="Update Reason"
                            showUpdateButton={true}
                        >
                            <p className="export-message">Do want to update Reason.</p>
                        </CommonModal>
                        <CommonModal
                            id=":rh:"
                            visible={exportModalVisible}
                            onCancel={onCancelModal}
                            onConfirm={handleConfirmExport}
                            title="Export All Invoices"
                            showExportButton={exportModalVisible}
                        ></CommonModal>
                        <ManageColumnDrawer
                            isDrawerOpen={openDrawer}
                            onCloseDrawer={handleCloseDrawer}
                            defaultColumns={defaultColumns}
                            additionalColumns={additionalColumns}
                            handleUpdateColumns={handleUpdateColumns}
                            setOpenDrawer={setOpenDrawer}
                        />
                        <RebateManagementEditt
                            isModalVisible={isModalVisible}
                            updateValues={updateValues}
                            handleModalCancel={handleModalCancel}
                            setIsModalVisible={setIsModalVisible}
                            modalCustomerId={modalCustomerId}
                            editingRecord={editingRecord}
                            dmsId={dmsId}
                            rebateDetail
                        />
                        <RebateManagementView
                            isViewModalVisible={isViewModalVisible}
                            setIsViewModalVisible={setIsViewModalVisible}
                            // dmsId={dmsId}
                            modalCustomerId={modalCustomerId}
                            handleViewModalCancel={handleViewModalCancel}
                        />
                    </div>
                    {!rebateDetail ? <RegionComponent /> : <></>}
                </div>
            </div>
            {!rebateDetail ? (
                <>
                    <GetCustomerApprovalStatus />
                    <GetCustomerUnapprovalReason />
                    <GetCustomerTypes />
                </>
            ) : (
                <></>
            )}
        </div>
    );
};
export default RebateManagementCustomer;
