const usePartsRebateDetailTableColumns = (rebateDetail) => {
    const columns = [
        {
            title: 'Invoice Date',
            key: 'invoiceDate',
            isDefault: true,
            isSelected: true,
        },
        {
            title: 'Invoice Number',
            key: 'invoiceNumber',
            isDefault: true,
            isSelected: true,
        },
        ...(!rebateDetail
            ? [
                  {
                      title: 'Dealer Code',
                      key: 'dealerCode',
                      isDefault: true,
                      isSelected: true,
                  },
                  {
                      title: 'Dealership Name',
                      key: 'dealershipName',
                      isDefault: true,
                      isSelected: true,
                  },
              ]
            : []),

        ...(!rebateDetail
            ? [
                  {
                      title: 'Part Number',
                      key: 'partNumber',
                      isDefault: false,
                      isSelected: true,
                  },
                  {
                      title: 'Part Description',
                      key: 'partDescription',
                      isDefault: false,
                      isSelected: true,
                  },
              ]
            : [
                  {
                      title: 'Part Number',
                      key: 'partNumber',
                      isDefault: true,
                      isSelected: true,
                  },
                  {
                      title: 'Part Description',
                      key: 'partDescription',
                      isDefault: true,
                      isSelected: true,
                  },
              ]),

        ...(!rebateDetail
            ? [
                  {
                      title: 'Customer Type',
                      key: 'customerType',
                      isDefault: false,
                      isSelected: true,
                  },
              ]
            : [
                  {
                      title: 'Customer DMS ID',
                      key: 'customerId',
                      isDefault: false,
                      isSelected: true,
                  },
                  {
                      title: 'Customer Name',
                      key: 'customerName',
                      isDefault: false,
                      isSelected: true,
                  },
              ]),

        {
            title: 'Quantity Sold',
            key: 'quantity',
            isDefault: false,
            isSelected: true,
        },
        {
            title: 'Quoted Price',
            key: 'quotedPrice',
            isDefault: false,
            isSelected: true,
        },
        ...(!rebateDetail
            ? [
                  {
                      // title: "Dealer Cost($)",?
                      title: 'Stock Order Price($)',
                      key: 'dealerCost',
                      isDefault: false,
                      isSelected: true,
                  },
              ]
            : [
                  {
                      title: 'Stock Order Price($)',
                      key: 'dealerCost',
                      isDefault: false,
                      isSelected: true,
                  },
              ]),

        {
            title: 'Gross Profit($)',
            key: 'grossProfit',
            isDefault: false,
            isSelected: true,
        },
        {
            title: 'Gross Profit(%)',
            key: 'grossProfitPercentage',
            isDefault: false,
            isSelected: true,
        },
        {
            title: 'Rebate Per Item',
            key: 'rebate',
            isDefault: false,
            isSelected: true,
        },
        {
            title: 'Effective Date',
            key: 'effectiveDate',
            isDefault: false,
            isSelected: true,
        },
    ];

    let defaultColumns = [];
    let additionalColumns = [];

    columns.forEach((column) => {
        if (column.isDefault) {
            defaultColumns.push(column);
        } else {
            additionalColumns.push(column);
        }
    });

    let selectedTableColumns = [];

    columns.forEach((column) => {
        if (column.isSelected && !column.isDefault) {
            selectedTableColumns.push(column.key);
        }
    });

    return [defaultColumns, additionalColumns, selectedTableColumns];
};

export default usePartsRebateDetailTableColumns;
