import React, { useEffect, useState, useRef, useContext } from 'react';
import { Typography, Select, Form, Col, Row, Input, Pagination, Button, message } from 'antd';
import { DateRangePicker } from '../../../Components/Datepicker';
import { CaretDownOutlined, SearchOutlined } from '@ant-design/icons';
import '../../../Assets/Styles/global-styles.css';
import '../../../Assets/Styles/fonts.css';
import { useFormData } from '../../../Constants/commonFormdata';
import { AllowanceRegionComponent } from '../../../Constants/commonRegion';
import { QuoteContext } from '../../Quote-Calculator/quoteContext';
import { webService } from '../../../Services/web.service';
import CommonTable from '../../../Components/Table';
import { paginationConfig } from '../../../Components/Pagination';
import CustomizedButton from '../../../Components/Button';
import { reportPartsQuoted, reportPartsQuotedExport, rebateCustomerDownload } from '../../../Services/userEndpoints';
import store from '../../../store/store';
import { isExecuting } from '../../../store/isHTTPRequestSlice';
import CommonModal from '../../../Components/Modal';
import { saveAs } from 'file-saver';

const { Title } = Typography;
const QuoteReport = () => {
    const { allowanceRegion, dealershipDetails } = useContext(QuoteContext);
    const {
        formData,
        handleonChange,
        handleSearch,
        setIsAnyDropdownSelected,
        setFormData,
        handleDropdownVisibleChange,
        setSelectedDates,
        selectedDates,
        handleChange,
    } = useFormData();
    const [pageSize, setPageSize] = useState(paginationConfig?.defaultPageSize);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [editingRowId, setEditingRowId] = useState(null);
    const [rebateRetailList, setRebateRetailList] = useState([]);
    const [exportModalVisible, setExportModalVisible] = useState(false);

    const [dealerPaymentList, setDealerPaymentList] = useState([]);

    const reportType = allowanceRegion.find((report) => report?.name === formData.region) || {};
    const onShowSizeChange = (current, size) => {
        setCurrentPage(current);
        setPageSize(size);
    };

    const onChange = (page, size) => {
        setEditingRowId(null);
        setCurrentPage(page);
        setPageSize(size);
    };

    const handleFilterNewReport = async () => {
        currentPage > 1 ? setCurrentPage(1) : filterRebateAllowance();
    };

    const handleConfirmExport = async () => {
        try {
            webService.post(`${reportPartsQuotedExport}`).then((response) => {
                const { fileName, buffer } = response.data;
                const arrayBuffer = new Uint8Array(buffer.data).buffer;

                const blob = new Blob([arrayBuffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                saveAs(blob, fileName);
                message.success(`All invoices exported.`);
                setExportModalVisible(false);
            });
        } catch (error) {
            console.error('Error Download Excel:', error);
        }
    };

    const fetchRebateAllowance = async (payload) => {
        try {
            store.dispatch(isExecuting(true));
            webService
                .post(reportPartsQuoted, payload)
                .then((response) => {
                    const data = response.data || {};
                    const rebateRetail = data.rows || [];
                    const totalAllRecords = data.total || 0;
                    setRebateRetailList(rebateRetail);
                    setTotalRecords(totalAllRecords);
                })
                .catch(() => {
                    setRebateRetailList([]);
                    setTotalRecords(0);
                })
                .finally(() => {
                    store.dispatch(isExecuting(false));
                });
        } catch (error) {
            console.error('Error fetching rebate allowance data:', error);
        }
    };

    const getRebateAllowance = async () => {
        const payload = generatePayload(selectedDates, true);
        await fetchRebateAllowance(payload);
    };
    //  Filter Api

    const filterRebateAllowance = async () => {
        const payload = generatePayload(selectedDates, true);
        await fetchRebateAllowance(payload);
    };

    const generatePayload = (selectedDates, includePageData = false) => {
        const { region, dealershipId } = formData;
        const payload = {};
        const reportTypesId = allowanceRegion.find((report) => report.name === region);

        if (region) payload.regionId = reportTypesId?.id;
        if (dealershipId) payload.dealershipId = dealershipId;
        if (selectedDates && selectedDates[0]) {
            payload.monthYearStart = selectedDates[0].format('MM/YYYY');
        }
        if (selectedDates && selectedDates[1]) {
            payload.monthYearEnd = selectedDates[1].format('MM/YYYY');
        }
        if (includePageData) {
            payload.pageNumber = currentPage;
            payload.pageSize = pageSize;
        }
        return payload;
    };

    //
    const handleGetImportedRecords = (data) => {
        const payload = convertToPayload(data);
        // importRebateAllowance(payload);
        setTimeout(() => {
            getRebateAllowance();
        }, 1000);
    };
    const convertToPayload = (data) => {
        return {
            items: data
                .filter((item) => {
                    // Check if all required keys have  non-empty, non-null, non-undefined value
                    return (
                        item.partNumber !== undefined &&
                        item.partNumber !== null &&
                        item.partNumber !== '' &&
                        item.description !== undefined &&
                        item.description !== null &&
                        item.description !== '' &&
                        item.quoteCount !== undefined &&
                        item.quoteCount !== null &&
                        item.quoteCount !== '' &&
                        item.quantityQuoted !== undefined &&
                        item.quantityQuoted !== null &&
                        item.quantityQuoted !== '' &&
                        item.quantitySold !== undefined &&
                        item.quantitySold !== null &&
                        item.quantitySold !== ''
                    );
                })
                .map((item) => {
                    return {
                        partNumber: item.partNumber,
                        description: item.description,
                        quoteCount: item.quoteCount,
                        quantityQuoted: item.quantityQuoted,
                        quantitySold: item.quantitySold,
                    };
                }),
        };
    };

    const handleExportClick = () => {
        setExportModalVisible(true); // Open the modal when the button is clicked
    };

    const onCancelModal = () => {
        setExportModalVisible(false);
    };

    useEffect(() => {
        setDealerPaymentList(reportPartsQuotedExport?.records);
        setTotalRecords(reportPartsQuotedExport?.count ?? 0);
    }, [reportPartsQuotedExport]);

    const handleReset = () => {
        // Reset form data to initial state
        setFormData({
            region: '', // Reset Report Type
            dealershipId: '', // Reset Dealer Code
        });

        // Reset selected dates
        setSelectedDates([null, null]);
    };
    const columns = [
        {
            title: 'Part Number',
            dataIndex: 'partNumber',
            key: 'partNumber',
        },
        {
            title: 'Part Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Quote Count',
            dataIndex: 'quoteCount',
            key: 'quoteCount',
        },
        {
            title: 'Qty Quoted',
            dataIndex: 'quantityQuoted',
            key: 'quantityQuoted',
        },
        {
            title: 'Qty Sold',
            dataIndex: 'quantitySold',
            key: 'quantitySold',
        },
    ];

    return (
        <div>
            <div className="main-container">
                <div className="box">
                    <div className="row-col-container">
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col span={8}>
                                <Title level={5}>Report Type</Title>
                                <Form.Item>
                                    <Select
                                        value={formData.region}
                                        onChange={(value) => handleonChange('region', value)}
                                        onSearch={(value) => handleSearch('region', value)}
                                        onDropdownVisibleChange={(open) =>
                                            handleDropdownVisibleChange('regionId', open)
                                        }
                                        className="custom-select custom-placeholder-text"
                                        placeholder="Select Report Type"
                                        suffixIcon={<CaretDownOutlined className="dropdown-icon" />}
                                    >
                                        {allowanceRegion?.map((item) => (
                                            <Select.Option key={item.id} value={item?.name}>
                                                {item?.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Title level={5}>Dealer Code / Name</Title>
                                <Form.Item>
                                    {reportType?.name === 'Dealer Specific Report' ? (
                                        <Select
                                            value={formData.dealershipId}
                                            onChange={(value) => handleonChange('dealershipId', value)}
                                            onSearch={(value) => handleSearch('dealerCode', value)}
                                            placeholder="Select Dealer"
                                            className="custom-select custom-placeholder-text"
                                            suffixIcon={<CaretDownOutlined className="dropdown-icon" />}
                                        >
                                            {dealershipDetails?.map((item) => (
                                                <Select.Option key={item?.id} value={`${item?.id}`}>
                                                    {item?.code} - {item?.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    ) : (
                                        <Input disabled placeholder="Select Dealer" />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Title level={5}>Quote Period</Title>
                                <Form.Item>
                                    <DateRangePicker
                                        selectedDates={selectedDates}
                                        setSelectedDates={setSelectedDates}
                                        handleChange={handleChange}
                                        format="MM/YYYY"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className="hr-container">
                            <div className="quotes-all-list-container">
                                <Button className="reset-btn" style={{ marginRight: '12px' }} onClick={handleReset}>
                                    Reset
                                </Button>
                                <Button
                                    type="primary"
                                    className="apply-btn"
                                    // onClick={handleFilterApply}
                                    // onClick={handleFilterNewReport}
                                    onClick={handleFilterNewReport}
                                >
                                    Apply
                                </Button>
                            </div>
                            <hr className="hr-line" />
                        </div>
                        <div className="table-container">
                            <CommonTable
                                columns={columns}
                                dataSource={rebateRetailList.map((item) => ({
                                    ...item,
                                    key: item.id,
                                    partNumber: item.partNumber ? item.partNumber : '-',
                                    description: item?.description ? item.description : '-',
                                    quoteCount: item?.quoteCount ? item.quoteCount : '-',
                                    quantityQuoted: item?.quantityQuoted ? item.quantityQuoted : '-',
                                    quantitySold: item?.quantitySold ? item.quantitySold : '-',
                                }))} // You should pass actual data here
                                scrollable={false}
                            />
                        </div>
                        <div className="quotes-all-list-container">
                            <div className="quotes-all-list-container">
                                <Button
                                    type="primary"
                                    className="apply-btn"
                                    style={{
                                        marginRight: '12px',
                                        backgroundColor: rebateRetailList.length === 0 ? '#d9d9d9' : '',
                                        borderColor: rebateRetailList.length === 0 ? '#d9d9d9' : '',
                                        color: rebateRetailList.length === 0 ? '#a6a6a6' : '',
                                        cursor: rebateRetailList.length === 0 ? 'not-allowed' : '',
                                    }}
                                    onClick={handleExportClick}
                                    disabled={rebateRetailList.length === 0}
                                >
                                    Export
                                </Button>
                            </div>

                            <CommonModal
                                id=":rh:"
                                visible={exportModalVisible}
                                onCancel={onCancelModal}
                                onConfirm={handleConfirmExport}
                                showExportButton={exportModalVisible}
                            >
                                <p className="export-message info-messages ">
                                    Export Part Rebate Summary in XLSX format
                                </p>
                            </CommonModal>
                        </div>

                        <div className="quote-pagination">
                            <Pagination
                                onShowSizeChange={onShowSizeChange}
                                onChange={onChange}
                                total={totalRecords}
                                className="custom-pagination"
                                current={currentPage}
                                {...paginationConfig}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <AllowanceRegionComponent />
        </div>
    );
};
export default QuoteReport;
