import React, { useEffect, useState, useContext } from 'react';
import '../../../Assets/Styles/global-styles.css';
import CommonTable from '../../../Components/Table';
import { CaretDownOutlined, SearchOutlined } from '@ant-design/icons';
import infoCircle from '../../../Assets/Icons/InfoCircle.svg';
import CustomizedButton from '../../../Components/Button';
import { Typography, Select, Form, Col, Row, Pagination, Input } from 'antd';
import '../../../Assets/Styles/fonts.css';
import Export from '../../../Assets/Images/Download.svg';
import ExportIcon from '../../../Assets/Icons/ExportIcon.svg';
import CommonModal from '../../../Components/Modal';
import { paginationConfig } from '../../../Components/Pagination';
import { QuoteContext } from '../../Quote-Calculator/quoteContext';
import { RegionComponent } from '../../../Constants/commonRegion';
import { showInvoiceCredits } from '../../../Constants/commonObject';
import { useFormData } from '../../../Constants/commonFormdata';
import { cleanObject } from '../../../Constants/constants';
import ManageColumnDrawer from '../ManageColumnDrawer/ManageColumnDrawer';
import moment from 'moment/moment';
import usePartsRebateSummaryTableColumns from '../../../shared/hooks/usePartsRebateSummaryTableColumns';
import useGetPartsRebateSummaryTableColumns from '../../../shared/hooks/columns/useGetPartsRebateSummaryTableColumns';
import useGetPartsRebateSummary from '../../../shared/hooks/data/useGetPartsRebateSummary';
import { MonthRangePicker } from '../../../Components/Datepicker';
import { message } from '../../../Components/Message';
import { webService } from '../../../Services/web.service';
import { reportPartsSummaryExport } from '../../../Services/userEndpoints';
import { saveAs } from 'file-saver';
import useGetUser from '../../../shared/hooks/useGetUser';
import { useSelector } from 'react-redux';

const { Title } = Typography;
const { Option } = Select;

const PartsRebateSummary = ({ rebateDetail }) => {
    const { dealer } = useSelector((state) => state.user);
    const [defaultColumns, additionalColumns, selectedTableColumns] = usePartsRebateSummaryTableColumns(rebateDetail);

    const [listFilter, setListFilter] = useState({
        loadRecords: false,
        pageSize: paginationConfig?.defaultPageSize,
        pageNumber: 1,
        additionalColumns: selectedTableColumns,
        ...(!rebateDetail ? { regionId: 0 } : {}),
    });

    const { rebateSummary, setListFilters } = useGetPartsRebateSummary(listFilter);

    const [isAnyFilterSelected, setIsAnyFilterSelected] = useState(false);
    const [exportModalVisible, setExportModalVisible] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([...defaultColumns]);
    const [showColumns, setShowColumns] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [rebateRetailList, setRebateRetailList] = useState([]);

    const [isFiltered, setIsFiltered] = useState(false);

    const { regionTypes, setRebateGrandTotal, dealershipDetails, customerFilters, setSummaryGrandTotal } =
        useContext(QuoteContext);
    const {
        formData,
        isAnyDropdownSelected,
        searchState,
        handleonChange,
        handleSearch,
        handleDropdownVisibleChange,
        selectedDates,
    } = useFormData();

    const { columns, setSelectedTableColumns, sortedKey, setSortedKey } = useGetPartsRebateSummaryTableColumns(
        selectedTableColumns,
        rebateDetail
    );

    const handleClickExport = () => {
        if (rebateRetailList.length > 0) {
            setExportModalVisible(true);
        }
    };

    const onCancelModal = () => {
        setExportModalVisible(false);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        let value = sortedKey;

        if (value) {
            if (value.columnKey === sorter.columnKey) {
                value.order = value.order == 'asc' ? 'desc' : 'asc';
            } else {
                value.columnKey = sorter.columnKey;
                value.order = 'asc';
            }
        } else {
            value = {
                columnKey: sorter.columnKey,
                order: 'asc',
            };
        }

        setSortedKey({ ...value });
    };

    const handleApplyFilter = () => {
        const values = {
            ...listFilter,
            loadRecords: true,
        };
        setListFilters(values);
        setListFilter(values);
    };

    // Export Rebate Detail Api
    const handleConfirmExport = async () => {
        try {
            webService.post(reportPartsSummaryExport, { ...listFilter }).then((response) => {
                const { fileName, buffer } = response.data;
                const arrayBuffer = new Uint8Array(buffer.data).buffer;
                const blob = new Blob([arrayBuffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                saveAs(blob, fileName);
                message.success(`Part Rebate Summary Report is exported.`);
                setExportModalVisible(false);
            });
        } catch (error) {
            console.error('Error Download Excel:', error);
        }
    };

    // Update Columns
    const handleUpdateColumns = (selectedColumns = []) => {
        const values = {
            ...listFilter,
            additionalColumns: selectedColumns,
        };
        setListFilter(values);
        setListFilters(values);
        setSelectedTableColumns(selectedColumns);
        if (isAnyFilterSelected) {
            const values = {
                ...listFilter,
                additionalColumns: selectedColumns,
                loadRecords: true,
            };
            setListFilter(values);
            setListFilters(values);
        }
    };

    const handleOpenDrawer = () => {
        setOpenDrawer(true);
    };

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    };

    useEffect(() => {
        const anyShowHideChecked = additionalColumns.some((col) => selectedColumns.includes(col));
        setShowColumns(anyShowHideChecked);
    }, [selectedColumns]);

    useEffect(() => {
        const values = {
            ...listFilter,
            loadRecords: true,
        };

        if (sortedKey && sortedKey.columnKey) {
            values['orderBy'] = `${sortedKey.columnKey}:${sortedKey.order}`;
            setListFilters(values);
            setListFilter(values);
        }
    }, [sortedKey]);

    const onPaginationChange = (page, size) => {
        const values = {
            ...listFilter,
            pageSize: size,
            pageNumber: page,
        };
        setListFilter(values);
        setListFilters(values);
    };

    const handleClearFilter = () => {
        const values = {
            ...listFilter,
            ...(!rebateDetail ? { regionId: 0 } : {}),
            dealershipId: undefined,
            invoicesOrCredits: undefined,
            partNumber: undefined,
            partDescription: undefined,
            dateRange: [null, null],
            dateStart: undefined,
            dateEnd: undefined,
            monthYearStart: null,
            monthYearEnd: null,
            loadRecords: false,
        };

        setListFilter(values);
        setListFilters(values);
    };

    const reportType = regionTypes.find((report) => report?.name === formData.region);

    const dropdownRender = (menu) => (
        <div>
            <div className=" dropdown-container">
                <p className="dropdown-partnumber-heading">Code</p>
                <p className="dropdown-description-heading">Dealership Name</p>
            </div>
            {menu}
        </div>
    );

    const isAnyFilterSelectedFun = () => {
        setIsAnyFilterSelected(
            listFilter.regionId > 0 ||
                listFilter.regionId ||
                listFilter.dealershipId ||
                listFilter.dateRange ||
                listFilter.invoicesOrCredits ||
                listFilter.monthYearStart ||
                listFilter.monthYearEnd
        );
    };

    const handleFilterChange = (name, value) => {
        let values = {};

        if (name === 'regionId') {
            if (value !== 0)
                values = {
                    ...listFilter,
                    regionId: value,
                    dealershipId: undefined,
                };
            else {
                values = {
                    ...listFilter,
                    regionId: value,
                };
            }
            setListFilter(values);
        } else if (name === 'partNumber') {
            const partNumber = value.split('-')[1];
            const partDescription = value.split('-')[2];

            values = {
                ...listFilter,
                partNumber: partNumber,
                partDescription: partDescription,
            };
        } else {
            values = {
                ...listFilter,
                [name]: value,
            };
        }

        setListFilter(values);
    };

    const handleDateChange = (value) => {
        const values = {
            ...listFilter,
            monthYearStart: moment(new Date(value[0])).format('MM/YYYY'),
            monthYearEnd: moment(new Date(value[1])).format('MM/YYYY'),
        };

        setListFilter(values);
    };

    useEffect(() => {
        setIsFiltered(
            (() => {
                return Object.keys(cleanObject(listFilter)).length > 0;
            })()
        );
        if (listFilter.dateRange && listFilter.dateRange[0] === null && listFilter.dateRange[1] === null) {
            setListFilter({
                ...listFilter,
                dateRange: undefined,
            });
        }
        isAnyFilterSelectedFun();
    }, [listFilter]);

    useEffect(() => {
        const data = rebateSummary?.data || {};
        const rebateRetail = data.rows || [];
        const totalAllRecords = data.total || 0;

        const grandTotal = [];
        grandTotal['totalSales'] = data?.totalSales;
        grandTotal['totalRebateSales'] = data?.totalRebateSales;
        setSummaryGrandTotal(grandTotal);
        setRebateRetailList(rebateRetail);
        setRebateGrandTotal(grandTotal);
        setTotalRecords(totalAllRecords);
    }, [rebateSummary]);

    return (
        <div>
            {rebateDetail && (
                <div className="quote-container">
                    <span>
                        <Title level={4} className="quoteheader">
                            Parts Rebate Summary
                        </Title>
                        <Title level={5} className="quote-info">
                            View the reports of parts rebate summary
                        </Title>
                    </span>
                </div>
            )}
            <div className="main-container">
                <div className="box">
                    <div className="row-col-container">
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            {!rebateDetail && (
                                <>
                                    <Col className="gutter-row" span={8}>
                                        <Title className="customer-id summary-dropdown" level={5}>
                                            Report Type
                                        </Title>
                                        <Form.Item>
                                            <Select
                                                showSearch
                                                className="custom-select custom-placeholder-text"
                                                value={listFilter.regionId}
                                                onChange={(value) => handleFilterChange('regionId', value)}
                                                onSearch={(value) => handleSearch('region', value)}
                                                onDropdownVisibleChange={(open) =>
                                                    handleDropdownVisibleChange('region', open)
                                                }
                                                suffixIcon={
                                                    searchState.region.onSearch ? (
                                                        <SearchOutlined className="dropdown-icon" />
                                                    ) : (
                                                        <CaretDownOutlined className="dropdown-icon" />
                                                    )
                                                }
                                                placeholder="Select Report Type"
                                            >
                                                {regionTypes?.map((item) => (
                                                    <Select.Option key={item.id} value={item?.id}>
                                                        {item?.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <Title className="customer-id summary-dropdown" level={5}>
                                            Dealer Code / Name
                                        </Title>
                                        <Form.Item>
                                            {listFilter.regionId == '0' ? (
                                                <Select
                                                    showSearch
                                                    className="custom-select custom-placeholder-text"
                                                    value={listFilter.dealershipId}
                                                    onChange={(value) => {
                                                        handleFilterChange('dealershipId', value);
                                                    }}
                                                    onSearch={(value) => handleSearch('dealerCode', value)}
                                                    onDropdownVisibleChange={(open) =>
                                                        handleDropdownVisibleChange('dealerCode', open)
                                                    }
                                                    suffixIcon={
                                                        searchState.dealerCode.onSearch ? (
                                                            <SearchOutlined className="dropdown-icon" />
                                                        ) : (
                                                            <CaretDownOutlined className="dropdown-icon" />
                                                        )
                                                    }
                                                    placeholder="Select Dealer"
                                                    filterOption={(input, option) => {
                                                        const code = option.props.code;
                                                        const name = option.props.name;
                                                        return (
                                                            code?.toLowerCase().includes(input?.toLowerCase()) ||
                                                            name?.toLowerCase().includes(input?.toLowerCase())
                                                        );
                                                    }}
                                                    optionFilterProp="children"
                                                    dropdownRender={dropdownRender}
                                                >
                                                    {dealershipDetails?.map((item) => (
                                                        <Select.Option
                                                            key={item?.id}
                                                            value={`${item?.id}`}
                                                            code={item?.code}
                                                            name={item?.name}
                                                        >
                                                            <div className="options">
                                                                <p>{item?.code}</p>
                                                                <p className="elipse-width options-description">
                                                                    {item?.name}
                                                                </p>
                                                            </div>
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            ) : (
                                                <Input className="input-style" disabled placeholder="Select Dealer" />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </>
                            )}

                            {rebateDetail && (
                                <>
                                    {' '}
                                    <Col className="gutter-row" span={8}>
                                        <Title className="dealer-code" level={5}>
                                            Dealer Code
                                        </Title>
                                        <Form.Item>
                                            <Input
                                                className="input-style"
                                                disabled
                                                placeholder="Dealer Code"
                                                value={dealer?.dlCode}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <Title className="customer-id summary-dropdown" level={5}>
                                            Show (Invoices and/or credits)
                                        </Title>
                                        <Form.Item>
                                            <Select
                                                showSearch
                                                className="custom-select custom-placeholder-text text-capitalize"
                                                value={listFilter.invoicesOrCredits}
                                                onChange={(value) => handleFilterChange('invoicesOrCredits', value)}
                                                suffixIcon={<CaretDownOutlined className="dropdown-icon" />}
                                                placeholder="Select Invoices or credits"
                                            >
                                                {showInvoiceCredits?.map((item) => (
                                                    <Select.Option
                                                        className="text-capitalize"
                                                        key={item.id}
                                                        value={item?.name}
                                                    >
                                                        {item?.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                            <Col className="gutter-row" span={8}>
                                <Title className="customer-id" level={5}>
                                    Invoice Period
                                </Title>
                                <Form.Item className="summary-report">
                                    <MonthRangePicker
                                        selectedDates={listFilter.dateRange}
                                        setSelectedDates={handleDateChange}
                                        format="MM/YYYY"
                                    />
                                </Form.Item>
                            </Col>
                            {rebateDetail && (
                                <>
                                    <Col className="gutter-row" span={6}></Col>{' '}
                                    <Col className="gutter-row" span={6}></Col>
                                </>
                            )}
                        </Row>
                        <div>
                            <div className="quotes-all-list-container">
                                <CustomizedButton className="reset-btn" onClick={handleClearFilter}>
                                    Reset
                                </CustomizedButton>
                                <CustomizedButton
                                    type="primary"
                                    className={isAnyFilterSelected ? 'apply-btn' : 'apply-btn-disabled'}
                                    onClick={handleApplyFilter}
                                >
                                    Apply
                                </CustomizedButton>
                            </div>
                        </div>
                    </div>
                    <div className="hr-container">
                        <hr className="hr-line" />
                    </div>
                    <div>
                        <Title className="quote-items all-quote-list" level={4}></Title>
                        <div className="total-container reports-container">
                            <div className="total-text">
                                Total:
                                <span className="item">
                                    {totalRecords > 0 && totalRecords < 10 ? `0${totalRecords}` : totalRecords} item
                                    {totalRecords > 1 ? 's' : ''}
                                </span>
                            </div>
                            <div className="apply-btn-container">
                                <CustomizedButton
                                    className="import-file-btn export-button summary-exportbtn btn-right "
                                    type="Outlined"
                                    onClick={handleOpenDrawer}
                                >
                                    Manage Columns
                                </CustomizedButton>
                                <CustomizedButton
                                    className={'import-file-btn export-button summary-exportbtn'}
                                    type="Outlined"
                                    icon={ExportIcon}
                                    onClick={handleClickExport}
                                >
                                    Export Report
                                </CustomizedButton>
                            </div>
                        </div>
                        <div className="table-container">
                            <CommonTable
                                dataSource={rebateRetailList}
                                onChange={handleTableChange}
                                columns={columns.map((column) => ({
                                    ...column,
                                    // onHeaderCell: () => ({
                                    //   onClick: () => handleColumnSort(column),
                                    // }),
                                    // sortOrder: columnSortDirections[column.dataIndex],
                                }))}
                            />
                            {/* {30 > 15 && ( */}
                            <div className="quote-pagination">
                                <Pagination
                                    onChange={onPaginationChange}
                                    total={totalRecords}
                                    className="custom-pagination"
                                    current={listFilter.pageNumber}
                                    {...paginationConfig}
                                />
                            </div>
                            {/* )} */}
                        </div>
                        <CommonModal
                            id=":rh:"
                            visible={exportModalVisible}
                            onCancel={onCancelModal}
                            onConfirm={handleConfirmExport}
                            title={
                                <>
                                    <img className="infocircle" src={infoCircle} /> Export Report
                                </>
                            }
                            showExportButton={exportModalVisible}
                        >
                            <p className="export-message info-messages ">Export Part Rebate Summary in XLSX format</p>
                        </CommonModal>
                        <ManageColumnDrawer
                            setOpenDrawer={setOpenDrawer}
                            isDrawerOpen={openDrawer}
                            onCloseDrawer={handleCloseDrawer}
                            defaultColumns={defaultColumns}
                            additionalColumns={additionalColumns}
                            handleUpdateColumns={handleUpdateColumns}
                        />
                    </div>
                    <RegionComponent />
                </div>
            </div>
        </div>
    );
};
export default PartsRebateSummary;
