export const customerUnApprovalStatusList = [
    {
        id: 1,
        reason: 'By default',
    },
    {
        id: 2,
        reason: 'No reason yet',
    },
    {
        id: 3,
        reason: 'No ABN provided',
    },
    {
        id: 4,
        reason: 'Not an approved trade customer',
    },
    {
        id: 5,
        reason: 'Details are incorrect',
    },
    {
        id: 6,
        reason: 'Other reasons',
    },
];

export const customerStatusList = [
    {
        id: '0',
        status: 'Pending',
    },
    {
        id: '1',
        status: 'Approved',
    },
    {
        id: '2',
        status: 'Rejected',
    },
];

export const customerTypes = {
    TRADE: 'Trade',
    RETAIL: 'Retail',
    DEALER: 'Dealer',
};

export const customerApprovalStatus = {
    PENDING: '0',
    APPROVED: '1',
    UNAPPROVED: '2',
};
