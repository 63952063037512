import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { encrypt, decrypt } from '../../Constants/utils';

export const useIsAdmin = () => {
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const role = Cookies.get('role');
        if (role) {
            setIsAdmin(decrypt(role) === '1');
        }
    }, []);

    return isAdmin;
};

export const useIsTradeDealer = () => {
    const [isTradeDealer, setIsTradeDealer] = useState(false);

    useEffect(() => {
        const role = Cookies.get('role');
        if (role) {
            setIsTradeDealer(decrypt(role) === '2');
        }
    }, []);

    return isTradeDealer;
};

export const useIsRetailDealer = () => {
    const [isRetailDealer, setIsRetailDealer] = useState(false);

    useEffect(() => {
        const role = Cookies.get('role');
        if (role) {
            setIsRetailDealer(decrypt(role) === '3');
        }
    }, []);

    return isRetailDealer;
};
