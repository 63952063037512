export const commonSorter = (dataIndex) => (a, b) => {
    const valueA = a[dataIndex];
    const valueB = b[dataIndex];

    const numA = parseFloat(valueA);
    const numB = parseFloat(valueB);

    const dateA = new Date(valueA);
    const dateB = new Date(valueB);

    const isValidDateA = !isNaN(dateA.getTime());
    const isValidDateB = !isNaN(dateB.getTime());

    // If both are valid dates, compare them
    if (isValidDateA && isValidDateB) {
        return dateA.getTime() - dateB.getTime();
    }

    const isNumA = !isNaN(numA);
    const isNumB = !isNaN(numB);

    if (isNumA && isNumB) {
        return numA - numB;
    }

    if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueA.localeCompare(valueB);
    }
    return 0;
};

export const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return '';
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

export const getObjectKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
};

export const render = (text) => text || '-';
export const renderPercentage = (text) => (text ? `${text}%` : '-');
export const renderDollar = (text) => (text ? `$${text}` : '-');

export const cleanObject = (obj) => {
    return Object.fromEntries(
        Object.entries(obj).filter(([_, value]) => value !== undefined && value !== null && value !== '')
    );
};

export const isValidPrice = (value) => {
    if (!value) return false;
    const priceStr = value.toString().trim();
    const priceRegex = /^(0|[1-9]\d*)(\.\d{1,2})?$/;
    return priceRegex.test(priceStr);
};

export const isValidQuantity = (value) => {
    if (!value) return false;
    const quantityStr = value.toString().trim();
    const quantityRegex = /^[0-9]+$/;
    return quantityRegex.test(quantityStr);
};

export const isValidPartNumber = (value) => {
    if (!value) return false;
    const partNumberRegex = /^[0-9A-Za-z]+$/;
    return partNumberRegex.test(value.trim());
};

export const isValidYear = (value) => {
    if (!value) return false;
    const regex = /^(1[0-9]{3}|20[0-9]{2})$/;
    return regex.test(value);
};

export const isValidMonth = (value) => {
    if (!value) return false;
    const regex = /^(0?[1-9]|1[0-2])$/;
    return regex.test(value);
};
