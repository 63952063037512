import { useContext, useEffect, useState } from 'react';
import { webService } from '../../../Services/web.service';
import store from '../../../store/store';
import { isExecuting } from '../../../store/isHTTPRequestSlice';
import { QuoteContext } from '../../../Pages/Quote-Calculator/quoteContext';

const useGetPartsRebateSummary = (initialFilters = {}) => {
    const [rebateSummary, setRebateSummary] = useState([]);
    const [filters, setFilters] = useState(initialFilters);
    const { setSummaryGrandTotal } = useContext(QuoteContext);

    const fetch = () => {
        store.dispatch(isExecuting(true));
        webService
            .post('reporting/parts/rebate-summary', filters)
            .then((response) => {
                setRebateSummary(response);
                const grandTotal = [];
                grandTotal['totalSales'] = response?.data?.totalSales;
                grandTotal['totalRebateSales'] = response?.data?.totalRebateSales;
                setSummaryGrandTotal(grandTotal);
            })
            .finally(() => {
                store.dispatch(isExecuting(false));
            });
    };

    const setListFilters = (data) => {
        // if (data.dates) {
        //   data.monthYearStart = moment(new Date(data.dates[0])).format("MM/YYYY");
        //   data.monthYearEnd = moment(new Date(data.dates[1])).format("MM/YYYY");
        // } else {
        //   data.monthYearStart = data.monthYearEnd = undefined;
        // }

        setFilters(data);
    };

    useEffect(() => {
        setRebateSummary(null);
        if (filters.loadRecords) {
            fetch();
        }
    }, [filters]);

    return { rebateSummary, setListFilters };
};

export default useGetPartsRebateSummary;
