import React, { useEffect } from 'react';
import QuoteGenerator from './quoteGenerator';

const ViewQuote = ({ setSelectedKey }) => {
    return (
        <>
            <QuoteGenerator view={true} />
        </>
    );
};
export default ViewQuote;
