import { createSlice } from '@reduxjs/toolkit';

export const isHTTPRequestSlice = createSlice({
    name: 'isHTTPRequest',
    initialState: false,
    reducers: {
        isExecuting: (state, action) => {
            // console.log("Action", action.payload)
            // state = action.payload;
            return action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { isExecuting } = isHTTPRequestSlice.actions;

export default isHTTPRequestSlice.reducer;
