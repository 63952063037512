import { Row, Col } from 'antd';
import React, { useContext } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import './index.css';
import '../../Assets/Styles/fonts.css';
import { useSelector } from 'react-redux';
import { QuoteContext } from '../../Pages/Quote-Calculator/quoteContext';
import { formatCurrency, formatValue } from '../../Constants/calculations';
import { GetCustomerDetails } from '../../Constants/commonRegion';
const Footer = () => {
    const location = useLocation();
    const {
        totalInvoices,
        rebateCustomer,
        tab,
        viewScreen,
        historicData,
        rebateGrandTotal,
        summaryGrandTotal,
        managepartTotalInvoice,
        managepartTotalRebate,
    } = useContext(QuoteContext);
    const quoteItemsStore = useSelector((state) => state.quoteItems);
    const reportSummaryPath = matchPath('/parts/rebate/summary', location.pathname);
    const rebateCustomerPath = matchPath('/parts/rebate/customer', location.pathname);
    const rebateReportPath = matchPath('/parts/rebate/detail', location.pathname);
    const partsRebateDetailPath = matchPath('/reporting/parts/rebate/detail', location.pathname);
    const partsRebateReportPath = matchPath('/reporting/parts/rebate/summary', location.pathname);
    const managePartRebateCustomerPath = matchPath('/rebate/management/customer', location.pathname);
    //  Quote Calculator Grand Total
    const dataToUse =
        !reportSummaryPath &&
        !rebateCustomerPath &&
        !rebateReportPath &&
        !partsRebateDetailPath &&
        !partsRebateReportPath
            ? quoteItemsStore
            : null;

    const calculateTotalAndFormat = (data, property, formatType = 'currency') => {
        if (!data || data.length === 0) {
            return '-';
        }
        const total = data.reduce((sum, item) => {
            const value = parseFloat(item[property]);
            return sum + (isNaN(value) ? 0 : value);
        }, 0);
        if (total === 0) {
            return '-';
        }

        switch (formatType) {
            case 'currency':
                return `$${total.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })}`;
            case 'percentage':
                return `${total.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })}%`;
            case 'none':
                return total.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
            default:
                return total.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
        }
    };

    const totalQuotedPrice = calculateTotalAndFormat(dataToUse, 'TotalQuotedPrice');
    const totalBasePrice = calculateTotalAndFormat(dataToUse, 'totalBasePrice');
    const totalStockPrice = calculateTotalAndFormat(dataToUse, 'totalStockOrderPrice');
    const totalRebateAmount = calculateTotalAndFormat(dataToUse, 'totalRebate');
    const totalDealerGp$ = calculateTotalAndFormat(dataToUse, 'dealerGP');
    const totalQuantity = calculateTotalAndFormat(historicData, 'quantity');
    const totalAvgSalesPrice = calculateTotalAndFormat(historicData, 'averagesellprice');

    let formatPercentage;
    const parseDollarValue = (dollarString) => {
        const match = dollarString.match(/-?\$?([\d,]+\.\d{2})/);
        return match ? parseFloat(match[1].replace(/,/g, '')) * (dollarString.includes('-') ? -1 : 1) : 0;
    };

    const totalDealerGp = parseDollarValue(totalDealerGp$);
    const totalStock = parseDollarValue(totalStockPrice);

    if (totalDealerGp !== 0 && totalStock !== 0) {
        const dealerGpPercentage = (totalDealerGp / totalStock) * 100;
        formatPercentage = dealerGpPercentage.toFixed(2) + '%';
    } else {
        formatPercentage = '-';
    }
    //  Rebate Report Detail
    const rebateQuantity = formatValue(rebateGrandTotal?.totalQuantity);
    const quotedPrice = formatValue(rebateGrandTotal?.totalQuotedPrice, '$');
    const grossProfit = formatValue(rebateGrandTotal?.totalGrossProfit, '$');
    const grossProfitPer = formatValue(rebateGrandTotal?.totalGrossProfitPercentage, '', '%');
    const stockPrice = formatValue(rebateGrandTotal?.totalStockOrderPrice, '$');
    const totalRebate = formatValue(rebateGrandTotal?.totalRebate, '$');
    const grandTotalPartsRebateDetail = [
        {
            name: 'Quantity',
            value: rebateQuantity,
        },
        {
            name: 'Quoted Price',
            value: quotedPrice,
        },
        {
            name: 'Stock Order Price',
            value: stockPrice,
        },
        {
            name: 'Gross Profit($)',
            value: grossProfit,
        },
        {
            name: 'Gross Profit(%)',
            value: grossProfitPer,
        },
        {
            name: 'Total Rebate',
            value: totalRebate,
        },
    ];
    // Admin / Trade Summary Grand Total
    const summarySales = formatValue(summaryGrandTotal?.totalSales, '$');
    const summaryRebateSales = formatValue(summaryGrandTotal?.totalRebateSales, '$');
    const grandTotalRebateSummary = [
        {
            name: 'Total Sales',
            value: summarySales,
        },
        {
            name: 'Total Rebate Sales',
            value: summaryRebateSales,
        },
    ];
    const grandTotalValues = [
        {
            name: 'Quoted Price',
            value: totalQuotedPrice,
        },
        {
            name: 'Base Price',
            value: totalBasePrice,
        },
        {
            name: 'Stock Price',
            value: totalStockPrice,
        },
        {
            name: 'Rebate Amount',
            value: totalRebateAmount,
        },
        {
            name: 'Dealer GP%',
            value: formatPercentage,
        },
    ];
    const grandTotalHistoricSales = [
        {
            name: 'Quantity',
            value: totalQuantity,
        },
        {
            name: 'Avg Sales Price',
            value: totalAvgSalesPrice,
        },
    ];
    const rebateCustomerTotal = formatCurrency(rebateCustomer);
    const grandTotalRebateCustomer = [
        {
            name: 'Total Invoices',
            value: totalInvoices,
        },
        {
            name: 'Total Rebate',
            value: rebateCustomerTotal,
        },
    ];
    const grandTotalManageRebateCustomer = [
        {
            name: 'Total Invoices',
            value: managepartTotalInvoice,
        },
        {
            name: 'Total Rebate ($)',
            value: managepartTotalRebate,
        },
    ];
    const rebateAndGp = grandTotalValues.slice(-2);
    const otherValues = grandTotalValues.slice(0, -2);

    return (
        <>
            <div className="grand-total">Grand Total</div>
            {viewScreen === true || tab === 'quoteDetails' ? (
                <>
                    {' '}
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="values-container" justify="center">
                        {otherValues.map((item, index) => (
                            <Col key={index}>
                                <div className="value-item">
                                    <div className="value-name">{item.name}</div>
                                    <div className="value">{item.value}</div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="other-values-container " justify="end">
                        {rebateAndGp.map((item, index) => (
                            <Col key={index}>
                                <div className="value-item">
                                    <div className="value-name">{item.name}</div>
                                    <div className="value">{item.value}</div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </>
            ) : tab === 'historical' && historicData ? (
                <Row
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    className="historic-values-container"
                    justify="end"
                    style={{ marginLeft: '300px' }}
                >
                    {grandTotalHistoricSales.map((item, index) => (
                        <Col key={index}>
                            <div className="value-item">
                                <div className="value-name">{item.name}</div>
                                <div className="value">{item.value}</div>
                            </div>
                        </Col>
                    ))}
                </Row>
            ) : rebateCustomerPath ? (
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="other-values-container">
                    <Col span={13}></Col>
                    {grandTotalRebateCustomer.map((item, index) => (
                        <React.Fragment key={index}>
                            <Col span={4}>
                                <div className="value-item">
                                    <div className="value-name">{item.name}</div>
                                    <div className="value">{item.value}</div>
                                </div>
                            </Col>
                            {/* Empty column between mapped items */}
                            {/* <Col span={1} style={{border:'1px solid green'}}></Col> */}
                        </React.Fragment>
                    ))}
                    <Col span={3}></Col>
                </Row>
            ) : managePartRebateCustomerPath ? (
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="other-values-container">
                    <Col span={15}></Col>
                    {grandTotalManageRebateCustomer.map((item, index) => (
                        <Col key={index} span={3}>
                            <div className="value-item">
                                <div className="value-name">{item.name}</div>
                                <div className="value">{item.value}</div>
                            </div>
                        </Col>
                    ))}
                </Row>
            ) : partsRebateReportPath || reportSummaryPath ? (
                <Row
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    className="values-container"
                    // style={{ marginLeft: "5px", marginRight: "5px" }}
                >
                    <Col span={8}></Col>
                    {grandTotalRebateSummary.map((item, index) => (
                        <React.Fragment key={index}>
                            <Col
                                span={index === 0 ? 5 : 3} // Dynamically assign span based on index
                            >
                                <div className="value-item">
                                    <div className="value-name">{item.name}</div>
                                    <div className="value">{item.value}</div>
                                </div>
                            </Col>
                        </React.Fragment>
                    ))}
                    <Col span={8}></Col>
                </Row>
            ) : (
                <Row
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    className="other-values-container report-details "
                    justify="end"
                >
                    {grandTotalPartsRebateDetail.map((item, index) => (
                        <Col key={index}>
                            <div className="value-item">
                                <div className="value-name">{item.name}</div>
                                <div className="value">{item.value}</div>
                            </div>
                        </Col>
                    ))}
                </Row>
            )}
            <GetCustomerDetails />
        </>
    );
};

export default Footer;
