import React from 'react';
import '../../Assets/Styles/global-styles.css';
import { Button, ConfigProvider } from 'antd';
import { matchPath, useLocation } from 'react-router-dom';

const CustomizedButton = ({ onClick, type, className, icon, children, htmlType, isDisabled = false }) => {
    const childrenUppercase = typeof children === 'string' ? children.toUpperCase() : children;
    const location = useLocation();
    const showLocation = [
        '/reporting/quote/conversion',
        '/reporting/dealer/payments',
        '/rebate/management',
        'rebate/allowance',
    ].some((path) => matchPath(path, location.pathname));
    return (
        <ConfigProvider wave={{ disabled: true }}>
            <Button type={type} className={className} onClick={onClick} htmlType={htmlType} disabled={isDisabled}>
                {icon && typeof icon === 'string' ? (
                    <img className={showLocation ? 'export-file-icon ' : 'import-icon'} src={icon} alt="icon" />
                ) : (
                    icon
                )}
                {childrenUppercase}
            </Button>
        </ConfigProvider>
    );
};
export default CustomizedButton;
