import React, { useEffect, useRef, useState } from 'react';
import { Select } from 'antd';
import { getParts } from '../../Services/userEndpoints';
import { webService } from '../../Services/web.service';

const { Option } = Select;

const PartNumberSelect = ({
    selectedValue = undefined,
    form = undefined,
    name = undefined,
    setPartList = undefined,
    handleChangePartNumber,
}) => {
    const [value, setValue] = useState(undefined);
    const [records, setRecords] = useState([]);
    const isPartListLoading = useRef(false);
    const debounceTimeout = useRef(null);

    const dropdownRender = (menu) => (
        <div>
            <div className=" dropdown-container">
                <p className="dropdown-partnumber-heading">Part Number</p>
                <p className="dropdown-description-heading">Description</p>
            </div>
            {menu}
        </div>
    );

    const [partListAPIQueryParams, setPartListAPIQueryParams] = useState({
        isEffective: false,
        skip: 1,
        limit: 500,
    });

    const handlePartSelectScroll = (event) => {
        const { target } = event;

        const threshold = target.scrollTop + target.offsetHeight >= target.scrollHeight / 2;

        if (threshold) {
            setPartListAPIQueryParams({
                ...partListAPIQueryParams,
                skip: ++partListAPIQueryParams.skip,
            });
        }
    };

    // const handleSearchPart = (e) => {
    //   if (!isPartListLoading.current) {
    //     const value = e.target.value;
    //     setRecords([]);
    //     setPartListAPIQueryParams({
    //       ...partListAPIQueryParams,
    //       skip: 1,
    //       search: value.trim(),
    //     });
    //     isPartListLoading.current = true;
    //   }
    // };

    const handleSearchPart = (e) => {
        const value = e.target.value;

        // Clear the previous timeout if it exists
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        // Set a new timeout to trigger the search
        debounceTimeout.current = setTimeout(() => {
            if (!isPartListLoading.current) {
                setRecords([]); // Reset records before fetching new data
                setPartListAPIQueryParams((prevParams) => ({
                    ...prevParams,
                    skip: 1,
                    search: value.trim(),
                }));
                isPartListLoading.current = true;
            }
        }, 600); // 1000ms delay for debouncing
    };

    const handleOnBlurPartNumberSelect = () => {
        if (form && name) {
            form.validateFields([name]);
        }
        if (!value) {
            setRecords([]);
            setPartListAPIQueryParams({
                ...partListAPIQueryParams,
                skip: 1,
                search: null,
            });
        }
    };

    const getPartList = async () => {
        try {
            webService
                .get(getParts, {
                    ...partListAPIQueryParams,
                })
                .then((response) => {
                    const data = response?.data?.rows;
                    setRecords((prevOptions) => [...prevOptions, ...data]);
                    if (setPartList) {
                        setPartList((prevOptions) => [...prevOptions, ...data]);
                    }
                });
        } catch (error) {
            console.error('Error Status', error);
        } finally {
            isPartListLoading.current = false;
        }
    };

    const handleChange = (e) => {
        const values = e.split('-');
        setValue(values[1]);
        handleChangePartNumber(e);
    };

    useEffect(() => {
        getPartList();
    }, [partListAPIQueryParams]);

    useEffect(() => {
        setValue(selectedValue);
    }, [selectedValue]);

    return (
        <>
            <Select
                showSearch
                className="custom-select custom-placeholder-text"
                onChange={handleChange}
                onBlur={handleOnBlurPartNumberSelect}
                onKeyUp={handleSearchPart}
                filterOption={(input, option) => {
                    const partNumber = option.props.partNumber;
                    const description = option.props.description;
                    return (
                        partNumber?.toLowerCase().includes(input?.toLowerCase()) ||
                        description?.toLowerCase().includes(input?.toLowerCase())
                    );
                }}
                optionFilterProp="children"
                placeholder="Enter Part Number"
                value={value}
                onPopupScroll={handlePartSelectScroll}
                // dropdownStyle={{ width: "360px", height: "200px" }}
                dropdownRender={dropdownRender}
                dropdownStyle={{ width: '385px' }}
            >
                {records?.map((record) => {
                    return (
                        <Option
                            key={record?.id}
                            value={`${record?.id}-${record?.partNumber}-${record?.description}`}
                            partNumber={record?.partNumber}
                            description={record?.description}
                        >
                            <div className="options">
                                <p>{record?.partNumber}</p>
                                <p className="elipse-width options-description">{record?.description}</p>
                            </div>
                        </Option>
                    );
                })}
            </Select>
        </>
    );
};

export default PartNumberSelect;
