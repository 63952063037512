import React, { useEffect, useState } from 'react';
import './style.css';
import { Modal, Pagination, Table } from 'antd';
import CustomizedButton from '../../Button';
import { CloseOutlined } from '@ant-design/icons';
import CommonMessage, { message } from '../../Message';
import * as XLSX from 'xlsx';
import {
    isValidMonth,
    isValidPartNumber,
    isValidPrice,
    isValidQuantity,
    isValidYear,
} from '../../../Constants/constants';

const ImportRebate = ({ isVisible, handleClose, file, handleGetImportedRecords }) => {
    const [filename, setFilename] = useState(null);
    const [columns, setColumns] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [excelData, setExcelData] = useState([]);
    const [allowanceRecords, setAllowanceRecords] = useState([]);
    const [isRecordLoading, setIsRecordLoading] = useState(false);

    const handleTransformToCamelCase = (inputString) => {
        switch (inputString) {
            case 'Year':
                return 'allowanceYear';
            case 'Month':
                return 'allowanceMonth';
            case 'Dealer Code':
                return 'code';
            case 'Rebate Allowance':
                return 'rebateAllowance';
            case 'Additional Allowance':
                return 'additionalRebateAllowance';
            default:
                return inputString.toLowerCase().replace(/\s+(.)/g, function (match, group) {
                    return group.toUpperCase();
                });
        }
    };

    const loadFile = async (file) => {
        const filename = file.name;
        setFilename(filename);

        const binaryStr = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => resolve(e.target.result);
            reader.readAsBinaryString(file);
        });

        const workbook = XLSX.read(binaryStr, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        const expectedHeaders = [
            'Year',
            'Month',
            'Dealer Code',
            'Dealership Name',
            'Rebate Allowance',
            'Additional Allowance',
        ];

        if (
            data.length > 0 &&
            data[0].length === expectedHeaders.length &&
            expectedHeaders.every((header, index) => data[0][index] === header)
        ) {
            // Set Headers
            const tableColumns = data[0].map((element) => ({
                title: element,
                dataIndex: handleTransformToCamelCase(element),
                key: handleTransformToCamelCase(element),
            }));

            tableColumns.push({
                title: 'Total Allowance',
                dataIndex: 'totalAllowance',
                key: 'totalAllowance',
            });

            setColumns(tableColumns);

            const records = [];

            setExcelData(data.slice(1));

            data.slice(1).forEach((record, i) => {
                if (
                    record[2] &&
                    record[3] &&
                    isValidPrice(record[5]) &&
                    isValidPrice(record[4]) &&
                    isValidMonth(record[1]) &&
                    isValidYear(record[0])
                ) {
                    records.push({
                        key: i + 1,
                        allowanceYear: record[0],
                        allowanceMonth: record[1],
                        code: record[2],
                        dealershipName: record[3],
                        rebateAllowance: record[4],
                        additionalRebateAllowance: record[5],
                        totalAllowance: Number(record[4]) + Number(record[5]),
                    });
                }
            });

            setAllowanceRecords(records);
            setDataSource(records);

            if (records.length == 0) {
                message.error('Import failed: No valid items found.');
            }

            if (records.length > 0 && records.length < data.slice(1).length) {
                message.warning('Import partially failed: Some items have invalid data; valid items imported.');
            }
        } else {
            message.error('Excel worksheet columns does not match.');
        }
    };

    useEffect(() => {
        if (allowanceRecords.length > 0) {
            console.log(allowanceRecords);
        }
    }, [allowanceRecords]);

    const handleImport = () => {
        console.log(dataSource);
        handleGetImportedRecords(dataSource);
        // CommonMessage("success", "Allowance File Imported successfully.");
        closeModal();
    };

    const closeModal = () => {
        setDataSource([]);
        handleClose();
    };

    useEffect(() => {
        if (file) {
            loadFile(file);
        }
    }, [file]);

    const onChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const startIndex = currentPage;
    const endIndex = startIndex + pageSize;
    const tableData = dataSource.slice(startIndex, endIndex);

    return (
        <Modal
            visible={isVisible}
            className="import-modal"
            closable={false}
            footer={[
                <span className="footer">
                    <CustomizedButton className="reset-btn" onClick={closeModal}>
                        Cancel
                    </CustomizedButton>
                    <CustomizedButton type="primary" className="import-btn" onClick={handleImport}>
                        Import
                    </CustomizedButton>
                </span>,
            ]}
        >
            <div className="header">
                <h3>{filename}</h3>
                <span className="close-icon">
                    <CloseOutlined className="close-icon" onClick={closeModal} />
                </span>
            </div>
            <div className="content ">
                <div className="modal-container">
                    <Table dataSource={allowanceRecords} columns={columns} pagination={false} />
                </div>
            </div>
            <div className="parts-pagination">
                <Pagination
                    defaultPageSize={15}
                    pageSizeOptions={['15', '25', '50', '100']}
                    showSizeChanger={true}
                    onChange={onChange}
                    total={dataSource.length}
                    defaultCurrent={1}
                    className="custom-pagination"
                />
            </div>
        </Modal>
    );
};
export default ImportRebate;
