import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import routes from './routes';

// Recursive function to render routes and nested routes
const renderRoutes = (routes) => {
    return routes.map((route, index) => {
        if (route.routes) {
            // If there are nested routes, render them recursively inside Routes
            return (
                <Route key={index} path={route.path} element={route.component}>
                    {renderRoutes(route.routes)}
                </Route>
            );
        }

        // Render the route if there are no nested routes
        return <Route key={index} path={route.path} element={route.component} />;
    });
};

function Router() {
    return (
        <BrowserRouter>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>{renderRoutes(routes)}</Routes>
            </Suspense>
        </BrowserRouter>
    );
}

export default Router;
