import React, { useEffect, useState, useContext, useRef } from 'react';
import '../../Assets/Styles/fonts.css';
import '../../Assets/Styles/global-styles.css';
import { Typography, Select, Form, Col, Row, Pagination, Input } from 'antd';
import { CaretDownOutlined, SearchOutlined } from '@ant-design/icons';
import CommonTable from '../../Components/Table';
import ExportIcon from '../../Assets/Icons/ExportIcon.svg';
import ImportFile from '../../Assets/Icons/ImportFile.svg';
import infoCircle from '../../Assets/Icons/InfoCircle.svg';
import CustomizedButton from '../../Components/Button';
import CommonModal from '../../Components/Modal';
import { DateRangePicker, MonthRangePicker } from '../../Components/Datepicker';
import { paginationConfig } from '../../Components/Pagination';
import { QuoteContext } from '../Quote-Calculator/quoteContext';
import { RegionComponent } from '../../Constants/commonRegion';
import { useFormData } from '../../Constants/commonFormdata';
import useGetDealerPayments from '../../shared/hooks/data/useGetDealerPayments';
import { columns } from '../../shared/dataTableColumns/dealerPayments';
import ImportReportsPayment from '../../Components/Modal/ImportReports_DealerPayment/ImportReportsPayment';
import { webService } from '../../Services/web.service';
import { dealerPaymentReportExport, importDealerPayment } from '../../Services/userEndpoints';
import { exportData } from '../../Constants/utils';
import { message } from '../../Components/Message';
import { cleanObject } from '../../Constants/constants';
import moment from 'moment/moment';

const { Title } = Typography;

const DealerPayments = () => {
    const fileInputRef = useRef(null);
    const [exportModalVisible, setExportModalVisible] = useState(false);
    const { regionTypes, dealershipDetails } = useContext(QuoteContext);
    const [importDealerReports, setImportDealerReports] = useState(false);
    const [importedFile, setImportedFile] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isAnyFilterSelected, setIsAnyFilterSelected] = useState(false);

    const [isFiltered, setIsFiltered] = useState(false);

    const [listFilter, setListFilter] = useState({
        loadRecords: false,
        pageSize: paginationConfig?.defaultPageSize,
        pageNumber: 1,
        regionId: 0,
    });

    const { dealerPayments, setListFilters } = useGetDealerPayments(listFilter);

    const [dealerPaymentList, setDealerPaymentList] = useState([]);

    const { searchState, handleSearch, handleDropdownVisibleChange } = useFormData();

    const handleClickExport = () => {
        if (dealerPaymentList?.length > 0) {
            setExportModalVisible(true);
        }
    };

    const onCancelModal = () => {
        setExportModalVisible(false);
    };

    const onPaginationChange = (page, size) => {
        const values = {
            ...listFilter,
            pageSize: size,
            pageNumber: page,
        };

        setListFilters(values);
        setListFilter(values);
    };

    const handleClearFilter = () => {
        const values = {
            ...listFilter,
            regionId: 0,
            dealershipId: undefined,
            dateRange: [null, null],
            loadRecords: false,
            monthYearStart: null,
            monthYearEnd: null,
        };

        setListFilters(values);
        setListFilter(values);
    };

    const handleOnFilterChange = (name, value) => {
        let values = {};
        if (name === 'regionId') {
            if (value !== 0)
                values = {
                    ...listFilter,
                    regionId: value,
                    dealershipId: undefined,
                };
            else {
                values = {
                    ...listFilter,
                    regionId: value,
                };
            }
        } else {
            values = {
                ...listFilter,
                [name]: value,
            };
        }
        setListFilter(values);
    };

    const handleDateChange = (value) => {
        const values = {
            ...listFilter,
            monthYearStart: moment(new Date(value[0])).format('MM/YYYY'),
            monthYearEnd: moment(new Date(value[1])).format('MM/YYYY'),
        };

        setListFilter(values);
    };

    const handleApplyFilter = () => {
        const values = {
            ...listFilter,
            loadRecords: true,
        };
        setListFilter(values);
        setListFilters(values);
    };

    const handleImportFile = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        setImportedFile(e.target.files[0]);
        setImportDealerReports(true);
    };

    const handleHideImportModal = () => {
        setImportDealerReports(false);
        fileInputRef.current.value = '';
    };

    useEffect(() => {
        setDealerPaymentList(dealerPayments?.records);
        console.log(dealerPayments);
        setTotalRecords(dealerPayments?.count ?? 0);
    }, [dealerPayments]);

    const dropdownRender = (menu) => (
        <div>
            <div className=" dropdown-container">
                <p className="dropdown-partnumber-heading">Code</p>
                <p className="dropdown-description-heading">Dealership Name</p>
            </div>
            {menu}
        </div>
    );

    const handleGetImportedRecords = (data) => {
        console.log('Imported Successfully');

        const payload = convertToPayload(data);
        importDealerPayments(payload);
        // setTimeout(() => {
        //   getRebateAllowance();
        // }, 1000);
    };

    const handleExportReport = async () => {
        await exportData(dealerPaymentReportExport, listFilter);
        message.success('Dealer Payment Report is exported.');
        setExportModalVisible(false);
    };

    const convertToPayload = (data) => {
        return {
            items: data.map((item) => {
                return {
                    dealerCode: item.dealerCode.toString(),
                    allowanceYear: item.allowanceYear,
                    allowanceMonth: item.allowanceMonth,
                    amount: item.amount,
                    receiptId: item.receiptId,
                };
            }),
        };
    };

    const importDealerPayments = async (payload) => {
        try {
            webService.post(importDealerPayment, payload).then((response) => {
                // const data = response?.data || [];
                const values = {
                    ...listFilter,
                    pageNumber: 1,
                };

                setListFilters(values);
                setListFilter(values);
            });
        } catch (error) {
            console.error('Error making API call:', error);
        }
    };

    const isAnyFilterSelectedFun = () => {
        setIsAnyFilterSelected(
            listFilter.regionId > 0 ||
                listFilter.dealershipId ||
                listFilter.dateRange ||
                listFilter.monthYearStart ||
                listFilter.monthYearEnd
        );
    };

    useEffect(() => {
        setIsFiltered(
            (() => {
                return Object.keys(cleanObject(listFilter)).length > 0;
            })()
        );
        if (listFilter.dateRange && listFilter.dateRange[0] === null && listFilter.dateRange[1] === null) {
            setListFilter({
                ...listFilter,
                dateRange: undefined,
            });
        }
        isAnyFilterSelectedFun();
    }, [listFilter]);

    return (
        <div>
            <div className="main-container">
                <div className="box">
                    <div className="row-col-container">
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={8}>
                                <Title className="customer-id summary-dropdown" level={5}>
                                    Report Type
                                </Title>
                                <Form.Item>
                                    <Select
                                        showSearch
                                        className="custom-select custom-placeholder-text"
                                        value={listFilter.regionId}
                                        onChange={(value) => handleOnFilterChange('regionId', value)}
                                        onSearch={(value) => handleSearch('region', value)}
                                        onDropdownVisibleChange={(open) => handleDropdownVisibleChange('region', open)}
                                        suffixIcon={
                                            searchState.region.onSearch ? (
                                                <SearchOutlined className="dropdown-icon" />
                                            ) : (
                                                <CaretDownOutlined className="dropdown-icon" />
                                            )
                                        }
                                        placeholder="Select Region"
                                    >
                                        {regionTypes?.map((item) => (
                                            <Select.Option key={item.id} value={item?.id}>
                                                {item?.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <Title className="customer-id summary-dropdown" level={5}>
                                    Dealer Code / Name
                                </Title>
                                <Form.Item>
                                    {listFilter?.regionId == '0' ? (
                                        <Select
                                            showSearch
                                            className="custom-select custom-placeholder-text"
                                            value={listFilter.dealershipId}
                                            onChange={(value) => {
                                                handleOnFilterChange('dealershipId', value);
                                            }}
                                            onSearch={(value) => handleSearch('dealerCode', value)}
                                            onDropdownVisibleChange={(open) =>
                                                handleDropdownVisibleChange('dealerCode', open)
                                            }
                                            suffixIcon={
                                                searchState.dealerCode.onSearch ? (
                                                    <SearchOutlined className="dropdown-icon" />
                                                ) : (
                                                    <CaretDownOutlined className="dropdown-icon" />
                                                )
                                            }
                                            placeholder="Select Dealer"
                                            filterOption={(input, option) => {
                                                const code = option.props.code;
                                                const name = option.props.name;
                                                return (
                                                    code?.toLowerCase().includes(input?.toLowerCase()) ||
                                                    name?.toLowerCase().includes(input?.toLowerCase())
                                                );
                                            }}
                                            optionFilterProp="children"
                                            dropdownRender={dropdownRender}
                                        >
                                            {dealershipDetails?.map((item) => (
                                                <Select.Option
                                                    key={item?.id}
                                                    value={`${item?.id}`}
                                                    code={item?.code}
                                                    name={item?.name}
                                                >
                                                    <div className="options">
                                                        <p>{item?.code}</p>
                                                        <p className="elipse-width options-description">{item?.name}</p>
                                                    </div>
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    ) : (
                                        <Input className="input-style" disabled placeholder="Select Dealer" />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <Title className="customer-id" level={5}>
                                    Invoice Period
                                </Title>
                                <Form.Item className="summary-report">
                                    <MonthRangePicker
                                        selectedDates={listFilter.dateRange}
                                        setSelectedDates={handleDateChange}
                                        format="MM/YYYY"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div className="hr-container">
                        <div className="quotes-all-list-container">
                            <CustomizedButton className="reset-btn" onClick={handleClearFilter}>
                                Reset
                            </CustomizedButton>
                            <CustomizedButton
                                type="primary"
                                className={isAnyFilterSelected ? 'apply-btn' : 'apply-btn-disabled'}
                                onClick={handleApplyFilter}
                            >
                                Apply
                            </CustomizedButton>
                        </div>
                        <hr className="hr-line" />
                    </div>
                    <div>
                        <Title className="quote-items all-quote-list" level={4}></Title>
                        <div className="total-container reports-container">
                            <div className="total-text">
                                Total:
                                <span className="item">
                                    {totalRecords > 0 && totalRecords < 10 ? `0${totalRecords}` : totalRecords} item
                                    {totalRecords > 1 ? 's' : ''}
                                </span>
                            </div>
                            <div className="apply-btn-container">
                                <CustomizedButton
                                    className="import-file-btn export-button export-file btn-right "
                                    type="Outlined"
                                    icon={ImportFile}
                                    onClick={handleImportFile}
                                >
                                    Import File
                                </CustomizedButton>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    accept=".xlsx,.xls"
                                    onChange={handleFileChange}
                                />
                                <CustomizedButton
                                    className={'import-file-btn export-button summary-exportbtn'}
                                    type="Outlined"
                                    icon={ExportIcon}
                                    onClick={handleClickExport}
                                >
                                    Export Report
                                </CustomizedButton>
                            </div>
                        </div>
                        <div className="table-container">
                            <CommonTable dataSource={dealerPaymentList} columns={columns} scrollable={false} />
                            {/* {totalRecords > 15 && ( */}
                            <div className="quote-pagination">
                                <Pagination
                                    onChange={onPaginationChange}
                                    total={totalRecords}
                                    className="custom-pagination"
                                    current={listFilter.pageNumber}
                                    {...paginationConfig}
                                />
                            </div>
                            {/* )} */}
                        </div>
                        <CommonModal
                            id=":rh:"
                            visible={exportModalVisible}
                            onCancel={onCancelModal}
                            onConfirm={handleExportReport}
                            title={
                                <>
                                    <img className="infocircle-quoteconversion" src={infoCircle} /> Export Report
                                </>
                            }
                            showExportButton={exportModalVisible}
                        >
                            <p className="export-message info-messages-quoteconversion">
                                Export Part Summary Report in XLSX format
                            </p>
                        </CommonModal>
                    </div>
                </div>
            </div>
            <ImportReportsPayment
                isVisible={importDealerReports}
                handleClose={handleHideImportModal}
                file={importedFile}
                handleGetImportedRecords={handleGetImportedRecords}
            />
            <RegionComponent />
        </div>
    );
};
export default DealerPayments;
