import { createSlice } from '@reduxjs/toolkit';
import { customerApprovalStatus, customerTypes } from '../Constants/enum';
const { rebate } = await import(`${process.env.REACT_APP_SERVER_URL}/rebate-calculation.js`);

export const quoteItemsSlice = createSlice({
    name: 'quoteItems',
    initialState: [],
    reducers: {
        add: (state, action) => {
            const { data, selectedPart, customer } = action.payload;
            data.customerType = customer.customerType;
            data.customerApprovalStatus = customer.approvalStatus;
            data.minimumSellPrice =
                data.minimumSellPrice ??
                (selectedPart.partsBaskets && selectedPart.partsBaskets.length > 0
                    ? selectedPart.partsBaskets[0].minimumSellPrice
                    : null);
            const existingPartIndex = state.findIndex(
                (item) => item.partNumber == data.partNumber && item.quotedSellPrice == data.quotedSellPrice
            );
            if (existingPartIndex !== -1) {
                const record = state[existingPartIndex];
                data.quantity = parseInt(record.quantity) + parseInt(data.quantity);
                data.quotedSellPrice = parseFloat(record.quotedSellPrice);
                data['totalBasePrice'] = data.minimumSellPrice
                    ? parseFloat(data.minimumSellPrice) * parseInt(data.quantity)
                    : null;
                data['totalStockOrderPrice'] = parseFloat(data.stockPrice) * parseInt(data.quantity);
                state[existingPartIndex] = calculateValues(data, selectedPart);
            } else {
                data['part'] = selectedPart;
                data['totalBasePrice'] = data.minimumSellPrice
                    ? parseFloat(data.minimumSellPrice) * parseInt(data.quantity)
                    : null;
                data['totalStockOrderPrice'] = parseFloat(data.part.stockPrice) * parseInt(data.quantity);
                data.quotedSellPrice = parseFloat(data.quotedSellPrice);
                state.unshift(calculateValues(data, selectedPart));
            }
        },

        remove: (state, action) => {
            return removeIndex(state, action.payload);
        },

        removeAll: (state, action) => {
            return [];
        },

        edit: (state, action) => {
            let { index, data } = action.payload;
            data.quotedSellPrice = parseFloat(data.quotedSellPrice);
            const recordIndex = state.findIndex((record) => {
                return record.partNumber == data.partNumber && record.quotedSellPrice == data.quotedSellPrice;
            });
            if (recordIndex !== -1 && recordIndex !== index) {
                const record = state[recordIndex];
                record.quantity = parseInt(record.quantity) + parseInt(data.quantity);
                data.totalBasePrice = parseFloat(record.minimumSellPrice) * parseInt(data.quantity);
                data.totalStockOrderPrice = parseFloat(record.stockOrderPrice) * parseInt(data.quantity);
                state[recordIndex] = calculateValues(record, {
                    minimumSellPrice: record.part.minimumSellPrice,
                    stockPrice: record.part.stockPrice,
                });
                const update = removeIndex(state, index);
                state.length = 0;
                state = state.push(...update);
            } else {
                data.totalBasePrice = parseFloat(data.minimumSellPrice) * parseInt(data.quantity);
                data.totalStockOrderPrice = parseFloat(data.stockOrderPrice) * parseInt(data.quantity);

                state[index] = calculateValues(data, {
                    minimumSellPrice: data.part.minimumSellPrice,
                    stockPrice: data.part.stockPrice,
                });
            }
        },

        insertBulk: (state, action) => {
            let { data, customer } = action.payload;
            data?.forEach((element) => {
                element['customerType'] = customer.customerType;
                element['customerApprovalStatus'] = customer.approvalStatus;
                const existingPartIndex = state.findIndex(
                    (item) => item.partNumber == element.partNumber && item.quotedSellPrice == element.quotedSellPrice
                );
                if (existingPartIndex !== -1) {
                    const record = state[existingPartIndex];
                    element.quantity = parseInt(record.quantity) + parseInt(element.quantity);
                    element.quotedSellPrice = parseFloat(record.quotedSellPrice);
                    element['part'] = {
                        minimumSellPrice: element.minimumSellPrice,
                        stockPrice: element.stockOrderPrice,
                    };
                    element['totalBasePrice'] = parseFloat(element.minimumSellPrice) * parseInt(element.quantity);
                    element['totalStockOrderPrice'] = parseFloat(element.stockOrderPrice) * parseInt(element.quantity);

                    state[existingPartIndex] = calculateValues(element, {
                        minimumSellPrice: element.minimumSellPrice,
                        stockPrice: element.stockOrderPrice,
                    });
                } else {
                    element['part'] = {
                        minimumSellPrice: element.minimumSellPrice,
                        stockPrice: element.stockOrderPrice,
                    };
                    element['totalBasePrice'] = parseFloat(element.part.minimumSellPrice) * parseInt(element.quantity);
                    element['totalStockOrderPrice'] =
                        parseFloat(element.part.stockOrderPrice) * parseInt(element.quantity);

                    state.unshift(calculateValues(element, element.part));
                }
            });
        },

        removeAll: (state, action) => {
            return [];
        },
    },
});

const getUniqueByValue = (array, key) => {
    const seen = new Set();
    return array.filter((item) => {
        const value = item[key];
        if (seen.has(value)) {
            return false;
        }
        seen.add(value);
        return true;
    });
};

const removeIndex = (array, index) => {
    return array.filter((item, i) => i !== index);
};

const calculateValues = (data, part) => {
    const minimumSellPrice = data.minimumSellPrice;
    const stockOrderPrice = part.stockPrice;
    const maxRebatePrice = part.maxRebatePrice;

    const isTradeOrDealerCustomer =
        data.customerType == customerTypes.TRADE || data.customerType == customerTypes.DEALER;

    return {
        ...data,
        minimumSellPrice,
        stockOrderPrice,
        TotalQuotedPrice: parseFloat(data.quotedSellPrice) * parseInt(data.quantity),
        GPRecovery: rebate.calculateGPRecovery(
            data.quotedSellPrice,
            stockOrderPrice,
            data.quantity,
            minimumSellPrice,
            maxRebatePrice
        ),
        handlingFee: rebate.calculateHandlingFee(
            data.quotedSellPrice,
            stockOrderPrice,
            data.quantity,
            minimumSellPrice,
            maxRebatePrice
        ),
        dealerGpPercentage: rebate.calculateGPPercentage(
            data.quotedSellPrice,
            stockOrderPrice,
            data.quantity,
            minimumSellPrice,
            maxRebatePrice
        ),
        totalRebate:
            isTradeOrDealerCustomer && data.customerApprovalStatus == customerApprovalStatus.APPROVED
                ? rebate.calculateTotalRebate(
                      data.quotedSellPrice,
                      stockOrderPrice,
                      data.quantity,
                      minimumSellPrice,
                      maxRebatePrice
                  )
                : 0,
        dealerGP: rebate.calculateDealerGP(
            data.quotedSellPrice,
            stockOrderPrice,
            data.quantity,
            minimumSellPrice,
            maxRebatePrice
        ),

        ruleApplied:
            isTradeOrDealerCustomer && data.customerApprovalStatus == customerApprovalStatus.APPROVED
                ? rebate.calculateRuledApplied(
                      data.quotedSellPrice,
                      stockOrderPrice,
                      data.quantity,
                      minimumSellPrice,
                      maxRebatePrice
                  )
                : 'Rebates are not applicable for Retail or Rejected customers',
    };
};

// Action creators are generated for each case reducer function
export const { add, remove, edit, insertBulk, removeAll } = quoteItemsSlice.actions;

export default quoteItemsSlice.reducer;
