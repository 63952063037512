import { exportApi, quoteStatusApi } from '../Services/userEndpoints';
import { saveAs } from 'file-saver';
import { webService } from '../Services/web.service';
import CryptoJS from 'crypto-js';

export const exportQuotesApi = async (quoteId) => {
    try {
        webService.get(`${exportApi}${quoteId}`).then((response) => {
            const { fileName, buffer } = response.data;
            const arrayBuffer = new Uint8Array(buffer.data).buffer;
            const blob = new Blob([arrayBuffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            saveAs(blob, fileName);
        });
    } catch (error) {
        console.error('Error exporting quote:', error);
        throw error;
    }
};

//Quote Status Api
export const getQuoteStatus = async () => {
    try {
        return webService
            .get(quoteStatusApi)
            .then((response) => {
                const quoteStatus = response?.data?.rows || [];
                return quoteStatus;
            })
            .catch(() => {
                return null;
            });
    } catch (error) {
        console.error('Error fetching Quote Status', error);
        throw error;
    }
};

// Export or Download File
export const exportData = async (apiEndpoint, payload = null) => {
    try {
        webService.post(apiEndpoint, payload).then((response) => {
            const { fileName, buffer } = response.data;
            const arrayBuffer = new Uint8Array(buffer.data).buffer;
            const blob = new Blob([arrayBuffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            saveAs(blob, fileName);
        });
    } catch (error) {
        console.error('Error exporting data:', error);
        throw error;
    }
};

export const sortArray = (arr, key, order = 'asc') => {
    const sortedArray = [...arr];
    sortedArray.sort((a, b) => {
        if (order === 'asc') {
            if (a[key] < b[key]) return -1;
            if (a[key] > b[key]) return 1;
            return 0;
        } else if (order === 'desc') {
            if (a[key] > b[key]) return -1;
            if (a[key] < b[key]) return 1;
            return 0;
        }
        return 0;
    });

    return sortedArray;
};

export const encrypt = (value) => {
    return CryptoJS.AES.encrypt(value, process.env.REACT_APP_ENCRYPTION_KEY).toString();
};

export const decrypt = (encrypted) => {
    const decrypted = CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_ENCRYPTION_KEY);
    return decrypted.toString(CryptoJS.enc.Utf8);
};
