import { message as messageAPI } from 'antd';

const CommonMessage = (type, content, duration = 2, onClose, className = '') => {
    messageAPI.open({
        type: type,
        duration: duration,
        content: content,
        className: `${className} ${type === 'success' ? 'custom-success-message' : ''}`,
        onClose: onClose,
    });
};

export default CommonMessage;

export const message = {
    success: (content) => {
        messageAPI.open({
            type: 'success',
            duration: 2,
            content,
            className: 'custom-success-message',
        });
    },
    warning: (content) => {
        messageAPI.open({
            type: 'warning',
            duration: 2,
            content,
        });
    },
    error: (content) => {
        messageAPI.open({
            type: 'error',
            duration: 2,
            content,
        });
    },
};
