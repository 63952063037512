import Cookies from 'js-cookie';
import { encrypt } from '../Constants/utils';

const userRole = {
    set: (value) => {
        Cookies.set('role', encrypt(value));
    },
    get: () => {
        return Cookies.get('role');
    },
};

export default userRole;
