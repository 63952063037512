import { configureStore } from '@reduxjs/toolkit';
import quoteItemsSlice from './quoteItemsSlice';
import isHTTPRequestSlice from './isHTTPRequestSlice';
import userSlice from './userSlice';

export default configureStore({
    reducer: {
        quoteItems: quoteItemsSlice,
        user: userSlice,
        isHTTPRequest: isHTTPRequestSlice,
    },
});
