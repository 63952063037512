import React from 'react';
import { Table } from 'antd';
import '../../Assets/Styles/global-styles.css';
import '../../Assets/Styles/fonts.css';
import { useSelector } from 'react-redux';

const CommonTable = ({ dataSource, columns, scrollable, onChange }) => {
    const isHTTPRequest = useSelector((state) => state.isHTTPRequest);

    let columnsWithActions = [
        ...columns.map((column) => ({
            ...column,
            title: <span className="table-column-headings">{column.title}</span>,
        })),
    ];
    const tableProps = {
        showSorterTooltip: { title: '' },
        columns: columnsWithActions,
        dataSource: dataSource,
        pagination: false,
        bordered: true,
        scroll: { x: scrollable ? 1300 : 0 },
        loading: isHTTPRequest,
        onChange,
    };
    return (
        <div>
            <Table {...tableProps} />
        </div>
    );
};
export default CommonTable;
