import React, { useContext, useState, useEffect } from 'react';
import '../../Assets/Styles/global-styles.css';
import { Card, Typography, MonthPicker } from 'antd';
import rebateCalculaterSvg from '../../Assets/Icons/Quote Calculator.svg';
import rebateReportDetailedIllustration from '../../Assets/Icons/Parts Rebate Detailed Report.svg';
import managePartsRebateCustomers from '../../Assets/Icons/Manage Parts Rebate Customers.svg';
import homeRebateSummary from '../../Assets/Icons/homeRebateSummary.svg';
import homeDealerPayments from '../../Assets/Icons/homeDealerPayments.svg';
import homeQuoteConversion from '../../Assets/Icons/homeQuoteConversion.svg';
import homePartsRebateDetails from '../../Assets/Icons/homePartsRebateDetails.svg';
import { Link } from 'react-router-dom';
import { Col, Row, Input } from 'antd';
import '../../Assets/Styles/fonts.css';
import { Form, Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { CalendarOutlined } from '@ant-design/icons';
import CustomizedButton from '../../Components/Button';
import useGetUser from '../../shared/hooks/useGetUser';
import { QuoteContext } from '../Quote-Calculator/quoteContext';
import { webService } from '../../Services/web.service';
import { reportdealerShipsApi } from '../../Services/userEndpoints';
import { RegionComponent } from '../../Constants/commonRegion';
import { months } from 'moment/moment';
import { DatePicker } from 'antd';
import { type } from '@testing-library/user-event/dist/type';
import { sideNavCollapsedState } from '../../Recoil/recoilState';
import { useRecoilState } from 'recoil';
import { useIsAdmin } from '../../shared/hooks/role';

import Cards1 from './Cards1';

const { Title } = Typography;

const ReportYearSelect = () => {
    // Generate a list of years (for example, from 2010 to the current year)
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let i = currentYear; i >= 2010; i--) {
        years.push(i);
    }

    return years; // This function will return the list of years
};

const ReportMonthSelect = () => {
    // Generate a list of months (from January to December)
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    return months; // This function will return the list of months
};

const AdminHome = (props) => {
    const isAdmin = useIsAdmin();
    const { user } = useGetUser();
    const years = ReportYearSelect(); // Use the function to get the years
    const months = ReportMonthSelect();
    const [listFilter, setListFilter] = useState({
        regionId: 0,
    });
    const [selectedDates, setSelectedDates] = useState();

    const { regionTypes } = useContext(QuoteContext);
    const [dealerList, setDealerList] = useState([]);
    const [collapsed, setCollapsed] = useRecoilState(sideNavCollapsedState);

    const mainCardlabels = [
        {
            id: 1,
            description: 'Create and manage parts rebate basket',
            label: 'Rebate Management',
            icon: rebateCalculaterSvg,
            route: '/rebate/management',
        },
        {
            id: 2,
            description: 'Every trade sale is recorded at the part number level, including and excluding rebates',
            label: 'Manage Parts Rebate Customers',
            icon: rebateReportDetailedIllustration,
            route: '/rebate/management/customer',
        },
        {
            id: 3,
            description: 'Display a list of customers who are approved or rejected for the parts rebate',
            label: 'Manage Dealer Rebate Allowance',
            icon: managePartsRebateCustomers,
            route: '/rebate/allowance',
        },
        {
            id: 4,
            type: 'reporting',
            label: 'Parts Rebate Summary',
            icon: homeRebateSummary,
            route: '/reporting/parts/rebate/summary',
        },
        {
            id: 5,
            type: 'reporting',
            label: 'Parts Rebate Details',
            icon: homePartsRebateDetails,
            route: '/reporting/parts/rebate/detail',
        },
        {
            id: 6,
            type: 'reporting',
            label: 'Dealer Payments',
            icon: homeDealerPayments,
            route: '/reporting/dealer/payments',
        },
        ...(isAdmin
            ? [] // If admin, do not include the Quote Conversion option
            : [
                  {
                      id: 7,
                      type: 'reporting',
                      label: 'Quote Conversion',
                      icon: homeQuoteConversion,
                      route: '/reporting/quote/conversion',
                  },
              ]),
        {
            id: 5,
            label: 'Parts Quoted Report',
            icon: homeQuoteConversion,
            route: '/reporting/quote/report',
        },
    ];

    const getDealerList = async () => {
        // API call to get dealer list
        try {
            webService.get(reportdealerShipsApi).then((response) => {
                const records = response?.data?.rows;
                if (records) {
                    setDealerList(records);
                }
            });
        } catch (error) {
            console.error('Error fetching Dealership', error);
        }
    };
    const handleClearFilter = () => {
        console.log('reset button ic clicked');
        const resetState = {
            regionId: 0, // or null, depending on your initial value
            dealershipId: undefined,
            monthYearStart: undefined,
            monthYearEnd: undefined,
        };

        setListFilter1(resetState); // Reset the state
        setSelectedDates([]); // If you have any selected dates to clear
        console.log('Reset state:', resetState);
        console.log('Selected Dates Cleared:', []);
    };

    // const handleApplyFilter = () => {
    //   handleListFilterUpdate(listFilter);
    // };

    useEffect(() => {
        getDealerList();
    }, []);

    const dropdownRender = (menu) => (
        <div>
            <div className=" dropdown-container">
                <p className="dropdown-partnumber-heading">Code</p>
                <p className="dropdown-description-heading">Dealership Name</p>
            </div>
            {menu}
        </div>
    );

    const handleSearch = (key, value) => {
        // setSearchState((prevState) => ({
        //   ...prevState,
        //   [key]: {
        //     ...prevState[key],
        //     onSearch: value !== "" || prevState[key].dropdownOpen,
        //   },
        // }));
    };
    const [listFilter1, setListFilter1] = useState({
        regionId: 0,
        dealershipId: undefined,
        monthYearStart: undefined,
        monthYearEnd: undefined,
    });

    const handleDateChange = (date, dateString, type) => {
        // Ensure dateString is defined and handle the logic for month and year selection
        if (type === 'month') {
            setListFilter1((prevState) => ({
                ...prevState,
                monthYearStart: dateString, // Store month selection as dateString
            }));
        } else if (type === 'year') {
            setListFilter1((prevState) => ({
                ...prevState,
                monthYearEnd: dateString, // Store year selection as dateString
            }));
        }
    };

    const handleRegionChange = (value) => {
        setListFilter1((prevState) => ({
            ...prevState,
            regionId: value,
        }));
    };

    const handleDealerChange = (value) => {
        setListFilter1((prevState) => ({
            ...prevState,
            dealershipId: value,
        }));
    };

    const handleApplyFilter = () => {
        console.log('Selected Filters:');
        console.log('Region ID:', listFilter1.regionId);
        console.log('Dealer ID:', listFilter1.dealershipId);
        console.log('Start Month/Year:', listFilter1.monthYearStart);
        console.log('End Month/Year:', listFilter1.monthYearEnd);
    };

    return (
        <div style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
            <div style={{ position: 'relative', bottom: '20px' }}>
                <Row gutter={[14, 14]} justify="start" style={{ marginTop: '15px' }}>
                    {mainCardlabels?.slice(0, 3).map((eachObject) => (
                        <Col xs={24} sm={6} lg={6} xl={6} key={eachObject.id}>
                            <Link to={eachObject.route}>
                                <Card className={collapsed ? 'card-container' : 'card-container expanded'}>
                                    <div>
                                        <h2 className="report-card-heading">{eachObject.label}</h2>
                                    </div>
                                    <div className="cards-content">
                                        <img
                                            src={eachObject.icon}
                                            alt="icon"
                                            className={eachObject?.type === 'reporting' ? 'report-icon' : 'card-img'}
                                        />
                                    </div>
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>
                <Row gutter={[14, 14]} justify="start" style={{ marginTop: '15px' }}>
                    {mainCardlabels?.slice(3).map((eachObject) => (
                        <Col xs={24} sm={6} lg={6} xl={6} key={eachObject.id}>
                            <Link to={eachObject.route}>
                                <Card className="report-container">
                                    <div>
                                        <h2 className="report-card-heading">{eachObject.label}</h2>
                                    </div>
                                    <div className="cards-content">
                                        <img src={eachObject.icon} alt="icon" className="report-icon" />
                                    </div>
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>
            </div>

            <div className="main-container">
                <div className="box">
                    <div className="row-col-container">
                        <Row
                            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                            justify="start"
                            style={{ alignItems: 'flex-end' }}
                        >
                            {/* Report Type */}
                            <Col span={6}>
                                <Title className="customer-id" level={5}>
                                    Report Type
                                </Title>
                                <Select
                                    className="report-type custom-select custom-placeholder-text"
                                    suffixIcon={<CaretDownOutlined className="dropdown-icon" />}
                                    placeholder="Select Report Type"
                                >
                                    <Select.Option value="national">National Report</Select.Option>
                                    <Select.Option value="region">Region Specific Report</Select.Option>
                                    <Select.Option value="dealer">Dealer Specific Report</Select.Option>
                                </Select>
                            </Col>

                            {/* Select Dealer */}
                            <Col span={8}>
                                <Title className="customer-id" level={5}>
                                    Select Dealer
                                </Title>
                                <Select
                                    showSearch
                                    className="custom-select custom-placeholder-text"
                                    value={listFilter1?.dealershipId}
                                    onChange={handleDealerChange}
                                    onSearch={handleSearch}
                                    suffixIcon={<CaretDownOutlined className="dropdown-icon" />}
                                    placeholder="Select Dealer"
                                    filterOption={(input, option) => {
                                        const { code, name } = option.props;
                                        return (
                                            code?.toLowerCase().includes(input.toLowerCase()) ||
                                            name?.toLowerCase().includes(input.toLowerCase())
                                        );
                                    }}
                                    optionFilterProp="children"
                                    dropdownRender={dropdownRender}
                                >
                                    {dealerList?.map((item) => (
                                        <Select.Option
                                            key={item.id}
                                            value={`${item.id}`}
                                            code={item.code}
                                            name={item.name}
                                        >
                                            <div className="options">
                                                <p>{item.code}</p>
                                                <p className="elipse-width options-description">{item.name}</p>
                                            </div>
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Col>

                            {/* Report Month */}
                            <Col span={6}>
                                <Title className="customer-id" level={5}>
                                    Report Month
                                </Title>
                                <DatePicker
                                    className=" report-month custom-select custom-placeholder-text"
                                    style={{ borderRadius: '2px' }}
                                    picker="month"
                                    format="MM/YYYY"
                                    onChange={(date, dateString) => handleDateChange(date, dateString, 'month')}
                                />
                            </Col>
                            <Col span={4}>
                                <div className="quotes-all-list-container">
                                    <CustomizedButton className="reset-btn" onClick={handleClearFilter}>
                                        Reset
                                    </CustomizedButton>
                                    <CustomizedButton type="primary" className="apply-btn" onClick={handleApplyFilter}>
                                        Apply
                                    </CustomizedButton>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    {/* Reset and Apply Buttons */}
                    <div className="hr-container">
                        <hr className="hr-line" />
                    </div>

                    {/* Additional Components */}
                    <RegionComponent />
                    <Cards1 />
                </div>
            </div>
        </div>
    );
};
export default AdminHome;
