import '../../Assets/Styles/fonts.css';
import React, { useEffect, useState } from 'react';
import { Typography, Select, Form, Col, Row, Input, Space, Modal } from 'antd';
import '../../Assets/Styles/global-styles.css';
import CustomizedButton from '../../Components/Button';
import { customerInformation } from '../../Services/userEndpoints';
import { useContext } from 'react';
import { QuoteContext } from '../Quote-Calculator/quoteContext';
import { GetCustomerApprovalStatus, GetCustomerTypes, GetCustomerUnapprovalReason } from '../../Constants/commonRegion';
import { webService } from '../../Services/web.service';
const { Title } = Typography;
const { Option } = Select;
const RebateManagementEditt = ({
    isModalVisible,
    setIsModalVisible,
    handleModalCancel,
    editingRecord,
    updateValues,
    dmsId,
    rebateDetail,
}) => {
    const [form] = Form.useForm();
    const { approvalStatus, unApprovalStatus, customerType } = useContext(QuoteContext);
    const [formValues, setFormValues] = useState({
        customerName: undefined,
        email: undefined,
        contactNumber: undefined,
        businessName: undefined,
        customerType: undefined,
        approvalStatus: undefined,
        reason: undefined,
        otherReason: undefined,
    });

    const getFormData = async () => {
        try {
            webService
                .get(`${customerInformation}/${dmsId}`)
                .then((response) => {
                    const responseData = response.data;
                    form.setFieldsValue({
                        customerName: responseData.name,
                        email: responseData.email,
                        contactNumber: responseData.contact,
                        businessName: responseData.businessName,
                        customerType: responseData.customerType,
                        approvalStatus: responseData.approvalStatus,
                        reason: responseData.reason,
                        otherReason: responseData.reason == 6 ? responseData.otherReason : undefined, // Assuming you have `otherReason` in `responseData`
                    });
                })
                .catch(() => {});
        } catch (error) {
            console.log('Error :', error);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleChange = (key, value) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [key]: value,
        }));
        if (key != 'reason' && value != '6' && key != 'otherReason') {
            form.setFieldsValue({
                otherReason: '',
            });
        }
    };

    const handleSubmit = (values) => {
        debugger;
        let payload = {};
        payload = {
            name: values.customerName,
            email: values.email,
            contact: values.contactNumber,
            approvalStatus: values.approvalStatus,
            reason: values.reason,
            businessName: values.businessName,
            customerType: values.customerType,
            otherReason: values.reason == 6 ? values.otherReason : '',
        };
        updateValues({ ...editingRecord, ...payload });
        setIsModalVisible(false);
    };
    useEffect(() => {
        if (dmsId) {
            getFormData();
        }
    }, [dmsId]);
    return (
        <>
            <Modal
                title={
                    <>
                        Edit Customer Information
                        <div style={{ borderBottom: '1px solid #e8e8e8', margin: '16px 0' }} />
                    </>
                }
                centered
                visible={isModalVisible}
                onCancel={handleModalCancel}
                footer={null}
                width={750}
                className="no-border-radius-modal"
            >
                <div>
                    <Form form={form} onFinish={handleSubmit} className="custom-no-margin">
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={12}>
                                <Title className="customer-id summary-dropdown">Customer Name</Title>
                                <Form.Item
                                    className="custom-no-margin"
                                    name="customerName"
                                    rules={[{ required: true, message: 'This field is required.' }]}
                                >
                                    <Input
                                        showSearch
                                        value={formValues.customerName}
                                        onBlur={() => {
                                            form.validateFields(['customerName']);
                                        }}
                                        onChange={(e) => handleChange('customerName', e.target.value)}
                                        className="custom-select custom-placeholder-text"
                                        style={{ borderRadius: '0px' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Title className="customer-id summary-dropdown" level={1}>
                                    Customer Email ID
                                </Title>
                                <Form.Item
                                    className="custom-no-margin"
                                    name="email"
                                    rules={[{ type: 'email', message: 'Please enter a valid email' }]}
                                >
                                    <Input
                                        showSearch
                                        value={formValues.email}
                                        onBlur={() => {
                                            form.validateFields(['email']);
                                        }}
                                        onChange={(e) => handleChange('email', e.target.value)}
                                        // value={selectedRecordData.email}
                                        className="custom-select custom-placeholder-text"
                                        style={{ borderRadius: '0px' }}
                                        autoComplete="off"
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Title className="customer-id summary-dropdown" level={1}>
                                    Customer Contact Number
                                </Title>
                                <Form.Item
                                    className="custom-no-margin"
                                    name="contactNumber"
                                    rules={[
                                        {
                                            pattern: /^[0-9]+$/,
                                            message: 'Contact number must be numeric',
                                        },
                                    ]}
                                >
                                    <Input
                                        showSearch
                                        value={formValues.contactNumber}
                                        onBlur={() => {
                                            form.validateFields(['contactNumber']);
                                        }}
                                        onChange={(e) => handleChange('contactNumber', e.target.value)}
                                        className="custom-select custom-placeholder-text"
                                        style={{ borderRadius: '0px' }}
                                        autoComplete="off"
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Title className="customer-id summary-dropdown" level={1}>
                                    Business Name
                                </Title>
                                <Form.Item className="custom-no-margin" name="businessName">
                                    <Input
                                        showSearch
                                        value={formValues.businessName}
                                        onBlur={() => {
                                            form.validateFields(['businessName']);
                                        }}
                                        onChange={(e) => handleChange('businessName', e.target.value)}
                                        className="custom-select custom-placeholder-text"
                                        style={{ borderRadius: '0px' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Title className="customer-id summary-dropdown" level={1}>
                                    Customer Type
                                </Title>
                                <Form.Item name="customerType" className="custom-no-margin">
                                    <Select
                                        showSearch
                                        className="custom-select custom-placeholder-text"
                                        value={formValues.customerType}
                                        onBlur={() => {
                                            form.validateFields(['customerType']);
                                        }}
                                        onChange={(value) => {
                                            handleChange('customerType', value);
                                            // When customer type is "Retail", set approvalStatus to "Pending"
                                            if (value === 'Retail') {
                                                handleChange('approvalStatus', '0'); // Automatically set "Pending" for Retail
                                            }
                                            // Optionally handle other customer type changes here if needed
                                        }}
                                    >
                                        <Option value="Trade" key="trade">
                                            Trade
                                        </Option>
                                        <Option value="Retail" key="retail">
                                            Retail
                                        </Option>
                                        <Option value="Dealer" key="dealer">
                                            Dealer
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Title className="customer-id summary-dropdown" level={1}>
                                    Approval Status
                                </Title>
                                <Form.Item
                                    className="custom-no-margin"
                                    name="approvalStatus"
                                    rules={[{ required: true, message: 'This field is required.' }]}
                                >
                                    <Select
                                        showSearch
                                        className="custom-select custom-placeholder-text"
                                        value={formValues.approvalStatus}
                                        onChange={(value) => handleChange('approvalStatus', value)} // Handle status change
                                    >
                                        {/* Conditionally render options based on customerType */}
                                        {formValues.customerType == 'Retail' ? (
                                            <>
                                                <Option value="0">Pending</Option>
                                                <Option value="2">Rejected</Option>
                                            </>
                                        ) : (
                                            <>
                                                <Option value="0">Pending</Option>
                                                <Option value="1">Approved</Option>
                                                <Option value="2">Rejected</Option>
                                            </>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Title className="customer-id summary-dropdown" level={1}>
                                    Reason
                                </Title>
                                <Form.Item
                                    className="custom-no-margin"
                                    name="reason"
                                    rules={[{ required: true, message: 'This field is required.' }]}
                                >
                                    <Select
                                        showSearch
                                        className="custom-select custom-placeholder-text"
                                        onBlur={() => {
                                            form.validateFields(['reason']);
                                        }}
                                        value={formValues.reason}
                                        onChange={(value) => handleChange('reason', value)}
                                    >
                                        {unApprovalStatus.map((item) => {
                                            return (
                                                <Option value={item.id} key={item.id}>
                                                    {item.reason}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Title className="customer-id summary-dropdown" level={1}>
                                    Comment
                                </Title>
                                <Form.Item
                                    className="custom-no-margin"
                                    name="otherReason"
                                    rules={[
                                        {
                                            required: formValues.reason == 6,
                                            message: 'This field is required.', // Only require if 'Other reason' is selected
                                        },
                                    ]}
                                >
                                    <Input
                                        className="custom-no-margin"
                                        placeholder="Comment"
                                        style={{ borderRadius: '0px' }}
                                        disabled={formValues.reason != 6} // Disable if the reason is not 'Other reason'
                                        value={formValues.otherReason} // Bind the 'otherReason' instead of 'comment'
                                        onChange={(e) => handleChange('otherReason', e.target.value)} // Update the 'otherReason' field
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <hr className="hr-line" />
                        <Row className="rebate-edit-modal">
                            <Col className="gutter-row new-footer-buttons rebate-edit-modal" span={8}>
                                <div>
                                    <Space>
                                        <CustomizedButton className="reset-btn" onClick={handleCancel}>
                                            CANCEL
                                        </CustomizedButton>
                                        <CustomizedButton
                                            type="primary"
                                            htmlType="submit"
                                            className="addpart-btn apply-btn"
                                        >
                                            UPDATE
                                        </CustomizedButton>
                                    </Space>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
            {!rebateDetail ? (
                <>
                    <GetCustomerUnapprovalReason />
                    <GetCustomerApprovalStatus />
                    <GetCustomerTypes />
                </>
            ) : (
                <></>
            )}
        </>
    );
};
export default RebateManagementEditt;
