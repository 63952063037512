import { useState } from 'react';
import { commonSorter, formatDate, render } from '../../../Constants/constants';
import { absolute, formatCurrency, formatPercentage } from '../../../Constants/calculations';

const useGetPartsRebateDetailTableColumns = (selectedTableColumns = [], rebateDetail) => {
    const additionalColumns = [
        ...(rebateDetail
            ? [
                  {
                      title: 'Customer DMS ID',
                      dataIndex: 'customerId',
                      key: 'customerId',
                      align: 'left',
                      width: '134px',
                      className: 'table-sorter font',
                      render: render,
                      sorter: commonSorter('customerId'),
                  },
                  {
                      title: 'Customer Name',
                      dataIndex: 'customerName',
                      key: 'customerName',
                      align: 'left',
                      width: '162px',
                      className: 'table-sorter font',
                      render: render,
                      sorter: commonSorter('customerName'),
                  },
              ]
            : []),
        {
            title: 'Part Number',
            dataIndex: 'partNumber',
            key: 'partNumber',
            align: 'left',
            width: '149px',
            className: 'table-sorter font',
            render: render,
            sorter: commonSorter('partNumber'),
        },
        {
            title: 'Part Description',
            dataIndex: 'description',
            key: 'partDescription',
            align: 'left',
            width: '211px',
            className: 'table-sorter font',
            render: render,
            sorter: commonSorter('description'),
        },
        {
            title: 'Customer Type',
            dataIndex: 'customerType',
            key: 'customerType',
            align: 'left',
            width: '127px',
            className: 'table-sorter font',
            render: render,
            sorter: commonSorter('customerType'),
        },
        {
            title: 'Quantity Sold',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'left',
            width: '101px',
            className: 'table-sorter font',
            render: render,
            sorter: commonSorter('quantity'),
        },
        {
            title: 'Quoted Price',
            dataIndex: 'unitSalePrice',
            key: 'quotedPrice',
            align: 'right',
            width: '109px',
            className: 'table-sorter font',
            render: (e, record) => {
                return formatCurrency(absolute(record.unitSalePrice));
            },
        },
        {
            title: 'Stock Order Price($)',
            dataIndex: 'dealerCost',
            key: 'dealerCost',
            align: 'right',
            width: '104px',
            className: 'table-sorter font',
            render: (e, record) => {
                return formatCurrency(absolute(record.dealerCost));
            },
        },
        {
            title: 'Gross Profit ($)',
            dataIndex: 'grossProfit',
            key: 'grossProfit',
            align: 'right',
            width: '109px',
            className: 'table-sorter font',
            render: (e, record) => {
                return formatCurrency(absolute(record.grossProfit));
            },
        },
        {
            title: 'Gross Profit (%)',
            dataIndex: 'grossProfitPercentage',
            key: 'grossProfitPercentage',
            align: 'left',
            width: '112px',
            sorter: commonSorter('grossProfitPercentage'),
            className: 'table-sorter font',
            render: (e, record) => {
                return formatPercentage(absolute(record.grossProfitPercentage));
            },
        },
        {
            title: 'Rebate Per Item',
            dataIndex: 'rebatePerItem',
            key: 'rebate',
            align: 'right',
            width: '108px',
            className: 'table-sorter font',
            render: (e, record) => {
                return formatCurrency(absolute(record.rebate));
            },
        },
        {
            title: 'Effective Date ',
            dataIndex: 'effectiveStartDate',
            key: 'effectiveDate',
            align: 'right',
            width: '120px',
            className: 'table-sorter font',
            render: (e, record) => {
                return formatDate(record.invoiceDate);
            },
            sorter: commonSorter('effectiveStartDate'),
        },
    ];

    const defaultColumns = [
        {
            title: 'Invoice Date',
            dataIndex: 'invoiceDate',
            key: 'invoiceDate',
            align: 'left',
            fixed: 'left',
            width: '111px',
            className: 'table-sorter font',
            render: (text) => formatDate(text) || '-',
            sorter: commonSorter('invoiceDate'),
        },
        {
            title: 'Invoice Number',
            dataIndex: 'invoiceNumber',
            key: 'invoiceNumber',
            align: 'left',
            fixed: 'left',
            width: '130px',
            className: 'table-sorter font',
            render: render,
            sorter: commonSorter('invoiceNumber'),
        },
        ...(rebateDetail
            ? [
                  {
                      title: 'Part Number',
                      dataIndex: 'partNumber',
                      key: 'partNumber',
                      align: 'left',
                      fixed: 'left',
                      width: '149px',
                      className: 'table-sorter font',
                      render: render,
                      sorter: commonSorter('partNumber'),
                  },
                  {
                      title: 'Part Description',
                      dataIndex: 'description',
                      key: 'partDescription',
                      align: 'left',
                      fixed: 'left',
                      width: '211px',
                      className: 'table-sorter font',
                      render: render,
                      sorter: commonSorter('description'),
                  },
              ]
            : [
                  {
                      title: 'Dealer Code',
                      dataIndex: 'dealerCode',
                      key: 'dealerCode',
                      align: 'left',
                      fixed: 'left',
                      width: '111px',
                      className: 'table-sorter font',
                      render: render,
                      sorter: commonSorter('dealerCode'),
                  },
                  {
                      title: 'Dealership Name',
                      dataIndex: 'dealershipName',
                      key: 'dealershipName',
                      align: 'left',
                      fixed: 'left',
                      width: '227px',
                      className: 'table-sorter font',
                      render: render,
                      sorter: commonSorter('dealershipName'),
                  },
              ]),
    ];

    // const [columns, setColumns] = useState(defaultColumns);
    const [columns, setColumns] = useState([...defaultColumns, ...additionalColumns]);

    const setSelectedTableColumns = (updatedColumns = []) => {
        const values = [
            ...defaultColumns,
            ...additionalColumns.filter((addColumn) => updatedColumns.includes(addColumn.key)),
        ];

        setColumns(values);
    };

    return { columns, setSelectedTableColumns };
};

export default useGetPartsRebateDetailTableColumns;
