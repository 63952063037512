import React, { useEffect, useRef, useState } from 'react';
import { Select } from 'antd';
import { dealershipsCustomers } from '../../Services/userEndpoints';
import { CaretDownOutlined } from '@ant-design/icons';
import { webService } from '../../Services/web.service';
import { useIsAdmin } from '../../shared/hooks/role';

const { Option } = Select;

const CustomerIDSelect = ({
    records = [],
    selectedValue = undefined,
    form = undefined,
    name = undefined,
    setCustomerList = undefined,
    handleSelectChange,
    dlCode = undefined,
}) => {
    const isAdmin = useIsAdmin();
    const [value, setValue] = useState(undefined);
    const isListLoading = useRef(false);

    const [customerListAPIQueryParams, setCustomerListAPIQueryParams] = useState({
        isEffective: false,
        skip: 1,
        limit: 500,
    });

    const handlePartSelectScroll = (event) => {
        const { target } = event;

        const threshold = target.scrollTop + target.offsetHeight >= target.scrollHeight / 2;

        if (threshold) {
            setCustomerListAPIQueryParams({
                ...customerListAPIQueryParams,
                dlCode,
                skip: ++customerListAPIQueryParams.skip,
            });
        }
    };

    const handleSearch = (e) => {
        if (!isListLoading.current) {
            const value = e.target.value;
            setCustomerList([]);
            setCustomerListAPIQueryParams({
                ...customerListAPIQueryParams,
                dlCode,
                skip: 1,
                searchId: value.trim(),
            });
            isListLoading.current = true;
        }
    };

    const handleOnBlurCustomerSelect = () => {
        if (form && name) {
            form.validateFields([name]);
        }
        if (!value && dlCode) {
            setCustomerList([]);
            setCustomerListAPIQueryParams({
                ...customerListAPIQueryParams,
                dlCode,
                skip: 1,
                searchId: null,
            });
        }
    };

    const getCustomerList = async () => {
        try {
            webService
                .get(`${dealershipsCustomers}`, {
                    ...customerListAPIQueryParams,
                })
                .then((response) => {
                    const data = response?.data?.rows;
                    if (setCustomerList) {
                        // setCustomerList((prevOptions) => [...prevOptions, ...data]);
                        setCustomerList(data);
                    }
                });
        } catch (error) {
            console.error('Error Status', error);
        } finally {
            isListLoading.current = false;
        }
    };

    const handleChange = (e) => {
        setValue(e);
        handleSelectChange(e);
        if (form) {
            form.setFieldsValue({ [name]: e });
            form.validateFields(['name', 'customerId']);
        }
    };

    useEffect(() => {
        getCustomerList();
    }, [customerListAPIQueryParams]);

    useEffect(() => {
        setValue(selectedValue);
    }, [selectedValue]);

    useEffect(() => {
        if (isAdmin) {
            setCustomerListAPIQueryParams({
                ...customerListAPIQueryParams,
                dlCode: dlCode,
                skip: 1,
                limit: 500,
            });
        }
    }, [isAdmin, dlCode]);

    return (
        <>
            <Select
                showSearch
                className="custom-select custom-placeholder-text"
                onChange={handleChange}
                onBlur={handleOnBlurCustomerSelect}
                onKeyUp={handleSearch}
                filterOption={false}
                optionFilterProp="children"
                placeholder="Select DMS"
                value={value}
                onPopupScroll={handlePartSelectScroll}
                suffixIcon={<CaretDownOutlined className="dropdown-icon" />}
            >
                {records?.map((record) => {
                    return (
                        <Option key={record?.id} value={`${record?.id}`}>
                            {record?.id}
                        </Option>
                    );
                })}
            </Select>
        </>
    );
};

export default CustomerIDSelect;
