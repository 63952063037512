import React, { useState, useEffect, useRef } from 'react';
import { Tag, Pagination } from 'antd';
import '../../Assets/Styles/global-styles.css';
import CustomizedButton from '../../Components/Button';
import { EditOutlined, DeleteOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { commonSorter, renderDollar, renderPercentage, render } from '../../Constants/constants';
import CommonModal from '../../Components/Modal';
import CommonMessage from '../../Components/Message';
import './index.css';
import { v4 as uuidv4 } from 'uuid';
import { Form, Input, Table, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { edit } from '../../store/quoteItemsSlice';
import { useLocation, matchPath } from 'react-router-dom';
import { onHandleNumericValidation, requiredRule, zeroValueRule } from '../../Constants/formValidation';

const EditableTable = ({
    onDeleteRow,
    viewQuote,
    viewScreen,
    deleteRecord,
    setIsSaveEnabled,
    handleInputChanges,
    setHasInputChanges,
}) => {
    const quoteItemsStore = useSelector((state) => state.quoteItems);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [showSuccess, setShowSuccess] = useState(false);
    const [deleteItemIndex, setDeleteItemIndex] = useState(null);
    const [isDelete, setIsDelete] = useState(null);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [isSaveModalVisible, setisSaveModalVisible] = useState(false);
    const updateItemIndex = useRef(null);
    const location = useLocation();
    const [editingKey, setEditingKey] = useState('');
    const [partMsg, setPartMsg] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(15);
    const [data, setData] = useState();
    const editRecordIndex = useRef(null);
    const [unableToDelete, setUnableToDelete] = useState(false);
    const isEditing = (record) => record.key === editingKey;
    const originalData = quoteItemsStore;
    const dataRef = useRef();

    document.querySelectorAll('.tooltip').forEach((tooltip) => {
        tooltip.addEventListener('mouseenter', function () {
            const tooltipText = this.querySelector('.tooltiptext');
            tooltipText.scrollTop = 0; // Scroll to the top
        });
    });
    useEffect(() => {
        const dataWithIds = originalData?.map((item) => ({
            ...item,
            key: uuidv4(),
        }));
        setData(dataWithIds);
    }, [originalData]);

    useEffect(() => {
        dataRef.current = data;
    }, [data]);

    useEffect(() => {
        if (deleteItemIndex !== null && isDelete) {
            success(`Part ${partMsg} deleted.`);
            setShowSuccess(false);
            if (viewScreen) {
                deleteRecord(deleteItemIndex);
            } else {
                onDeleteRow(deleteItemIndex);
            }
            setIsDelete(null);
            setDeleteItemIndex(null);
        }
    }, [deleteItemIndex, isDelete]);

    const hidePopup = ['/quote/create'].some((path) => matchPath(path, location.pathname));
    const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
        let onInputEvent;
        if (!hidePopup) {
            onInputEvent = (e) => {
                handleInputChanges(e);
            };
        }
        return (
            <td {...restProps}>
                {editing ? (
                    <>
                        {dataIndex === 'quantity' ? (
                            <Form.Item
                                className="custom-nomargin"
                                name={dataIndex}
                                style={{
                                    marginTop: '-0px',
                                }}
                                rules={[requiredRule, zeroValueRule]}
                                onChange={onInputEvent}
                            >
                                <Input
                                    onBlur={() => {
                                        form.validateFields(['quantity']);
                                    }}
                                    onKeyPress={(e) => onHandleNumericValidation(e, form, 'quantity', false)}
                                    onChange={onInputEvent}
                                    placeholder="Enter Quantity"
                                    autoComplete="off"
                                />
                            </Form.Item>
                        ) : (
                            <Form.Item
                                className="custom-nomargin"
                                name={dataIndex}
                                style={{
                                    marginTop: '0px',
                                }}
                                placeholder="Enter Quoted Price"
                                rules={[requiredRule, zeroValueRule]}
                            >
                                <Input
                                    onBlur={() => {
                                        form.validateFields(['quotedSellPrice']);
                                    }}
                                    onKeyPress={(e) => onHandleNumericValidation(e, form, 'quotedSellPrice', true)}
                                    onChange={onInputEvent}
                                    autoComplete="off"
                                />
                            </Form.Item>
                        )}
                    </>
                ) : (
                    children
                )}
            </td>
        );
    };

    const success = (message) => {
        CommonMessage(
            'success',
            message,
            2,
            () => {
                setShowSuccess(false);
            },
            'custom-success-message'
        );
    };

    const showDeleteModal = (index, record) => {
        setPartMsg(record?.partNumber);
        setDeleteItemIndex(index);
        if (viewQuote?.length > 1 || quoteItemsStore?.length > 1) {
            setIsDeleteModalVisible(true);
        } else {
            setUnableToDelete(true);
        }
    };
    const hideDeleteModal = () => {
        setIsDeleteModalVisible(false);
        setDeleteItemIndex(null);
    };
    const handleDeleteItem = () => {
        setIsDeleteModalVisible(false);
        setIsDelete(true);
    };

    const handleUnableDelete = () => {
        setUnableToDelete(false);
    };
    const showUpdateModal = (record) => {
        form.validateFields()
            .then(() => {
                updateItemIndex.current = record.key;
                setisSaveModalVisible(true);
                setPartMsg(record?.partNumber);
            })
            .catch((errorInfo) => {
                setisSaveModalVisible(false);
            });
    };
    const handleCloseModal = () => {
        setisSaveModalVisible(false);
    };

    const editColumn = (record, index) => {
        form.setFieldsValue({
            partNumber: '',
            description: '',
            quantity: '',
            GPRecovery: '',
            handlingFee: '',
            totalRebate: '',
            stockOrderPrice: '',
            quotedSellPrice: '',
            minimumSellPrice: '',
            dealerGp: '',
            quoteCreator: '',
            ruleApplied: '',
            ...record,
        });
        setEditingKey(record.key);
        editRecordIndex.current = index;
    };
    const cancel = () => {
        setEditingKey('');
    };

    const handleUpdateSingleRow = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...dataRef.current];
            const index = newData.findIndex((item) => updateItemIndex.current === item.key);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                setEditingKey('');
                if (!hidePopup) {
                    setIsSaveEnabled(true);
                }
                setData(newData);
                dispatch(edit({ data: { ...item, ...row }, index: editRecordIndex.current }));
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
        setisSaveModalVisible(false);
        if (viewScreen) {
            setIsSaveEnabled(true);
        }
        success('Parts information edited');
        if (!hidePopup) {
            setHasInputChanges(false);
        }
    };

    const emptyColumns = (data, columns) => {
        return data?.map((item) => {
            const newItem = { ...item };
            columns.forEach((column) => {
                if (column.dataIndex) {
                    const dataIndex = column.dataIndex;
                    if (newItem[dataIndex] === null || newItem[dataIndex] === undefined) {
                        newItem[dataIndex] = '-';
                    }
                }
            });
            return newItem;
        });
    };
    const viewTagClassName = (record) => {
        return record?.totalRebate > 0 ? 'quote-status' : 'quote-norebate';
    };

    const viewRebateStatus = (record) => {
        return record?.totalRebate > 0 ? 'Rebate Available' : 'No Rebate Available';
    };

    const columns = [
        {
            title: 'Part Number',
            width: 135,
            dataIndex: 'partNumber',
            key: 'partNumber',
            sorter: commonSorter('partNumber'),
            fixed: 'left',
            className: 'table-sorter font',
            render: render,
        },
        {
            title: 'Description',
            width: 200,
            dataIndex: 'description',
            sorter: commonSorter('description'),
            fixed: 'left',
            className: 'table-sorter font',
            render: render,
        },
        {
            title: 'QTY',
            width: 160,
            dataIndex: 'quantity',
            fixed: 'left',
            align: 'center',
            sorter: commonSorter('quantity'),
            className: 'table-sorter font',
            editable: true,
        },
        {
            title: 'Quoted Price',
            width: 160,
            dataIndex: 'quotedSellPrice',
            fixed: 'left',
            align: 'left',
            sorter: commonSorter('quotedSellPrice'),
            className: 'table-sorter font',
            editable: true,
            render: renderDollar,
        },
        {
            title: 'Total Quoted Price',
            width: 140,
            dataIndex: 'TotalQuotedPrice',
            align: 'right',
            sorter: commonSorter('TotalQuotedPrice'),
            className: 'table-sorter font',
            render: renderDollar,
        },
        {
            title: 'Base Price',
            width: 108,
            dataIndex: 'minimumSellPrice',
            align: 'right',
            sorter: commonSorter('minimumSellPrice'),
            className: 'table-sorter font',
            render: (text) => (text !== '-' ? `$${text}` : '-'),
        },
        {
            title: 'Stock Order Price',
            width: 150,
            dataIndex: 'stockOrderPrice',
            align: 'right',
            sorter: commonSorter('stockOrderPrice'),
            className: 'table-sorter font',
            render: renderDollar,
        },
        {
            title: 'GP Recovery',
            width: 111,
            dataIndex: 'GPRecovery',
            align: 'right',
            sorter: commonSorter('GPRecovery'),
            className: 'table-sorter font',
            render: renderDollar,
        },
        {
            title: 'Handling Fee ',
            width: 112,
            dataIndex: 'handlingFee',
            align: 'right',
            sorter: commonSorter('handlingFee'),
            className: 'table-sorter font',
            render: renderDollar,
        },
        {
            title: 'Total Rebate ',
            width: 100,
            dataIndex: 'totalRebate',
            align: 'right',
            sorter: commonSorter('totalRebate'),
            className: 'table-sorter font',
            render: renderDollar,
        },
        {
            title: 'Dealer GP($) ',
            width: 100,
            dataIndex: 'dealerGP',
            align: 'center',
            sorter: commonSorter('dealerGP'),
            className: 'table-sorter font',
            render: renderDollar,
        },
        {
            title: 'Dealer GP(%) ',
            width: 100,
            dataIndex: 'dealerGpPercentage',
            align: 'center',
            sorter: commonSorter('dealerGpPercentage'),
            className: 'table-sorter font',
            render: renderPercentage,
        },
        {
            title: 'Rebate Status',
            key: 'rebateStatus',
            width: 175,
            align: 'center',
            className: 'table-sorter font',
            render: (text, record) => (
                <span>
                    <Tag className={viewTagClassName(record)}>{viewRebateStatus(record)}</Tag>
                </span>
            ),
        },
        {
            title: 'Rule Applied',
            width: 150,
            dataIndex: 'ruleApplied',
            align: 'left',
            className: 'table-sorter font',
            render: (text, record) => {
                return (
                    <div className="tooltip">
                        <p className="elipse">{record.ruleApplied} </p>
                        {record.ruleApplied !== '-' && <span className="tooltiptext">{record.ruleApplied}</span>}
                    </div>
                );
            },
        },
        {
            title: 'Actions',
            dataIndex: 'Actions',
            fixed: 'right',
            width: '85px',
            key: 'action',
            align: 'center',
            className: 'tableHeading',
            render: (_, record, index) => {
                const editable = isEditing(record);

                return editable ? (
                    <span className="quote-action-btn">
                        <CustomizedButton type="danger">
                            <Typography.Link onClick={cancel}>
                                <CloseOutlined className="closeIcon" />
                            </Typography.Link>
                        </CustomizedButton>
                        <CustomizedButton type="danger">
                            <Typography.Link onClick={() => showUpdateModal(record)}>
                                <CheckOutlined className="checkIcon" />
                            </Typography.Link>
                        </CustomizedButton>
                    </span>
                ) : (
                    <>
                        <span className="quote-action-btn">
                            <CustomizedButton type="danger">
                                <Typography.Link onClick={() => editColumn(record, index)}>
                                    <EditOutlined className="quote-edit-btn quote-edit-mar" />
                                </Typography.Link>
                            </CustomizedButton>
                            <CustomizedButton
                                type="danger"
                                icon={<DeleteOutlined className="quote-delete-btn quote-delete-mar" />}
                                onClick={() => {
                                    showDeleteModal(index, record);
                                }}
                            ></CustomizedButton>
                        </span>
                    </>
                );
            },
        },
    ];
    const mergedColumns = columns?.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    const onChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const startIndex = currentPage;
    const endIndex = startIndex + pageSize;
    const tableData = emptyColumns(data?.slice(startIndex, endIndex), columns);

    return (
        <>
            <Form form={form} component={false}>
                <Table
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    bordered
                    dataSource={tableData}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    pagination={false}
                    showSorterTooltip={false}
                    scroll={{
                        x: 1300,
                    }}
                />
            </Form>
            <CommonModal
                visible={isDeleteModalVisible}
                onCancel={hideDeleteModal}
                onConfirm={handleDeleteItem}
                title="Delete Part"
                showDeleteButton={true}
            >
                <p>Do you want to delete the part {partMsg}? </p>
            </CommonModal>
            <CommonModal
                visible={unableToDelete}
                onConfirm={handleUnableDelete}
                title={<span className="unable-delete">Unable To Delete</span>}
                showUnable={true}
            >
                <p className="export-message">
                    You cannot delete this entry because at least one record is required to proceed
                </p>
            </CommonModal>
            <CommonModal
                visible={isSaveModalVisible}
                onCancel={handleCloseModal}
                onConfirm={handleUpdateSingleRow}
                title="Update Changes"
                showUpdateButton={true}
            >
                <p>Update the changes done in part {partMsg}?</p>
            </CommonModal>
            <div className="quote-pagination">
                <Pagination
                    defaultPageSize={15}
                    pageSizeOptions={['15', '25', '50', '100']}
                    showSizeChanger={true}
                    onChange={onChange}
                    total={quoteItemsStore?.length}
                    defaultCurrent={1}
                    className="custom-pagination"
                />
            </div>
            {showSuccess && <CommonMessage type="success" />}
        </>
    );
};
export default EditableTable;
