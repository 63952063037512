import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { webService } from '../../Services/web.service';
import { auth } from '../../Services/auth.service';

const Login = () => {
    const { token } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            auth.login(token);
            navigate('/');
        }
    }, [token]);

    return <></>;
};

export default Login;
