import React, { useState, useEffect, useRef } from 'react';
import CustomizedButton from '../../Components/Button';
import { Typography, Form, Col, Select, Row, Input } from 'antd';
import '../../Assets/Styles/global-styles.css';
import { CaretDownOutlined, SearchOutlined } from '@ant-design/icons';
import AllQuoteList from './allQuote';
import { allQuotesListApi, quoteNumberApi } from '../../Services/userEndpoints';
import { paginationConfig } from '../../Components/Pagination';
import { useNavigate } from 'react-router-dom';
import '../../Assets/Styles/fonts.css';
import CustomerSelect from '../../Components/CustomerSelect/CustomerSelect';
import DealerSelect from '../../Components/DealerSelect/DealerSelect';
import CustomerIDSelect from '../../Components/CustomerSelect/CustomerIDSelect';
import { webService } from '../../Services/web.service';
import store from '../../store/store';
import { isExecuting } from '../../store/isHTTPRequestSlice';
import { RangeDateRangePicker } from '../../Components/Datepicker';
import moment from 'moment/moment';
import dayjs from 'dayjs';
const { Title } = Typography;
const { Option } = Select;

const QuoteCalculator = () => {
    const navigate = useNavigate();
    const [isAnyDropdownSelected, setIsAnyDropdownSelected] = useState(false);
    const [filteredQuotes, setFilteredQuotes] = useState([]);
    const [quoteStatusValue, setQuoteStatusValue] = useState([]);
    const [dealerList, setDealerList] = useState([]);
    const [filteredDealerList, setFilteredDealerList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [customerFilters, setCustomerFilters] = useState([]);
    const [quoteNumberFilter, setQuoteNumberFilter] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const defaultDateRange = [dayjs(moment().subtract(7, 'days').toISOString()), dayjs(moment().toISOString())];
    const listFilter = useRef({});

    const [listFilterState, setListFilterState] = useState({
        pageNumber: 1,
        pageSize: paginationConfig?.defaultPageSize,
        dateRange: defaultDateRange,
        dateStart: moment(new Date(defaultDateRange[0])).format('YYYY-MM-DD'),
        dateEnd: moment(new Date(defaultDateRange[1])).format('YYYY-MM-DD'),
    });
    const [searchState, setSearchState] = useState({
        dealer: {
            onSearch: false,
            dropdownOpen: false,
        },
        customerName: {
            onSearch: false,
            dropdownOpen: false,
        },
        customerId: {
            onSearch: false,
            dropdownOpen: false,
        },
        quoteNumber: {
            onSearch: false,
            dropdownOpen: false,
        },
    });

    const handleCreateNewQuote = () => {
        navigate('/quote/create', { state: { customerFilters } });
    };

    const handlePageChange = (page, size) => {
        const data = {
            ...listFilterState,
            pageNumber: page,
            pageSize: size,
        };

        setListFilterState(data);
        listFilter.current = data;

        getList();
    };

    const handleFilterApply = async () => {
        const data = {
            ...listFilterState,
            pageNumber: 1,
        };

        setListFilterState(data);
        listFilter.current = data;

        getList();
    };

    const handleClearFilter = () => {
        const data = {
            ...listFilterState,
            pageNumber: 1,
            quoteNumber: undefined,
            customerId: undefined,
            dlCode: undefined,
            dateRange: defaultDateRange,
            dateStart: moment(new Date(defaultDateRange[0])).format('YYYY-MM-DD'),
            dateEnd: moment(new Date(defaultDateRange[1])).format('YYYY-MM-DD'),
        };

        setListFilterState(data);
        listFilter.current = data;
        setIsAnyDropdownSelected(false);
    };

    useEffect(() => {
        getQuoteNumberFilters();
    }, []);

    const getList = async () => {
        try {
            let { dateRange, ...rest } = { ...listFilter.current };
            store.dispatch(isExecuting(true));
            webService
                .get(allQuotesListApi, rest)
                .then((response) => {
                    const quotesData = response?.data?.rows || [];
                    const totalAllRecords = response?.data?.total || [];
                    setFilteredQuotes(quotesData);
                    setTotalRecords(totalAllRecords);
                })
                .finally(() => {
                    store.dispatch(isExecuting(false));
                })
                .catch(() => {
                    setFilteredQuotes([]);
                    setTotalRecords([]);
                });
        } catch (error) {
            console.error('Error fetching quoteslist:', error);
        }
    };

    const handleSort = async (columnKey, sortOrder) => {
        listFilter.current = {
            ...listFilter.current,
            orderBy: `${columnKey}:${sortOrder}`,
            pageNumber: 1,
        };
        getList();
    };

    const getQuoteNumberFilters = async () => {
        try {
            webService
                .get(quoteNumberApi)
                .then((response) => {
                    const getQuoteNumber = response?.data || [];
                    setQuoteNumberFilter(getQuoteNumber);
                })
                .catch(() => {
                    setQuoteNumberFilter([]);
                });
        } catch (error) {
            console.error('Error fetching QuoteNumber:', error);
        }
    };

    const handleOnChange = (name, value) => {
        const data = {
            ...listFilterState,
            [name]: value,
        };
        if (name == 'dlCode') {
            data.customerId = undefined;
        }
        setListFilterState(data);
        listFilter.current = data;

        const isAnySelected = Object.values(data).some((val) => val !== undefined && val !== '');
        setIsAnyDropdownSelected(isAnySelected);
    };

    const handleSearch = (key, value) => {
        setSearchState((prevState) => ({
            ...prevState,
            [key]: {
                ...prevState[key],
                onSearch: value !== '' || prevState[key].dropdownOpen,
            },
        }));
    };

    const handleDropdownVisibleChange = (key, open) => {
        setSearchState((prevState) => ({
            ...prevState,
            [key]: {
                ...prevState[key],
                dropdownOpen: open,
                onSearch: open || false,
            },
        }));
    };
    const handleDateChange = (value) => {
        const values = {
            ...listFilterState,
            dateRange: value,
            dateStart: moment(new Date(value[0])).format('YYYY-MM-DD'),
            dateEnd: moment(new Date(value[1])).format('YYYY-MM-DD'),
        };
        setListFilterState(values);
    };

    return (
        <div>
            <div className="quote-container">
                <span>
                    <Title level={4} className="quoteheader">
                        Quote Calculator
                    </Title>
                    <Title level={5} className="quote-info">
                        Input information to generate the quote
                    </Title>
                </span>
                <div className="generate-container">
                    <CustomizedButton type="primary" className="create-new-quote" onClick={handleCreateNewQuote}>
                        Create new quote
                    </CustomizedButton>
                </div>
            </div>
            <div className="main-container">
                <div className="box">
                    <div className="row-col-container">
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={6}>
                                <Title className="customer-id" style={{ width: 'max-content' }} level={5}>
                                    Dealer
                                </Title>
                                <Form.Item>
                                    <DealerSelect
                                        records={filteredDealerList}
                                        setFilteredDealerList={setFilteredDealerList}
                                        dealerList={dealerList}
                                        setDealerList={setDealerList}
                                        handleSelectChange={(value) => {
                                            handleOnChange('dlCode', value);
                                        }}
                                        selectedValue={listFilterState.dlCode}
                                        searchState={searchState}
                                        onSearch={handleSearch}
                                        handleDropdownVisibleChange={handleDropdownVisibleChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={6}>
                                <Title className="customer-id" level={5}>
                                    Customer Name
                                </Title>
                                {listFilterState.dlCode ? (
                                    <Form.Item>
                                        <CustomerSelect
                                            records={customerList}
                                            dlCode={listFilterState.dlCode}
                                            setCustomerList={listFilterState.dlCode ? setCustomerList : []}
                                            handleSelectChange={(value) => {
                                                handleOnChange('customerId', value);
                                            }}
                                            selectedValue={listFilterState.customerId}
                                        />
                                    </Form.Item>
                                ) : (
                                    <Form.Item>
                                        <Input className="input-style" disabled placeholder="Select Customer" />
                                    </Form.Item>
                                )}
                            </Col>
                            <Col className="gutter-row" span={6}>
                                <Title className="customer-id" level={5}>
                                    DMS ID
                                </Title>
                                {listFilterState.dlCode ? (
                                    <Form.Item>
                                        <CustomerIDSelect
                                            records={customerList}
                                            dlCode={listFilterState.dlCode}
                                            setCustomerList={listFilterState.dlCode ? setCustomerList : []}
                                            handleSelectChange={(value) => {
                                                handleOnChange('customerId', value);
                                            }}
                                            selectedValue={listFilterState.customerId}
                                        />
                                    </Form.Item>
                                ) : (
                                    <Form.Item>
                                        <Input className="input-style" disabled placeholder="Select DMS" />
                                    </Form.Item>
                                )}
                            </Col>
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={8}>
                                <Title className="customer-id" level={5}>
                                    Quote Period
                                </Title>
                                <Form.Item className="summary-report">
                                    <RangeDateRangePicker
                                        selectedDates={listFilterState.dateRange}
                                        setSelectedDates={handleDateChange}
                                        format="DD/MM/YYYY"
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={6}>
                                <Title className="customer-id" level={5}>
                                    Reference
                                </Title>
                                <Form.Item>
                                    <Select
                                        showSearch
                                        className="custom-select custom-placeholder-text"
                                        value={listFilterState.quoteNumber}
                                        onChange={(value) => handleOnChange('quoteNumber', value)}
                                        onSearch={(value) => handleSearch('quoteNumber', value)}
                                        onDropdownVisibleChange={(open) =>
                                            handleDropdownVisibleChange('quoteNumber', open)
                                        }
                                        suffixIcon={
                                            searchState.quoteNumber.onSearch ? (
                                                <SearchOutlined className="dropdown-icon" />
                                            ) : (
                                                <CaretDownOutlined className="dropdown-icon" />
                                            )
                                        }
                                        placeholder="Enter Quote Reference"
                                        title={false}
                                    >
                                        {quoteNumberFilter?.map((item) => (
                                            <Option key={item.id} value={item?.quoteNumber}>
                                                {item?.quoteNumber}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div className="hr-container">
                        <div className="quotes-all-list-container">
                            <CustomizedButton className="reset-btn" onClick={handleClearFilter}>
                                Reset
                            </CustomizedButton>
                            <CustomizedButton
                                type="primary"
                                className={isAnyDropdownSelected ? 'apply-btn' : 'apply-btn-disabled'}
                                onClick={handleFilterApply}
                            >
                                Apply
                            </CustomizedButton>
                        </div>
                        <hr className="hr-line" />
                    </div>
                    <div>
                        <Title className="quote-items all-quote-list" level={4}>
                            ALL QUOTES LIST
                        </Title>
                        <div className="total-container">
                            <div className="total-text">
                                Total:
                                <span className="item">
                                    {totalRecords > 0 && totalRecords < 10 ? `0${totalRecords}` : totalRecords} item
                                    {totalRecords > 1 ? 's' : ''}
                                </span>
                            </div>
                        </div>
                        <div className="table-container">
                            {filteredQuotes.length >= 0 && (
                                <AllQuoteList
                                    dataSource={filteredQuotes}
                                    getAllList={getList}
                                    quoteStatus={quoteStatusValue}
                                    onPageChange={handlePageChange}
                                    onSort={handleSort}
                                    totalRecords={totalRecords}
                                    currentPage={listFilterState.pageNumber}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default QuoteCalculator;
