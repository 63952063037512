import React, { useEffect, useState, useRef, useContext } from 'react';
import '../../Assets/Styles/global-styles.css';
import CustomizedButton from '../../Components/Button';
import dayjs from 'dayjs';
import { Typography, Select, Form, Col, Row, Input, Pagination, Space, Checkbox, DatePicker, Button } from 'antd';
import moment from 'moment';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import CommonTable from '../../Components/Table';
import ExportIcon from '../../Assets/Icons/ExportIcon.svg';
import ImportFile from '../../Assets/Icons/ImportFile.svg';
import { commonSorter } from '../../Constants/constants';
import { getParts, addPart, partsExport, importRebates } from '../../Services/userEndpoints';
import { useNavigate } from 'react-router-dom';
import {
    getMaxCharsRule,
    noDecimalsRule,
    onHandleNumericValidation,
    onHandleNumericWithDecimalsValidation,
    requiredRule,
    zeroValueRule,
} from '../../Constants/formValidation';
import { saveAs } from 'file-saver';
import ImportFiles from './importFiles';
import { QuoteContext } from '../Quote-Calculator/quoteContext';
import CommonMessage, { message } from '../../Components/Message';
import CommonModal from '../../Components/Modal';
import PartNumberSelect from '../../Components/PartNumberSelect/PartNumberSelect';
import { webService } from '../../Services/web.service';
import { convertExcelDateToJSDate } from '../../Constants/dateConverter';
import store from '../../store/store';
import { isExecuting } from '../../store/isHTTPRequestSlice';

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const RebateManagement = () => {
    const [form] = Form.useForm();
    const [selectedPartNumber, setSelectedPartNumber] = useState('');
    const [partsNumbersData, setPartNumbersData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const { setEditPartId } = useContext(QuoteContext);

    const [search, setSearch] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [total, setTotal] = useState(0);
    const [exportModalVisible, setExportModalVisible] = useState(false);
    const [formData, setFormData] = useState({
        handlingFeeWST: 20,
        handlingFeeSTD: 10,
        quotedSellPrice: undefined,
        effectivePeriod: undefined,
        maxRebatePrice: undefined,
    });
    const fileInputRef = useRef(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(true);
    const [importedFile, setImportedFile] = useState(null);
    const [isImportPartsModalVisible, setIsImportPartsModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [deleteItemIndex, setDeleteItemIndex] = useState(null);
    const [newItemAdded, setNewItemAdded] = useState(false);
    const [partid, setPartId] = useState();
    const navigate = useNavigate();
    const [partDescriptionValue, setPartDescriptionVaue] = useState(undefined);
    const debounceTimeout = useRef(null);
    const deleteItemId = useRef(null);
    const [isCurrentSelected, setIsCurrentSelected] = useState(false);
    const [listFilter, setListFilter] = useState({});
    const [isAnyFilterSelected, setIsAnyFilterSelected] = useState(true);
    const [filterType, setFilterType] = useState('all');

    const showDeleteModal = (index, record) => {
        setDeleteItemIndex(record.id);
        deleteItemId.current = record.id;
        setIsDeleteModalVisible(true);
    };
    const hideDeleteModal = () => {
        setIsDeleteModalVisible(false);
    };

    const handleDeleteItem = async () => {
        try {
            webService
                .destroy(`${addPart}${deleteItemId.current}`)
                .then((response) => {
                    message.success(response?.message);
                    getTableList();
                })
                .catch(() => {})
                .finally(() => {
                    setIsDeleteModalVisible(false);
                });
        } catch (error) {
            console.error('Error making API call:', error);
            setIsDeleteModalVisible(false);
        }
    };

    const handleClickExport = (record) => {
        setExportModalVisible(true);
    };
    const onCancelModal = () => {
        setExportModalVisible(false);
    };

    const [selectedButton, setSelectedButton] = useState(''); // Could be 'all', 'current', or 'expired'
    // Step 2: Define the styles for each button dynamically
    const buttonStyle = (buttonName) => ({
        backgroundColor: selectedButton === buttonName ? '#002c5f' : '', // If button is selected, apply color
        color: selectedButton === buttonName ? 'white' : '#002c5f', // Text color is white when selected, else #002c5f
    });

    const handleConfirmExport = async () => {
        try {
            webService.get(partsExport).then((response) => {
                const { fileName, buffer } = response.data;
                const arrayBuffer = new Uint8Array(buffer.data).buffer;
                const blob = new Blob([arrayBuffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                saveAs(blob, fileName);
                message.success(`Part basket list is exported.`);
                setExportModalVisible(false);
                message.success(`Part basket list is exported.`);
            });
        } catch (error) {
            console.error('Error Download Excel:', error);
        }
    };

    const handlePartNumber = (e) => {
        const values = e.split('-');
        const selectedPartNumber = values[1];
        setSelectedPartNumber(selectedPartNumber);
        setPartDescriptionVaue(values[2]);
        form.setFieldsValue({ partNumber: selectedPartNumber });

        const part = partsNumbersData.find((p) => p.partNumber === selectedPartNumber);

        if (part) {
            setPartId(part.id);
            form.setFieldsValue({
                description: part.description,
                listPrice: part.listPrice !== null ? part.listPrice : 0,
                stockOrderPrice: part.stockPrice,
            });
        } else {
            form.setFieldsValue({
                description: '-',
                listPrice: '-',
                stockOrderPrice: '-',
            });
        }
    };

    const handleonChange = (fieldName, event) => {
        const formDataUpdate =
            fieldName === 'quotedSellPrice'
                ? form.setFieldsValue({
                      quotedSellPrice: event.target.value,
                  })
                : fieldName === 'handlingFeeSTD'
                  ? form.setFieldsValue({
                        handlingFeeSTD: event.target.value,
                    })
                  : fieldName === 'handlingFeeWST'
                    ? form.setFieldsValue({
                          handlingFeeWST: event.target.value,
                      })
                    : fieldName === 'maxRebatePrice'
                      ? form.setFieldsValue({
                            maxRebatePrice: event.target.value,
                        })
                      : {
                            ...formData,
                        };

        setFormData(formDataUpdate);
    };

    const handleEditIconClick = (record) => {
        setEditPartId(record.id);
        navigate(`/rebate/management/edit/${record.id}`, {
            state: { record },
        });
    };

    const handleTableChange = (page, size) => {
        setCurrentPage(page);
        setPageSize(size);
    };

    const render = (text) => text || '-';
    const columns = [
        {
            title: 'Part Number',
            dataIndex: 'partNumber',
            key: 'partNumber',
            align: 'left',
            width: '143px',
            fixed: 'left',
            sorter: commonSorter('partNumber'),
            className: 'table-sorter font',
            render: render,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            align: 'left',
            width: '242px',
            fixed: 'left',
            sorter: commonSorter('description'),
            className: 'table-sorter font',
            render: render,
        },
        {
            title: 'List Price',
            dataIndex: 'listPrice',
            key: 'listPrice',
            align: 'left',
            width: '111px',
            sorter: commonSorter('listPrice'),
            className: 'table-sorter font',
            render: render,
        },
        {
            title: 'Stock Order Price',
            dataIndex: 'stockPrice',
            key: 'stockPrice',
            align: 'left',
            width: '125px',
            sorter: commonSorter('stockPrice'),
            className: 'table-sorter font',
            render: render,
        },
        {
            title: 'Base Price',
            dataIndex: 'minimumSellPrice',
            key: 'minimumSellPrice',
            align: 'left',
            width: '123px',
            sorter: commonSorter('minimumSellPrice'),
            className: 'table-sorter font',
            render: render,
        },
        {
            title: 'Max Rebate Price',
            dataIndex: 'maxRebatePrice',
            key: 'maxRebatePrice',
            align: 'left',
            width: '132px',
            sorter: commonSorter('maxRebatePrice'),
            className: 'table-sorter font',
            render: render,
        },
        {
            title: 'Handling Fee(STD)',
            dataIndex: 'standardHandlingFeePercentage',
            key: 'standardHandlingFeePercentage',
            align: 'left',
            width: '108px',
            sorter: commonSorter('standardHandlingFeePercentage'),
            className: 'table-sorter font',
            render: render,
        },
        {
            title: 'Handling Fee(WST)',
            dataIndex: 'westernHandlingFeePercentage',
            key: 'westernHandlingFeePercentage',
            align: 'left',
            width: '110px',
            sorter: commonSorter('westernHandlingFeePercentage'),
            className: 'table-sorter font',
            render: render,
        },
        {
            title: 'Effective Date',
            dataIndex: 'effectiveStartDate',
            key: 'effectiveStartDate',
            align: 'left',
            width: '112px',
            sorter: commonSorter('effectiveStartDate'),
            className: 'table-sorter font',
            render: (text, record) => `${record.effectiveStartDate}-${record.effectiveEndDate}`,
        },
        {
            title: 'Expiry Date',
            dataIndex: 'effectiveEndDate',
            key: 'effectiveEndDate',
            align: 'left',
            width: '161px',
            sorter: commonSorter('effectiveEndDate'),
            className: 'table-sorter font',
            render: render,
        },
        {
            title: 'Actions',
            dataIndex: 'Actions',
            fixed: 'right',
            width: '85px',
            key: 'action',
            align: 'center',
            className: 'tableHeading',
            render: (_, record, index) => {
                return (
                    <>
                        <span className="quote-action-btn">
                            <CustomizedButton type="danger">
                                <Typography.Link onClick={() => handleEditIconClick(record)}>
                                    <EditOutlined className="quote-edit-btn quote-edit-mar" />
                                </Typography.Link>
                            </CustomizedButton>
                            <CustomizedButton
                                type="danger"
                                icon={
                                    <DeleteOutlined
                                        className="quote-delete-btn quote-delete-mar"
                                        onClick={() => {
                                            showDeleteModal(index, record);
                                        }}
                                    />
                                }
                            ></CustomizedButton>
                        </span>
                    </>
                );
            },
        },
    ];

    const convertToPayload = (data) => {
        return {
            items: data.map((item) => {
                const effDate = moment(convertExcelDateToJSDate(item.effectiveDate)).format('YYYY-MM-DD');
                return {
                    partNumber: item.partNumber.toString(),
                    effectiveDate: effDate.toString(),
                    expiryDate:
                        item.expiryDate.toLowerCase() === 'current'
                            ? '9999-12-31'
                            : item.expiryDate.format('YYYY-MM-DD').toString(),
                    basePrice: item.basePrice,
                    stockOrderPrice: item.stockPrice,
                    maxRebatePrice: item.maxRebatePrice,
                    standardHandlingFeePercentage: item.handelingFeeStd,
                    westernHandlingFeePercentage: item.handelingFeeWst,
                };
            }),
        };
    };

    const handleImportFile = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        setImportedFile(e.target.files[0]);
        setIsImportPartsModalVisible(true);
    };

    const handleHideImportPartsModal = () => {
        setIsImportPartsModalVisible(false);
        fileInputRef.current.value = '';
    };

    const handleGetImportedRecords = (data) => {
        const payload = convertToPayload(data);
        importRebate(payload);
    };

    const importRebate = async (payload) => {
        webService
            .post(importRebates, payload)
            .then((response) => {
                getTableList();
            })
            .catch(() => {});
    };

    useEffect(() => {
        isAnyFilterSelectedFun();
    }, [listFilter]);

    const isAnyFilterSelectedFun = () => {
        const hasEffectivePeriod =
            listFilter.effectivePeriod && listFilter.effectivePeriod[0] && listFilter.effectivePeriod[1];

        const hasBasePrice = !!listFilter.basePrice;
        const hasMaxRebatePrice = !!listFilter.maxRebatePrice;

        setIsAnyFilterSelected(hasEffectivePeriod && hasBasePrice && hasMaxRebatePrice);
    };
    const handleFilterChange = (name, value) => {
        let values = {};
        values = {
            ...listFilter,
            [name]: value,
        };
        setListFilter(values);
    };

    const handleSubmit = async (values) => {
        setListFilter({});
        const { effectivePeriod, ...restValues } = values;
        const effectiveStartDate = dayjs(effectivePeriod[0]).format('YYYY-MM-DD');
        let effectiveEndDate = dayjs(effectivePeriod[1]).format('YYYY-MM-DD');

        if (isCurrentSelected) {
            effectiveEndDate = 'current';
        }
        const item = {
            partNumber: values.partNumber,
            minimumSellPrice: parseFloat(restValues.quotedSellPrice),
            effectiveStartDate,
            effectiveEndDate,
            maxRebatePrice: parseFloat(restValues.maxRebatePrice),
            standardHandlingFeePercentage: parseFloat(restValues.handlingFeeSTD),
            westernHandlingFeePercentage: parseFloat(restValues.handlingFeeWST),
        };
        const payload = {
            items: [item],
        };
        addParts(payload);
    };

    const addParts = async (payload) => {
        try {
            webService
                .post(addPart, payload)
                .then((response) => {
                    message.success(response?.message);
                    form.resetFields();
                    getTableList();
                    setNewItemAdded(true);
                })
                .catch(() => {});
        } catch (error) {
            console.error('Error making API call:', error);
        }
    };

    //table LIst
    const getTableList = async () => {
        try {
            store.dispatch(isExecuting(true));
            webService
                .get(getParts, {
                    isEffective: true,
                    skip: currentPage,
                    limit: pageSize,
                    search: search,
                    filterType: filterType,
                })
                .then((response) => {
                    let responseData = response?.data?.rows || [];
                    const pageTotalRecords = response.data.total || 0;
                    const currentDate = new Date();
                    responseData = responseData.map((record) => {
                        return {
                            ...record,
                            partNumber: record.part.partNumber,
                            description: record.part.description,
                        };
                    });
                    setTableData(responseData);
                    setTotal(pageTotalRecords);
                })
                .finally(() => {
                    store.dispatch(isExecuting(false));
                });
        } catch (error) {
            console.error('Error making API call:', error);
        }
    };
    const handleCurrentClick = () => {
        setFilterType('current');
        setCurrentPage(1); // Optionally reset to the first page when the filter changes
        getTableList(); // Fetch current data
        setSelectedButton('current');
    };
    const handleExpiredClick = () => {
        setFilterType('expired');
        setCurrentPage(1); // Optionally reset to the first page when the filter changes
        getTableList(); // Fetch expired data
        setSelectedButton('Expired');
    };
    const handleAllClick = () => {
        setFilterType('all');
        setCurrentPage(1); // Optionally reset to the first page when the filter changes
        getTableList(); // Fetch all data
        setSelectedButton('all');
    };

    useEffect(() => {
        getTableList();
    }, [currentPage, pageSize, search, filterType, newItemAdded === true]);

    const handleReset = () => {
        const values = {
            ...listFilter,
            effectivePeriod: undefined,
            basePrice: undefined,
            maxRebatePrice: undefined,
            handlingFeeSTD: undefined,
            handlingFeeWST: undefined,
        };

        setListFilter(values);
        setIsCheckboxDisabled(true);
        setIsCurrentSelected(false);
        form.resetFields();
        form.setFieldsValue({ effectivePeriod: undefined });
    };

    const handleCalendarChange = (dates) => {
        const start = dates && dates[0] ? dates[0] : startDate;
        setStartDate(start);

        if (start) {
            setIsCheckboxDisabled(false);
        } else {
            setIsCheckboxDisabled(true);
            setIsCurrentSelected(false);
            setEndDate(null);
            form.setFieldsValue({ effectivePeriod: [null, null] });
        }
    };

    const handleDateChange = (dates) => {
        const start = dates && dates[0] ? dates[0] : null;
        const end = dates && dates[1] ? dates[1] : null;

        setStartDate(start);
        setEndDate(end);

        if (!start) {
            setIsCheckboxDisabled(true);
            setIsCurrentSelected(false);
            setEndDate(null);
        }

        form.setFieldsValue({ effectivePeriod: [start, end] });
    };

    const handleCheckboxChange = (e) => {
        setIsCurrentSelected(e.target.checked);
        if (e.target.checked) {
            const currentDate = moment();
            setEndDate(currentDate);
            form.setFieldsValue({ effectivePeriod: [startDate, currentDate] });
            let values = {
                ...listFilter,
                effectivePeriod: [startDate, currentDate],
            };
            setListFilter(values);
        } else {
            setEndDate(null);
            form.setFieldsValue({ effectivePeriod: [startDate, null] });
            let values = {
                ...listFilter,
                effectivePeriod: [startDate, null],
            };
            setListFilter(values);
        }
    };

    useEffect(() => {
        form.setFieldsValue({
            handlingFeeSTD: 10,
            handlingFeeWST: 20,
        });
    }, []);

    const handleSearch = (e) => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        // Set a new timeout to delay the API call
        debounceTimeout.current = setTimeout(() => {
            setSearch(e.target.value); // Make API call after the delay
        }, 500);
    };

    return (
        <div className="main-container create-new-quote-container">
            <div className="box">
                <div className="row-col-container">
                    <Form form={form} onFinish={handleSubmit}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="newreq">
                            <Col className="gutter-row" span={8}>
                                <Title className="dealer-code" level={5}>
                                    Part Number
                                </Title>
                                <Form.Item
                                    name="partNumber"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required',
                                        },
                                    ]}
                                >
                                    <PartNumberSelect
                                        handleChangePartNumber={handlePartNumber}
                                        setPartList={setPartNumbersData}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <Title className="dealer-code lable-width" level={5}>
                                    Part Description
                                </Title>
                                <Form.Item name="description">
                                    {partDescriptionValue === undefined ? (
                                        <Input
                                            className="input-style addPart-input-style"
                                            value={partDescriptionValue}
                                            disabled
                                            placeholder="Part Description"
                                        />
                                    ) : (
                                        <div
                                            className=" custom-tooltip "
                                            style={{
                                                width: 'calc(100% - 3px)',
                                                position: 'relative',
                                            }}
                                        >
                                            <div>
                                                <Input
                                                    className="input-style addPart-input-style"
                                                    value={partDescriptionValue}
                                                    disabled
                                                    placeholder="Part Description"
                                                />
                                                <span className="tooltip-text">{partDescriptionValue}</span>
                                            </div>
                                        </div>
                                    )}
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={8}>
                                <Title className="customer-id" level={5}>
                                    Effective Period
                                </Title>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Form.Item
                                        name="effectivePeriod"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'This field is required',
                                            },
                                        ]}
                                    >
                                        <RangePicker
                                            style={{ borderRadius: '2px' }}
                                            format="DD/MM/YYYY"
                                            placeholder={['dd/mm/yyyy', 'dd/mm/yyyy']}
                                            value={[startDate, endDate]}
                                            onCalendarChange={handleCalendarChange}
                                            onChange={(value) => {
                                                handleFilterChange('effectivePeriod', value);
                                                handleDateChange(value);
                                            }}
                                            onBlur={() => {
                                                form.validateFields(['effectivePeriod']);
                                            }}
                                        />
                                    </Form.Item>
                                    <Checkbox
                                        style={{
                                            marginLeft: '9px',
                                            marginBottom: '30px',
                                            color: '#0c0d0e',
                                        }}
                                        disabled={isCheckboxDisabled}
                                        checked={isCurrentSelected}
                                        onChange={handleCheckboxChange}
                                    >
                                        Current
                                    </Checkbox>
                                </div>
                            </Col>
                            <Col className="gutter-row rebate-management-form" span={8}>
                                <Title className="dealer-code quote-number form-items-card" level={6}>
                                    Base Price
                                </Title>
                                <Form.Item
                                    name="quotedSellPrice"
                                    rules={[requiredRule, getMaxCharsRule('quotedSellPrice', 15), zeroValueRule]}
                                >
                                    <Input
                                        placeholder="Enter Base Price"
                                        addonBefore={<span className="dollor-style">$</span>}
                                        className="input-style"
                                        autoComplete="off"
                                        onBlur={() => {
                                            form.validateFields(['quotedSellPrice']);
                                        }}
                                        onKeyPress={(e) => onHandleNumericValidation(e, form, 'quotedSellPrice', true)}
                                        onChange={(value) => {
                                            handleFilterChange('basePrice', value);
                                            handleonChange('quotedSellPrice', value);
                                        }}
                                        style={{
                                            width: '100%',
                                            borderRadius: '0px',
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row rebate-management-form" span={8}>
                                <Title className="dealer-code quote-number form-items-card" level={6}>
                                    List Price
                                </Title>
                                <Form.Item name="listPrice">
                                    <Input
                                        placeholder="List Price"
                                        disabled
                                        className="input-style disabled-input-fields"
                                        addonBefore={<span className="dollor-style">$</span>}
                                        autoComplete="off"
                                        style={{
                                            width: '100%',
                                            borderRadius: '0px',
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row rebate-management-form" span={8}>
                                <Title className="dealer-code quote-number lable-width form-items-card" level={6}>
                                    Stock Order Price
                                </Title>
                                <Form.Item name="stockOrderPrice">
                                    <Input
                                        placeholder="Stock Order Price"
                                        className="input-style disabled-input-fields"
                                        styles={{ color: 'black' }}
                                        addonBefore={<span className="dollor-style">$</span>}
                                        autoComplete="off"
                                        disabled
                                        style={{
                                            width: '100%',
                                            borderRadius: '0px',
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row rebate-management-form" span={8}>
                                <Title className="dealer-code quote-number lable-width form-items-card" level={6}>
                                    Max. Rebate Price
                                </Title>
                                <Form.Item
                                    name="maxRebatePrice"
                                    rules={[requiredRule, getMaxCharsRule('maxRebatePrice', 15), zeroValueRule]}
                                >
                                    <Input
                                        placeholder="Enter Max. Rebate Price "
                                        addonBefore={<span className="dollor-style">$</span>}
                                        className="input-style"
                                        autoComplete="off"
                                        onBlur={() => {
                                            form.validateFields(['maxRebatePrice']);
                                        }}
                                        onKeyPress={(e) => onHandleNumericValidation(e, form, 'maxRebatePrice', true)}
                                        onChange={(value) => {
                                            handleFilterChange('maxRebatePrice', value);
                                            handleonChange('maxRebatePrice', value);
                                        }}
                                        style={{
                                            width: '100%',
                                            borderRadius: '0px',
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row rebate-management-form" span={8}>
                                <Title className="dealer-code quote-number lable-width form-items-card" level={6}>
                                    Handling Fee (STD Region)
                                </Title>
                                <Form.Item name="handlingFeeSTD" rules={[requiredRule, zeroValueRule]}>
                                    <Input
                                        placeholder="Enter Handling Fee"
                                        className="input-style"
                                        addonAfter={<span className="dollor-style">%</span>}
                                        autoComplete="off"
                                        style={{
                                            width: '100%',
                                            borderRadius: '0px',
                                        }}
                                        onBlur={() => {
                                            form.validateFields(['handlingFeeSTD']);
                                        }}
                                        onKeyPress={(e) =>
                                            onHandleNumericWithDecimalsValidation(e, form, 'handlingFeeSTD', false)
                                        }
                                        onChange={(value) => {
                                            handleFilterChange('handlingFeeSTD', value);
                                            handleonChange('handlingFeeSTD', value);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row rebate-management-form" span={8}>
                                <Title className="dealer-code quote-number lable-width form-items-card" level={6}>
                                    Handling Fee (WST Region)
                                </Title>
                                <Form.Item name="handlingFeeWST" rules={[requiredRule, zeroValueRule]}>
                                    <Input
                                        placeholder="Enter Handling Fee"
                                        className="input-style"
                                        addonAfter={<span className="dollor-style">%</span>}
                                        onChange={(value) => {
                                            handleFilterChange('handlingFeeWST', value);
                                            handleonChange('handlingFeeWST', value);
                                        }}
                                        onBlur={() => {
                                            form.validateFields(['handlingFeeWST']);
                                        }}
                                        onKeyPress={(e) =>
                                            onHandleNumericWithDecimalsValidation(e, form, 'handlingFeeWST', false)
                                        }
                                        autoComplete="off"
                                        style={{
                                            width: '100%',
                                            borderRadius: '0px',
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row new-footer-buttons " span={24}>
                                <div className="addPart-modal-footer " style={{ marginTop: '-20px' }}>
                                    <Space>
                                        <CustomizedButton className="reset-btn" onClick={handleReset}>
                                            RESET
                                        </CustomizedButton>
                                        {/* <CustomizedButton
                      type="primary"
                      className={
                        isAnyFilterSelected ? "apply-btn" : "apply-btn-disabled"
                      }
                      onClick={handleApplyFilter}
                      isDisabled={!isAnyFilterSelected}
                    >
                      Apply
                    </CustomizedButton> */}
                                        <CustomizedButton
                                            type="primary"
                                            htmlType="submit"
                                            className={
                                                isAnyFilterSelected
                                                    ? 'addpart-btn apply-btn'
                                                    : 'addpart-btn apply-btn-disabled'
                                            }
                                        >
                                            Add Part
                                        </CustomizedButton>
                                    </Space>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <hr className="hr-line" />
                <div>
                    <Col
                        className="gutter-row rebate-management-form"
                        span={6}
                        style={{ marginLeft: '27px', marginTop: '30px' }}
                    >
                        <Title className="search  lable-width" level={6}>
                            Search Part Number
                        </Title>
                        <Form.Item
                            name="quotedSellPrice"
                            rules={
                                [
                                    // requiredRule,
                                    // getMaxCharsRule("quotedSellPrice", 15),
                                    // zeroValueRule,
                                ]
                            }
                        >
                            <Input.Group compact>
                                <div style={{ display: 'flex', width: '100%' }}>
                                    {/* First Column: Search Input */}
                                    <div style={{ flex: 1, display: 'flex' }}>
                                        <Input
                                            placeholder="Enter Part Number"
                                            autoComplete="off"
                                            onInput={(e) => handleSearch(e)}
                                            style={{
                                                flex: 1, // Ensures the input takes the remaining space
                                                borderRadius: '0px', // Remove rounded corners
                                            }}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}></div>
                                </div>
                            </Input.Group>
                            {/* <Input
                placeholder="Enter Part Number"
                // addonBefore={<span className="dollor-style">$</span>}
                className=""
                autoComplete="off"
                // onBlur={() => {
                //   form.validateFields(["quotedSellPrice"]);
                // }}
                onInput={(e) => handleSearch(e)}
                // onKeyPress={(e) =>
                //   onHandleNumericValidation(
                //     e,
                //     form,
                //     "quotedSellPrice",
                //     true
                //   )
                // }
                // onChange={(value) =>
                //   handleonChange("quotedSellPrice", value)
                // }
                style={{
                  width: "100%",
                  borderRadius: "0px",
                }}
              /> */}
                        </Form.Item>
                    </Col>
                    <Title className="quote-items all-quote-list" level={4}></Title>
                    <div className="total-container reports-container">
                        <div className="total-text">
                            Total:
                            <span className="item">{`${total} items`}</span>
                        </div>
                        <div style={{ marginRight: '-165px', marginTop: '-113px' }}>
                            <Button className="reset-btn" style={buttonStyle('all')} onClick={handleAllClick}>
                                All
                            </Button>
                            <Button className="reset-btn" style={buttonStyle('current')} onClick={handleCurrentClick}>
                                Current
                            </Button>
                            <Button className="reset-btn" style={buttonStyle('Expired')} onClick={handleExpiredClick}>
                                Expired
                            </Button>
                        </div>
                        <div className="apply-btn-container">
                            <CustomizedButton
                                className="import-file-btn export-button export-file btn-right "
                                type="Outlined"
                                onClick={handleImportFile}
                                icon={ImportFile}
                            >
                                IMPORT FILE
                            </CustomizedButton>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                accept=".xlsx,.xls"
                                onChange={handleFileChange}
                            />
                            <CustomizedButton
                                className="import-file-btn export-button"
                                type="Outlined"
                                icon={ExportIcon}
                                onClick={handleClickExport}
                            >
                                Export
                            </CustomizedButton>
                        </div>
                    </div>
                </div>
                <div className="table-container">
                    <CommonTable dataSource={tableData} columns={columns} />
                    <div className="quote-pagination">
                        <Pagination
                            current={currentPage}
                            pageSize={pageSize}
                            total={total}
                            onChange={handleTableChange}
                            showSizeChanger
                            className="custom-pagination"
                            pageSizeOptions={['15', '25', '50', '100']}
                            onShowSizeChange={handleTableChange}
                        />
                    </div>
                </div>
            </div>
            <CommonModal
                id=":rh:"
                visible={exportModalVisible}
                onCancel={onCancelModal}
                onConfirm={handleConfirmExport}
                title="Export"
                showExportButton={exportModalVisible}
            >
                <p className="export-message">Export part basket in XLS format.</p>
            </CommonModal>
            <CommonModal
                visible={isDeleteModalVisible}
                onCancel={hideDeleteModal}
                onConfirm={handleDeleteItem}
                title="Delete Part"
                showDeleteButton={true}
            >
                <p>Do you want to delete the part? </p>
            </CommonModal>
            <ImportFiles
                isVisible={isImportPartsModalVisible}
                handleClose={handleHideImportPartsModal}
                file={importedFile}
                handleGetImportedRecords={handleGetImportedRecords}
            />
        </div>
    );
};
export default RebateManagement;
