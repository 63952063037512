import { QuoteContext } from '../Pages/Quote-Calculator/quoteContext';
import {
    customerStatusList,
    customertypes,
    CustomerUnapprovalReason,
    dealershipsCustomers,
    regionsApi,
    reportdealerShipsApi,
} from '../Services/userEndpoints';
import { useEffect, useContext } from 'react';
import { webService } from '../Services/web.service';
// Dealership Api
const fetchAndSetDealership = async (setDealershipDetails) => {
    try {
        webService.get(reportdealerShipsApi).then((response) => {
            const dealerDetails = response?.data?.rows;
            if (dealerDetails) {
                setDealershipDetails(dealerDetails);
            }
        });
    } catch (error) {
        console.error('Error fetching Dealership', error);
    }
};

export const RegionComponent = () => {
    const { setRegionTypes, setDealershipDetails } = useContext(QuoteContext);
    //  Regions Api
    const fetchAndProcessRegions = async () => {
        try {
            webService
                .get(regionsApi)
                .then((response) => {
                    const regionData = response.data || [];
                    const updatedRegionData = regionData.map((detail) => ({
                        ...detail,
                        name: `${detail.name} Report`,
                    }));
                    const combinedRegionData = [{ id: 0, name: 'Dealer Specific Report' }, ...updatedRegionData];
                    setRegionTypes(combinedRegionData);
                })
                .catch(() => {
                    setRegionTypes([]);
                });
        } catch (error) {
            console.error('Error fetching or processing regions', error);
        }
    };
    useEffect(() => {
        fetchAndProcessRegions();
        fetchAndSetDealership(setDealershipDetails);
    }, []);
    return null;
};

export const AllowanceRegionComponent = () => {
    const { setAllowanceRegion, setDealershipDetails } = useContext(QuoteContext);
    //Allowance regions
    const fetchAllowanceRegions = async () => {
        try {
            webService
                .get(regionsApi)
                .then((response) => {
                    const regionData = response.data || [];
                    const updatedRegionData = regionData.map((detail) => ({
                        ...detail,
                        name: `${detail.name}`,
                    }));
                    const combinedRegionData = [{ id: 0, name: 'Dealer Specific Report' }, ...updatedRegionData];
                    setAllowanceRegion(combinedRegionData);
                })
                .catch(() => {
                    setAllowanceRegion([]);
                });
        } catch (error) {
            console.error('Error fetching or processing regions', error);
        }
    };
    useEffect(() => {
        fetchAllowanceRegions();
        fetchAndSetDealership(setDealershipDetails);
    }, []);
    return null;
};

//Customer approval Status Api
export const GetCustomerUnapprovalReason = () => {
    const { setUnApprovalStatus } = useContext(QuoteContext);
    const getUnapprovalList = async () => {
        try {
            return webService
                .get(CustomerUnapprovalReason)
                .then((response) => {
                    const customerReason = response?.data || [];
                    setUnApprovalStatus(customerReason);
                    return customerReason;
                })
                .catch(() => {
                    return null;
                });
        } catch (error) {
            console.error('Error fetching Customer UnApproval Reason', error);
            throw error;
        }
    };
    useEffect(() => {
        getUnapprovalList();
    }, []);
    return null;
};

//Customer approval Status Api
export const GetCustomerApprovalStatus = () => {
    const { setApprovalStatus } = useContext(QuoteContext);
    const getApprovalList = async () => {
        try {
            return webService
                .get(customerStatusList)
                .then((response) => {
                    const customerStatus = response?.data || [];
                    setApprovalStatus(customerStatus);
                    return customerStatus;
                })
                .catch(() => {
                    return null;
                });
        } catch (error) {
            console.error('Error fetching Customer Approval Status', error);
            throw error;
        }
    };
    useEffect(() => {
        getApprovalList();
    }, []);
    return null;
};

//Customer Types Api
export const GetCustomerTypes = () => {
    const { setCostomerType } = useContext(QuoteContext);
    const getCustomerTypeList = async () => {
        try {
            return webService
                .get(customertypes)
                .then((response) => {
                    const customerStatus = response?.data.rows || [];
                    setCostomerType(customerStatus);
                    return customerStatus;
                })
                .catch(() => {
                    return null;
                });
        } catch (error) {
            console.error('Error fetching Customer Type', error);
            throw error;
        }
    };
    useEffect(() => {
        getCustomerTypeList();
    }, []);
    return null;
};

//  Customer Api
export const GetCustomerDetails = () => {
    const { setCustomerFilters } = useContext(QuoteContext);
    const getCustomerTypeList = async () => {
        try {
            return webService
                .get(`${dealershipsCustomers}`)
                .then((response) => {
                    const customerFilter = response?.data.rows || [];
                    setCustomerFilters(customerFilter);
                    return customerFilter;
                })
                .catch(() => {
                    return null;
                });
        } catch (error) {
            console.error('Error fetching Customer Type', error);
            throw error;
        }
    };
    useEffect(() => {
        getCustomerTypeList();
    }, []);
    return null;
};
